import { Button, Icon, Label, Popup } from "semantic-ui-react";
import { useEffect } from "react";
import React from "react";
import moment from "moment";
import { parseFHIRPreference } from "../../../utils/FHIRUtils";

type ObservationsProps = {
  code: string;
  data: any;
  sidebar: boolean;
};

const Observations: React.FC<ObservationsProps> = ({ code, data, sidebar }) => {
  const [educationFilter, setEducationFilter] = React.useState(null);

  const [highlightID, setHighlightID] = React.useState<any[]>([]);

  useEffect(() => {
    if (data[0]) {
      let lastDataInfo = "";
      let indexOfQuestionnaire = "";
      let currentDataInfo = "";
      let lastUpdatedDate = "";
      let tempHighlightId = highlightID;
      for (let i = 0; i < data.length; i++) {
        //if the data does have surveyResponse id, we will use the surveyResponse id as lastDataInfo
        if (data[i]?.resource?.derivedFrom?.length > 0) {
          if (i === 0) {
            lastUpdatedDate = data[i]?.resource?.meta?.lastUpdated;
          }
          indexOfQuestionnaire = "";
          currentDataInfo = "";
          indexOfQuestionnaire = data[i]?.resource?.["derivedFrom"].findIndex((obj) => (obj.display = "Questionnaire"));
          currentDataInfo = data[i]?.resource?.["derivedFrom"]?.[indexOfQuestionnaire]?.["reference"]?.split("/")?.[1];
        } else {
          //if the data does not have surveyResponse id, we will use the effectivePeriod as lastDataInfo
          currentDataInfo = data[i]?.resource?.effectivePeriod?.start;
        }

        if (!lastDataInfo) {
          lastDataInfo = currentDataInfo;
        }
        if (
          (currentDataInfo === lastDataInfo &&
            moment(lastUpdatedDate).diff(data[i]?.resource?.meta?.lastUpdated, "seconds") < 15) ||
          moment(lastDataInfo).diff(currentDataInfo, "seconds") < 15
        ) {
          if (!highlightID.includes(data[i]?.resource?.id)) {
            tempHighlightId.push(data[i]?.resource?.id);
            setHighlightID(tempHighlightId);
          }
        } else {
          if (highlightID.includes(data[i]?.resource?.id)) {
            tempHighlightId = tempHighlightId.filter((obs) => {
              return obs !== data[i]?.resource?.id;
            });
            setHighlightID(tempHighlightId);
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const observationsList = (observation: any, i: number, counter: number) => {
    let parsedObservation = parseFHIRPreference(observation.resource);
    if (educationFilter) {
      if (!parsedObservation.accessMethod || !educationFilter.includes(parsedObservation.accessMethod)) {
        return;
      }
    }
    if (!sidebar) {
      return (
        <Popup
          key={i}
          flowing
          trigger={
            <Label
              basic={!(highlightID?.includes(parsedObservation?.id) || code === "FOOD-AVOIDANCE")}
              style={{ margin: "0.15em" }}
              color={highlightID?.includes(parsedObservation?.id) || code === "FOOD-AVOIDANCE" ? "teal" : "grey"} //always highlight all food avoidance items
            >
              {parsedObservation.valueString}
            </Label>
          }
          content={
            <span key={i}>
              <Icon name="clock" />
              {parsedObservation.accessMethod && "Access Method: " + parsedObservation.accessMethod + ". "}
              Last Updated By: {parsedObservation.author}.{" "}
              {moment(parsedObservation.lastUpdated).format("MMMM Do YYYY, h:mm:ss a")}.{" "}
              {observation.resource.category
                ? `From ${observation.resource.category[0].coding[0].display}`
                : "Manually added"}
              .
            </span>
          }
          on="hover"
        />
      );
    } else {
      return (
        <Label
          key={i}
          basic
          style={{ margin: "0.15em" }}
          color={highlightID?.includes(parsedObservation?.id) ? "teal" : "grey"}
        >
          {parsedObservation.valueString}
          {parsedObservation.code === "REFERRAL-TYPE" && parsedObservation.referralStatus
            ? ": " + parsedObservation.referralStatus
            : ""}
        </Label>
      );
    }
  };

  return (
    <>
      {data && data.length > 0 && (
        <>
          {code === "EDUCATIONAL-MATERIAL-PREFERENCES,access-content" && (
            <Button.Group size="mini">
              <Button
                content={"All"}
                color={educationFilter === null ? "blue" : "grey"}
                onClick={() => {
                  setEducationFilter(null);
                }}
              />
              <Button
                content={"Lesson ONLY"}
                color={educationFilter === "member-app-task" ? "blue" : "grey"}
                onClick={() => {
                  educationFilter === "member-app-task"
                    ? setEducationFilter(null)
                    : setEducationFilter("member-app-task");
                }}
              />
              <Button
                content={"Library ONLY"}
                color={educationFilter === "member-app-resource-library" ? "blue" : "grey"}
                onClick={() => {
                  educationFilter === "member-app-resource-library"
                    ? setEducationFilter(null)
                    : setEducationFilter("member-app-resource-library");
                }}
              />
            </Button.Group>
          )}
          {data.map((observation: any, i: number) => observationsList(observation, i, data.length))}
        </>
      )}
    </>
  );
};

export default Observations;
