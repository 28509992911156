import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

const SuspenseLoader: React.FC = () => {
  return (
    <Dimmer active>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default SuspenseLoader;
