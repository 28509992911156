import { automatedTaskSummary, EMAIL_TEMPLATE_CONTENT } from 'fhir-sdoh/common/constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Checkbox, Icon, Label, LabelDetail, List, Popup } from 'semantic-ui-react';

enum taskPriority {
  routine,
  urgent,
  asap,
  stat,
}

export function NewTaskList(props: any) {
  let [taskList, setTaskList] = useState(null);
  let [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    setTaskList(props.taskList);
  }, [props.taskList]);

  let colors = {
    requested: 'blue',
    'in-progress': 'orange',
    completed: 'green',
    cancelled: 'grey',
    failed: 'red',
    'on-hold': 'red',
    received: 'purple',
  };
  let icons = {
    requested: 'circle',
    'in-progress': 'circle',
    completed: 'check circle',
    cancelled: 'circle',
    failed: 'remove circle',
    'on-hold': 'circle',
    received: 'circle',
  };
  let taskIconMaps = {
    touchpoint: 'american sign language interpreting',
    survey: 'write',
    'food-card': 'vcard',
    'confirm-food-card-balance': 'vcard',
    'food-box': 'box',
    'food-delivery': 'food',
    education: 'book',
    consultation: 'call',
    careplan: 'calendar alternate outline',
    communication: 'phone',
    'food-recall': 'food',
    triage: 'plus',
    outreach: 'handshake',
    automated: 'keyboard',
    member: 'user',
    voicemail: 'phone volume',
  };
  let taskIconColors = {
    careplan: 'pink',
    communication: 'red',
    consultation: 'yellow',
    education: 'violet',
    voicemail: 'red',
    'food-box': 'brown',
    'food-card': 'red',
    'confirm-food-card-balance': 'red',
    'food-delivery': 'green',
    survey: 'purple',
    touchpoint: 'orange',
    'food-recall': 'blue',
    triage: 'red',
    outreach: 'blue',
    automated: 'black',
    member: 'blue',
  };

  let taskBorder = {
    pastdue: '2px solid red',
    today: '2px solid green',
    future: '',
  };

  let taskDueDateStatusIcon = {
    pastdue: 'angle double left',
    today: 'hourglass half',
    future: 'angle double right',
  };
  const priorityIcons = { routine: '', urgent: 'warning triangle', asap: 'lightning bolt', stat: 'fire' };
  const priorityIconColors = { routine: 'blue', urgent: 'yellow', asap: 'orange', stat: 'red' };
  const handleTaskButton = (e: any, taskButtonProps) => {
    props.handleTaskButton(e, taskButtonProps);
  };

  const handleMultipleTasks = (task) => {
    let temp = props.selectedTasks;
    let index = temp.findIndex((tempTask) => tempTask.id === task.id);
    index === -1 ? temp.push(task) : temp.splice(index, 1);
    props.setSelectedTasks(temp);
    if (temp.length < 1) {
      props?.setDisableMultiSelect(true);
    } else {
      props?.setDisableMultiSelect(false);
    }
    setRender(!render);
  };

  const handleAllTasks = () => {
    let temp = props.selectedTasks;
    if (props.selectedTasks.length === Object.keys(taskList).length) {
      temp = [];
    } else {
      temp = taskList;
    }
    props.setSelectedTasks(temp);
    if (temp.length < 1) {
      props?.setDisableMultiSelect(true);
    } else {
      props?.setDisableMultiSelect(false);
    }
    setRender(!render);
  }

  //show the email summary for automated tasks
  const openPreview = (task) => {
    if (task.description) {
      let description = task.description;
      let email_template_ID;
      let orgIndex = task?.basedOn?.findIndex((obj) => obj.reference.startsWith('Organization'));
      let org = task?.basedOn?.[orgIndex]?.reference?.split('/')?.[1];

      if (
        description.includes('Automated Task: Send Nutrition Tip Week') ||
        description === 'Automated Task: Send Resources Survey' ||
        description === 'System check for member food rating response, send communication if not found'
      ) {
        email_template_ID = automatedTaskSummary?.[description];
      } else {
        let index = automatedTaskSummary?.[org]?.findIndex((summary) => summary[description] !== undefined);
        if (index >= 0) {
          email_template_ID = automatedTaskSummary?.[org]?.[index]?.[description];
        }
      }
      if (email_template_ID) {
        return EMAIL_TEMPLATE_CONTENT?.[email_template_ID];
      } else {
        return 'Sorry, no preview available for this email.';
      }
    } else {
      return null;
    }
  };

  function subList(tasksubList) {
    tasksubList?.sort((a, b) => {
      let aDate: any = new Date(
        moment(a.executionPeriod?.end ?? a.executionPeriod?.start)
          .utc()
          .format()
      ).toDateString();
      let bDate: any = new Date(
        moment(b.executionPeriod?.end ?? b.executionPeriod?.start)
          .utc()
          .format()
      ).toDateString();
      return Date.parse(aDate) - Date.parse(bDate);
    });
    tasksubList?.sort((a, b) => {
      let priorityA: 'routine' | 'urgent' | 'asap' | 'stat' = a.priority ?? 'routine';
      let priorityB: 'routine' | 'urgent' | 'asap' | 'stat' = b.priority ?? 'routine';
      return taskPriority[priorityB] - taskPriority[priorityA];
    });

    return (
      <List relaxed size="medium">
        {tasksubList.length > 0 ? (
          tasksubList.map((taskpayload: any, ind: number) => {
            let coding = taskpayload.code && taskpayload.code.coding && taskpayload.code.coding[0];
            //may 2024 todo: we could add a taskHelper class here, like questionnaire has
            let priority = taskpayload.priority;
            let identifiersList =
              taskpayload &&
              taskpayload.identifier &&
              taskpayload.identifier.filter((identifier) => {
                return identifier.system.toString().includes('vendor-codes');
              });
            let vendorValue = identifiersList && identifiersList.length > 0 ? identifiersList[0].value : null;
            let referralId = taskpayload && taskpayload.groupIdentifier && taskpayload.groupIdentifier.value;
            let createOrUpdatedInfo: string;
            if (
              new Date(moment(taskpayload.executionPeriod?.start).format('MM-DD-YYYY')) <=
              new Date(moment(taskpayload.meta.lastUpdated).format('MM-DD-YYYY')) &&
              taskpayload.requester
            ) {
              createOrUpdatedInfo = `(created by ${taskpayload.requester.display} on ${moment(
                taskpayload.executionPeriod.start
              ).format('MM-DD-YYYY')})`;
            } else {
              let updatedByName = '';
              if (taskpayload?.identifier) {
                let index = taskpayload.identifier.findIndex(
                  (eachObj) => eachObj.system === 'https://projectwell.io/fhir/identifiers/last-modified-by'
                );
                index !== -1
                  ? (updatedByName = taskpayload.identifier[index].value)
                  : (updatedByName = taskpayload?.owner?.display);
              } else {
                updatedByName = taskpayload.owner.display;
              }
              createOrUpdatedInfo = `(updated by ${updatedByName} on ${moment(taskpayload.meta.lastUpdated).format(
                'MM-DD-YYYY'
              )})`;
            }
            let duedate: string;
            let taskDueDateStatus = '';
            if (taskpayload.executionPeriod) {
              duedate = moment(
                new Date(
                  new Date(taskpayload.executionPeriod.end ?? taskpayload.executionPeriod.start).getTime() +
                  Math.abs(
                    new Date(
                      taskpayload.executionPeriod.end ?? taskpayload.executionPeriod.start
                    ).getTimezoneOffset() * 60000
                  )
                )
                  .toString()
                  .substring(0, 15)
              ).format('MM/DD/YYYY');
            }
            if (moment(duedate).isSame(moment().local().format('MM/DD/YYYY'))) {
              taskDueDateStatus = 'today';
            } else if (moment(duedate).isAfter(moment().local().format('MM/DD/YYYY'))) {
              taskDueDateStatus = 'future';
            } else {
              taskDueDateStatus = 'pastdue';
            }
            return (
              <List.Item
                key={taskpayload.id}
                as="span"
                onClick={handleTaskButton}
                taskid={taskpayload.id}
                style={{
                  cursor: 'pointer',
                  backgroundColor: props.currentTaskId === taskpayload.id ? '#add8e6' : 'white',
                  padding: '10px',
                  borderRadius: '6px',
                  margin: '16px 0px',
                  boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)',
                  border: `${taskpayload.status !== 'completed' && taskpayload.status !== 'failed'
                    ? taskDueDateStatus === 'pastdue' || taskDueDateStatus === 'today'
                      ? taskBorder[taskDueDateStatus]
                      : ''
                    : ''
                    }`, //show boarder for pastdue or today incompleted && not failed task
                }}
              >
                <List.Icon size="large" name={taskIconMaps[coding.code]} color={taskIconColors[coding.code]}>
                  {' '}
                </List.Icon>
                <List.Content>
                  <List.Description style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ float: 'left' }}>
                      {taskpayload.reasonCode && taskpayload.reasonCode.text
                        ? taskpayload.reasonCode.text.split('|')[0]
                        : ''}

                      <Icon name={priorityIcons[priority]} color={priorityIconColors[priority]} />
                      {priority === 'asap' && (
                        <Label color="orange" horizontal>
                          URGENT
                        </Label>
                      )}
                    </span>
                    <span style={{ display: 'flex' }}>
                      {referralId && (
                        <Label style={{ marginBottom: '4px', marginRight: '4px' }} basic color="red" size="tiny">
                          {referralId}
                        </Label>
                      )}
                      <Checkbox
                        style={{ width: '15px', height: '15px' }}
                        onClick={() => handleMultipleTasks(taskpayload)}
                        checked={
                          props.selectedTasks.length > 0 &&
                          props.selectedTasks.find((task) => task.id === taskpayload.id)
                        }
                      />
                    </span>
                  </List.Description>
                  <List.Header>
                    {vendorValue ? vendorValue + ' - ' : ''}
                    {coding.display} -{' '}
                    <span style={{ opacity: '70%' }}>
                      {' '}
                      {duedate}
                      {' - '}
                      {taskpayload.owner ? taskpayload.owner.display : ''} {' / '}
                      {createOrUpdatedInfo}
                    </span>
                  </List.Header>
                  <List.Description>
                    {taskpayload.description}
                    <span style={{ float: 'right' }}>
                      {coding.code === 'automated' && (
                        <Popup
                          trigger={<Icon name="file outline" on="hover" />}
                          wide="very"
                          content={openPreview(taskpayload)}
                          on="hover"
                          position="bottom right"
                        />
                      )}
                      <Icon name={taskDueDateStatusIcon[taskDueDateStatus]} />{' '}
                      <Icon name={icons[taskpayload.status]} color={colors[taskpayload.status]} />
                    </span>
                  </List.Description>
                </List.Content>
              </List.Item>
            );
          })
        ) : (
          <>No Tasks</>
        )}
      </List>
    );
  }
  let tasksMap = {};
  let numTasks = 0;
  let cats = Object.keys(taskIconColors);
  for (let i = 0; i < cats.length; i++) {
    let cat = cats[i];
    let temp = taskList?.filter((taskpayload) => {
      return (
        taskpayload.code &&
        taskpayload.code.coding &&
        taskpayload.code.coding[0] &&
        taskpayload.code.coding[0].code === cat
      );
    });
    if (temp?.length > 0) {
      tasksMap[cat] = taskList?.filter((taskpayload) => {
        return (
          taskpayload.code &&
          taskpayload.code.coding &&
          taskpayload.code.coding[0] &&
          taskpayload.code.coding[0].code === cat
        );
      });
    }
    numTasks += temp?.length;
  }

  return (
    <>
      {numTasks > 0 && (
        <div style={{ textAlign: "right" }}>
          <Label style={{ marginBottom: '4px', marginRight: '4px' }} basic size='large'>
            Select All
            <LabelDetail>
              <Checkbox
                style={{ width: '15px', height: '15px' }}
                onClick={() => handleAllTasks()}
                checked={props.selectedTasks.length === numTasks}
              />
            </LabelDetail>
          </Label>
        </div>
      )}
      {numTasks > 0 &&
        Object.keys(tasksMap).map((header: string) => {
          return (
            <>
              <div key={header}>
                <Card.Content
                  header={<h4>{header[0].toUpperCase() + header.substr(1)} </h4>}
                  description={subList(tasksMap[header])}
                />
              </div>
            </>
          );
        })}
      {numTasks === 0 && (
        <Card fluid>
          <Card.Content header={'No Tasks'} />
        </Card>
      )}
    </>
  );
}
