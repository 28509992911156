/* 
Functions copied over from Care-Hooks temporarily. Look to move to backend later.
*/

import moment from "moment";
import { fhirGet, fhirPut } from "services";
import { v4 as uuid } from 'uuid';

export const hooksPatientStatus = async ({ patientId, status, reason }, token, personName) => {
    let updatedResourcesPW = []

    let patientData = (await fhirGet(`/Patient/${patientId}`, token));

    patientData.identifier.find(i => i.system === 'https://projectwell.io/fhir/identifiers/last-modified-by')
        ? patientData.identifier.find(i => i.system === 'https://projectwell.io/fhir/identifiers/last-modified-by').value = personName
        : patientData.identifier.push({ system: 'https://projectwell.io/fhir/identifiers/last-modified-by', value: personName })
    patientData.identifier.find(i => i.system === 'https://projectwell.io/fhir/identifiers/member-status')
        ? patientData.identifier.find(i => i.system === 'https://projectwell.io/fhir/identifiers/member-status').value = status
        : patientData.identifier.push({ system: 'https://projectwell.io/fhir/identifiers/member-status', value: status })
    updatedResourcesPW.push(patientData)

    if (status === 'INACTIVE') {
        let tasks = (await fhirGet(`/Task?_sort=period&code:not=fulfill&patient=${patientId}&status=requested,in-progress,on-hold`, token)).entry?.map(e => e.resource) ?? [];

        let setScreeningTaskToCompleted = false;
        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (
                task.reasonCode.text === 'assessment' ||
                task.reasonCode.text === 'intervention'
            ) {
                task.status = 'failed';
                updatedResourcesPW.push(task);
            } else if (task.reasonCode.text === 'screening') {
                //need to set screening status to completed no matter on the previous status
                task.status = 'completed';
                updatedResourcesPW.push(task);
                setScreeningTaskToCompleted = true;
            } else {
                task.status = 'cancelled';
                updatedResourcesPW.push(task);
            }
        }

        if (!setScreeningTaskToCompleted) {
            //setting screening task to completed
            let screeningTask = (await fhirGet(`/Task?_sort=period&code=careplan&_content:contains=Screening&patient=${patientId}`, token)).entry?.map(e => e.resource)?.[0];

            if (screeningTask.status !== 'completed') {
                //need to set screening status to completed no matter on the previous status
                screeningTask.status = 'completed';
                updatedResourcesPW.push(screeningTask);
            }
        }
    } else if (status === 'ACTIVE') {
        //we need to get all cancelled tasks and filter out the past due tasks, only active not past due tasks
        if (reason === 'REENROLL') {
            let tasks = (await fhirGet(`/Task?_sort=period&patient=${patientId}&status=cancelled&period=ge${moment().format('YYYY-MM-DD')}`, token)).entry?.map(e => e.resource);

            tasks = tasks.filter((t) => {
                let dueDate = t.executionPeriod?.end ?? t.executionPeriod?.start;
                return moment(dueDate).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD');
            });
            for (let i = 0; i < tasks.length; i++) {
                let task = tasks[i];
                task.status = 'requested';
                updatedResourcesPW.push(task);
            }
        } else {
            let tasks = (await fhirGet(`/Task?_sort=period&patient=${patientId}&code=careplan`, token)).entry?.map(e => e.resource);

            for (let i = 0; i < tasks.length; i++) {
                let task = tasks[i];
                if (
                    task.reasonCode.text === 'assessment' ||
                    task.reasonCode.text === 'intervention'
                ) {
                    task.status = 'requested'
                    updatedResourcesPW.push(task);
                } else if (task.reasonCode.text === 'screening') {
                    task.status = 'completed'
                    updatedResourcesPW.push(task);
                }
            }
        }
    }

    let memberStatusObservation = {
        code: {
            coding: [
                {
                    code: 'QUALIFICATION-STATUS',
                    display: 'Qualification Status',
                    system: 'https://projectwell.io/fhir/preference-codes'
                }
            ]
        },
        effectivePeriod: {
            start: moment.utc().format('YYYY-MM-DDTHH:mm:ss+00:00')
        },
        id: uuid(),
        identifier: [
            {
                system: 'https://projectwell.io/fhir/identifiers/last-modified-by',
                value: personName
            }
        ],
        resourceType: 'Observation',
        status: 'final',
        subject: {
            reference: `Patient/${patientId}`
        },
        valueString: status === 'ACTIVE' ? 'Member Active' : 'Member Inactive'
    }
    updatedResourcesPW.push(memberStatusObservation);

    updatedResourcesPW.forEach(async r => {
        await fhirPut(`/${r.resourceType}/${r.id}`, token, r).then(
            () => {
            }, (e) => {
                console.log(e);
            }
        )
    });
}