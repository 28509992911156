export const STATUS_COLORS = {
  new: "blue",
  accepted: "blue",
  error: "error",
  unaccepted: "error",
  "lost/stolen": "error",
  "late delivery": "error",
  error_cancelled: "error",
  ">25% damaged/spoiled/unsafe/incomplete": "error",
  "<25% damaged/spoiled/unsafe/incomplete": "error",
  reshipped: "yellow",
  "never arrived": "error",
  "incomplete delivery": "negative",
  exported: "warning",
  sent: "yellow",
  shipped: "yellow",
  processed: "yellow",
  in_process: "yellow",
  delivered: "yy",
  cancelled: "zz",
  "partially-fulfilled": "error",
  unfulfilled: "error"
};
