import React from "react";
import { Menu } from "semantic-ui-react";

const Footer: React.FC = () => {
  return (
    <Menu size="large" color="orange" inverted fixed="bottom" borderless>
      <Menu.Item>
        <b style={{ position: "fixed", left: 0, width: "100%", textAlign: "center" }}>
          For help, contact NourishedRx HelpDesk at helpdesk@nourishedrx.com or 855-659-FOOD (3663)
        </b>
      </Menu.Item>
    </Menu>
  );
};

export default Footer;
