import { PatientBuilder } from "../../../fhir-sdoh";
import AddressBuilder from "../../../models/addressBuilder";
import PatientCDO from "../../../models/patient";
import * as fhirpath from "fhirpath";
import { states } from "utils";

// Edit/Save Patient Utils
export function PreparePatientPayLoad(
  patientDetails: any,
  operation: string,
  practitionerName: string,
  displayDeliveryAddress: boolean,
  patientId: string,
  careteamStatus: string,
  patientOldDetails: object | undefined
): any {
  // Patient object created
  let patientPayLoad: any = PatientCDO;
  if (operation === "Add") {
    patientPayLoad = PatientBuilder.build(
      patientDetails.org.toUpperCase(),
      patientDetails.memberId[0].toUpperCase(),
      patientDetails.firstName[0],
      patientDetails.lastName[0],
      patientDetails.middleName[0],
      patientDetails.dob,
      patientDetails.gender,
      patientDetails.homePhone[0],
      patientDetails.mobilePhone[0],
      patientDetails.email[0],
      patientDetails.addressLine1[0],
      patientDetails.addressLine2[0],
      patientDetails.city[0],
      patientDetails.state[0],
      patientDetails.zip[0],
      patientDetails.country[0],
      patientDetails.language[0],
      [{ code: "", display: "" }],
      //[],
      practitionerName,
      patientDetails?.otherId?.[0],
      patientDetails?.mrnNumber?.[0],
      careteamStatus,
      patientDetails.provider[0],
      practitionerName,
      'NEW',
      patientDetails?.eligibilityGroup
    );
    let deliveryAddress = {};
    if (displayDeliveryAddress) {
      deliveryAddress = AddressBuilder(
        "physical",
        patientDetails.addressLine1[0].trim(),
        patientDetails.addressLine2[0].trim(),
        patientDetails.city[0].trim(),
        patientDetails.state[0].trim(),
        patientDetails.zip[0].trim(),
        patientDetails.country[0].trim()
      );
    } else {
      deliveryAddress = AddressBuilder(
        "physical",
        patientDetails.delivery_addressLine1[0] ? patientDetails.delivery_addressLine1[0].trim() : "",
        patientDetails.delivery_addressLine2[0].trim(),
        patientDetails.delivery_city[0].trim(),
        patientDetails.delivery_state[0].trim(),
        patientDetails.delivery_zip[0].trim(),
        patientDetails.delivery_country[0].trim()
      );
    }

    patientPayLoad.address[0].type = "postal";
    patientPayLoad.gender =
      patientDetails.gender.trim() === "Gender" || patientDetails.gender === "" ? "" : patientDetails.gender.trim();

    let dob = patientDetails.dob;
    // Converting our date format to API supported format
    if (!(dob === undefined || dob === "")) {
      let rawDate = dob.trim().split("/");
      dob = rawDate[2] + "-" + rawDate[0] + "-" + rawDate[1];
    }
    patientPayLoad.birthDate = patientPayLoad.birthDate === dob ? patientPayLoad.birthDate : dob;

    patientPayLoad.address.push(deliveryAddress);
  } else {
    //if update, we want to keep out old data
    patientPayLoad = patientOldDetails;
    let dob = patientDetails.dob;
    // Converting our date format to API supported format
    if (!(dob === undefined || dob === "")) {
      let rawDate = dob.trim().split("/");
      dob = rawDate[2] + "-" + rawDate[0] + "-" + rawDate[1];
    }
    patientPayLoad.birthDate = patientPayLoad.birthDate === dob ? patientPayLoad.birthDate : dob;
    patientPayLoad.communication = [
      {
        language: {
          text: updatePatientData(patientPayLoad, "communication.language.text", patientDetails?.language?.[0])
        }
      }
    ];
    patientPayLoad.gender =
      patientPayLoad?.gender === patientDetails?.gender ? patientPayLoad?.gender : patientDetails?.gender;
    patientPayLoad.telecom = [
      {
        system: "phone",
        use: "home",
        value: updatePatientData(
          patientPayLoad,
          "telecom.where(system='phone').where(use='home').value",
          patientDetails?.homePhone?.[0]
        )
      },
      {
        system: "phone",
        use: "mobile",
        value: updatePatientData(
          patientPayLoad,
          "telecom.where(system='phone').where(use='mobile').value",
          patientDetails?.mobilePhone?.[0]
        )
      },
      {
        system: "email",
        value: updatePatientData(
          patientPayLoad,
          "telecom.where(system='email').value",
          patientDetails?.email?.[0]?.toLowerCase()
        )
      }
    ];
    patientPayLoad.name = [
      {
        family: updatePatientData(patientPayLoad, "name.family", patientDetails?.lastName?.[0]),
        given: [
          updatePatientData(patientPayLoad, "name.given.first()", patientDetails?.firstName?.[0]),
          updatePatientData(patientPayLoad, "name.given.last()", patientDetails?.middleName?.[0])
        ]
      }
    ];
    if (
      fhirpath.evaluate(
        patientPayLoad,
        "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value"
      )[0] !== practitionerName
    ) {
      let index = patientPayLoad.identifier.findIndex(
        (obj) => obj.system === "https://projectwell.io/fhir/identifiers/last-modified-by"
      );
      if (patientPayLoad.identifier[index]?.value) {
        patientPayLoad.identifier[index].value = practitionerName;
      }
    }

    let physicalAddress = patientPayLoad?.address.find((address) => address?.type === "physical");
    let postalAddress = patientPayLoad?.address.find((address) => address?.type === "postal");

    let newPostalAddress = {
      city: updatePatientData(postalAddress, "city", patientDetails?.city?.[0]),
      country: updatePatientData(postalAddress, "country", patientDetails?.country?.[0]),
      line: [
        updatePatientData(postalAddress, "line.first()", patientDetails?.addressLine1?.[0]),
        updatePatientData(postalAddress, "line.last()", patientDetails?.addressLine2?.[0])
      ],
      postalCode: updatePatientData(postalAddress, "postalCode", patientDetails?.zip?.[0]),
      state: updatePatientData(postalAddress, "state", patientDetails?.state?.[0]),
      type: "postal"
    };
    if (displayDeliveryAddress) {
      let newPhysicalAddress = { ...newPostalAddress };
      newPhysicalAddress.type = "physical";
      patientPayLoad.address = [newPostalAddress, newPhysicalAddress];
    } else {
      patientPayLoad.address = [
        newPostalAddress,
        {
          city: updatePatientData(
            physicalAddress,
            "city",
            displayDeliveryAddress ? patientDetails?.city?.[0] : patientDetails?.delivery_city?.[0]
          ),
          country: updatePatientData(
            physicalAddress,
            "country",
            displayDeliveryAddress ? patientDetails?.country?.[0] : patientDetails?.delivery_country?.[0]
          ),
          line: [
            updatePatientData(
              physicalAddress,
              "line.first()",
              displayDeliveryAddress ? patientDetails?.addressLine1?.[0] : patientDetails?.delivery_addressLine1?.[0]
            ),
            updatePatientData(
              physicalAddress,
              "line.last()",
              displayDeliveryAddress ? patientDetails?.addressLine2?.[0] : patientDetails?.delivery_addressLine2?.[0]
            )
          ],
          postalCode: updatePatientData(
            physicalAddress,
            "postalCode",
            displayDeliveryAddress ? patientDetails?.zip?.[0] : patientDetails?.delivery_zip?.[0]
          ),
          state: updatePatientData(
            physicalAddress,
            "state",
            displayDeliveryAddress ? patientDetails?.state?.[0] : patientDetails?.delivery_state?.[0]
          ),
          type: "physical"
        }
      ];
    }

    if (
      patientDetails?.provider &&
      patientPayLoad?.extension?.find((e) => e?.valueCoding?.code === "PCP_ORGANIZATION")?.valueCoding?.display !==
        patientDetails?.provider?.[0]
    ) {
      if (!patientPayLoad?.extension) {
        patientPayLoad.extension = [];
        patientPayLoad.extension.push({
          url: "https://projectwell.io/fhir/extensions",
          valueCoding: {
            code: "PCP_ORGANIZATION",
            display: patientDetails?.provider?.[0],
            system: "https://projectwell.io/fhir/extensions"
          }
        });
      } else {
        let index = patientPayLoad?.extension?.findIndex((e) => e?.valueCoding?.code === "PCP_ORGANIZATION");
        patientPayLoad.extension[index].valueCoding.display = patientDetails?.provider?.[0];
      }
    }
    if (
      patientDetails?.otherId?.length > 0 &&
      patientPayLoad?.identifier?.find(
        (identifier) => identifier?.system === "https://projectwell.io/fhir/identifiers/other-id"
      )?.value !== patientDetails?.otherId?.[0]
    ) {
      if (!patientPayLoad?.identifier) {
        patientPayLoad.identifier = [];
        patientPayLoad.identifier.push({
          system: "https://projectwell.io/fhir/identifiers/other-id",
          value: patientDetails?.otherId?.[0]
        });
      } else {
        let index = patientPayLoad?.identifier?.findIndex(
          (identifier) => identifier?.system === "https://projectwell.io/fhir/identifiers/other-id"
        );
        patientPayLoad.identifier[index].value = patientDetails?.otherId?.[0];
      }
    }
  }
  return patientPayLoad;
}

export function ValidatePatient(
  patientDetails: any,
  operation: string,
  practitionerName: string,
  displayDeliveryAddress: boolean,
  patientId: string
): any {
  let validations = { valid: true, errors: [], patientBannerErrors: [] };

  let validateKeys = [
    { key: "firstName", value: "First name" },
    { key: "lastName", value: "Last name" },
    { key: "memberId", value: "Member Id" },
    { key: "org", value: "Organization" },
    { key: "homePhone", value: "Home phone" },
    { key: "mobilePhone", value: "Mobile phone" },
    { key: "email", value: "Email" }, // Not a mandatory field anymore.
    { key: "addressLine1", value: "Address" },
    { key: "city", value: "City" },
    { key: "state", value: "State" }
  ];

  //let validPhoneNumber = false;
  let mandatoryPhoneNumber = false;

  if (!displayDeliveryAddress) {
    validateKeys.push({
      key: "delivery_addressLine1",
      value: "Delivery Address"
    });
    validateKeys.push({
      key: "delivery_city",
      value: "Delivery Address - City"
    });
    validateKeys.push({
      key: "delivery_state",
      value: "Delivery Address - State"
    });
  }
  validateKeys.forEach((validateObj) => {
    if (
      (patientDetails[validateObj.key][0] === undefined || patientDetails[validateObj.key][0].trim() === "") &&
      !(validateObj.key === "homePhone" || validateObj.key === "mobilePhone" || validateObj.key === "email")
    ) {
      validations.valid = false;
      validations.errors.push(validateObj.value + " is a mandatory field");
      validations.patientBannerErrors.push(validateObj.value);
    } else if (
      (validateObj.key === "homePhone" || validateObj.key === "mobilePhone" || validateObj.key === "email") &&
      !mandatoryPhoneNumber &&
      (patientDetails["homePhone"][0] === undefined || patientDetails["homePhone"][0].trim() === "") &&
      (patientDetails["mobilePhone"][0] === undefined || patientDetails["mobilePhone"][0].trim() === "") &&
      (patientDetails["email"][0] === undefined || patientDetails["email"][0].trim() === "")
    ) {
      validations.valid = false;
      mandatoryPhoneNumber = true;
      validations.errors.push("Enter either an Email or a Home phone # or a mobile phone #");
      validations.patientBannerErrors.push("Home phone # or mobile phone #");
    } else if (validateObj.key === "email") {
      // eslint-disable-next-line no-useless-escape
      if (
        patientDetails.email[0] !== undefined &&
        patientDetails.email[0].trim() !== "" &&
        !/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(patientDetails.email[0].trim())
      ) {
        validations.valid = false;
        validations.errors.push("Enter valid email address");
      }
    } else if (
      (validateObj.key === "homePhone" || validateObj.key === "mobilePhone") &&
      patientDetails[validateObj.key][0] !== undefined &&
      patientDetails[validateObj.key][0].trim() !== ""
    ) {
      // Phone format validation, Format: XXX-XXX-XXXX
      let PhoneValidationRegex2 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (!PhoneValidationRegex2.test(patientDetails[validateObj.key][0])) {
        validations.valid = false;
        validations.errors.push("Not a valid " + validateObj.value);
      }
    }
  });

  // Gender is not a mandatory field anymore. Gender field can be blank now.
  // if (patientDetails.gender === "") {
  //     validations.valid = false;
  //     validations.errors.push("Gender is a mandatory field")
  // }

  if (patientDetails.dob === "undefined/undefined/undefined") {
    validations.valid = false;
    validations.errors.push("Date Of Birth is a mandatory field");
    validations.patientBannerErrors.push("Date Of Birth");
  }

  return validations;
}

// Fetch state abbreviation for the 2 letter state key
export function FetchStateAbbr(searchState: string): any {
  if (searchState !== undefined || searchState.trim() !== "") {
    let fullState = states.list.filter((state) => searchState.trim() === state.key);
    if (fullState.length) return fullState[0].value;
    else return searchState.trim();
  }
}

function updatePatientData(patientPayLoad, dataName, newPatientPayload) {
  //compare the patient payload with updated data, if it's not the same, update it
  let originalValue = fhirpath.evaluate(patientPayLoad, dataName);
  if (Array.isArray(originalValue)) {
    //@ts-ignore
    originalValue = originalValue.toString();
  }
  return originalValue === newPatientPayload ? originalValue : newPatientPayload;
}
