import { useEffect, useState } from 'react';
import { Form, Loader, Message } from 'semantic-ui-react';
import { Flag, Provenance } from 'fhir/r4';
import { FHIRUtils, ProvenanceBuilder, FlagBuilder } from '../../../../fhir-sdoh';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import { useAuth } from "hooks";
import { fhirGet, fhirPut } from "services";
import { hooksPatientStatus } from 'utils/care-hooks';

export function AssignTask(props: any) {
  const { token, personId, personName } = useAuth();

  let selectedTasks = props.selectedTasks;
  let flatPractitioners = props.flatPractitioners;

  const [allOptions, setAllOptions] = useState({
    assignee: null,
    dueDate: null,
    status: null,
  });

  let [loading, setLoading] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (selectedTasks?.length < 1) {
      setAllOptions({
        assignee: null,
        dueDate: null,
        status: null,
      });
    }
  }, [selectedTasks, props?.disableMultiSelect]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e?.preventDefault();
    let bundleEntry = [];
    //lopp through each task and update the assginee, due date, and status and create provenance
    for (let i = 0; selectedTasks.length > i; i++) {
      let currentTask = selectedTasks[i];
      let currentTaskId = currentTask.id;

      if (allOptions.dueDate) {
        let dueDate = moment(allOptions.dueDate).utc().format('YYYY-MM-DD');
        let updateDueDateProcess = await handleChangeDate(dueDate, currentTask);
        currentTask = updateDueDateProcess?.find((resource) => resource?.fullUrl?.includes(currentTaskId))?.resource;
        //to avoid save the same task multiple times
        //if status and assignee doesn't have to be updated, then we can just push the fhirResources we got from the handleChangeDate function
        //if we need to update the status/assginee, we need to pass the updated current task to update status/assginee
        if (!(allOptions.status || allOptions.assignee)) {
          bundleEntry = bundleEntry.concat(updateDueDateProcess);
        } else {
          let resourcesExceptCurrentTask = updateDueDateProcess.filter(
            (resource) => !resource?.fullUrl?.includes(currentTaskId)
          );
          bundleEntry = bundleEntry.concat(resourcesExceptCurrentTask);
        }
      }

      if (allOptions.status) {
        let updateStatusProcess = await changeStatus(allOptions.status, currentTask);
        currentTask = updateStatusProcess?.find((resource) => resource?.fullUrl?.includes(currentTaskId))?.resource;
        //if assignee doesn't have to be updated, then we can just push the fhirResources we got from the changeStatus function
        //if we need to update the assginee, we need to take out the updated current task and pass to update assginee
        if (!allOptions.assignee) {
          bundleEntry = bundleEntry.concat(updateStatusProcess);
        } else {
          let resourcesExceptCurrentTask = updateStatusProcess.filter(
            (resource) => !resource?.fullUrl?.includes(currentTaskId)
          );
          bundleEntry = bundleEntry.concat(resourcesExceptCurrentTask);
        }
      }

      if (allOptions.assignee) {
        let updatedAssgineeProcess = await assignTask(allOptions.assignee, currentTask);
        bundleEntry = bundleEntry.concat(updatedAssgineeProcess);
      }
    }

    bundleEntry.forEach((entry) => {
      fhirPut(`/${entry.resource.resourceType}/${entry.resource.id}`, token, entry.resource).then(
        (response) => {},
        (response) => {
          setErrorMessage(response.toJSON()['message']);
        }
      );
    })
    props.tasksMutate();
    props.setRender(!props.render);
    setLoading(false);
    setAllOptions({
      assignee: null,
      dueDate: null,
      status: null,
    });
    selectedTasks = [];
    props.setSelectedTasks([]);
  };

  const assignTask = async (assignee, taskDetails) => {
    taskDetails.owner = {
      reference: 'Practitioner/' + assignee.toString(),
      display: flatPractitioners.filter((x) => {
        return x.id === assignee;
      })[0].fullName,
    };
    let orgCode = props.orgCode;
    if (orgCode === 'All') {
      orgCode = await getPatientOrg(taskDetails);
    }
    let orgRef = { code: orgCode.toUpperCase(), display: orgCode.toLowerCase() };
    let patientId = taskDetails.for.reference.split('/')[1];
    taskDetails = FHIRUtils.addUpdatedBy(taskDetails, personName);
    let flagDetails: Flag = FlagBuilder.build(
      patientId,
      personId,
      { code: 'admin', display: 'administrative' },
      { code: 'ALERT', display: 'Alert' },
      taskDetails.code.coding[0].code.toString().toUpperCase() +
        ' related task assigned to you by ' +
        personName,
      personName,
      orgCode,
      taskDetails.owner.reference.split('/')[1],
      'active',
      taskDetails.id
    );
    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskDetails.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Assignee has been changed to ` +
        taskDetails.owner.display.toString() +
        ` on ${taskDetails?.code?.coding?.[0]?.code} Task `,
      orgRef
    );
    //now post bundle request

    return [
      FHIRUtils.buildResourceEntry(taskDetails, false),
      FHIRUtils.buildResourceEntry(flagDetails, false),
      FHIRUtils.buildResourceEntry(provenance, false),
    ];
  };

  const handleChangeDate = async (dueDate, taskDetails) => {
    let orgCode = props.orgCode;
    if (orgCode === 'All') {
      orgCode = await getPatientOrg(taskDetails);
    }
    let orgRef = { code: orgCode.toUpperCase(), display: orgCode.toLowerCase() };
    let patientId = taskDetails.for.reference.split('/')[1];
    if (taskDetails?.code?.coding?.[0]?.code !== 'automated') {
      if (
        moment(dueDate).format('YYYY-MM-DD') < moment(taskDetails.meta.lastUpdated).format('YYYY-MM-DD') &&
        moment(dueDate).format('YYYY-MM-DD') < moment(taskDetails.executionPeriod?.start).format('YYYY-MM-DD') &&
        moment(dueDate).format('YYYY-MM-DD') < moment(taskDetails.executionPeriod?.end).format('YYYY-MM-DD')
      ) {
        //if the selected new due date is smaller than last updated date and both executionPeriod, then we will update the executionPeriod to selected new due date
        taskDetails.executionPeriod.start = moment(dueDate).format('YYYY-MM-DD');
        taskDetails.executionPeriod.end = moment(dueDate).format('YYYY-MM-DD');
      } else if (
        moment(taskDetails.executionPeriod?.start).format('YYYY-MM-DD') > moment(dueDate).format('YYYY-MM-DD')
      ) {
        //if both start date and last updated date is greater than the current new due date, then we will set start date as today. Otherwise, we will mark last updated date as start date
        if (moment(taskDetails.meta.lastUpdated).format('YYYY-MM-DD') > moment(dueDate).format('YYYY-MM-DD')) {
          taskDetails.executionPeriod.start = moment().format('YYYY-MM-DD');
        } else {
          taskDetails.executionPeriod.start = moment(taskDetails.meta.lastUpdated).format('YYYY-MM-DD');
          taskDetails.executionPeriod.end = moment(dueDate).format('YYYY-MM-DD');
        }
      } else {
        taskDetails.executionPeriod.start = moment(taskDetails.executionPeriod.start).format('YYYY-MM-DD');
        taskDetails.executionPeriod.end = moment(dueDate).format('YYYY-MM-DD');
      }
    }
    //set both execution period the same date for automated tasks
    else if (taskDetails?.code?.coding?.[0]?.code === 'automated') {
      taskDetails.executionPeriod = {
        end: moment(dueDate).format('YYYY-MM-DD'),
        start: moment(dueDate).format('YYYY-MM-DD'),
      };
    }
    taskDetails = FHIRUtils.addUpdatedBy(taskDetails, personName);
    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskDetails.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Task due date has been changed to ` +
        taskDetails.executionPeriod.end +
        ` on ${taskDetails?.code?.coding?.[0]?.code} Task`,
      orgRef
    );
    return [FHIRUtils.buildResourceEntry(taskDetails, false), FHIRUtils.buildResourceEntry(provenance, false)];
  };

  const changeStatus = async (newStatus, taskDetails) => {
    let oldTaskStatus = taskDetails.status;
    taskDetails.status = newStatus;
    if (taskDetails?.statusReason) {
      taskDetails.statusReason = {
        text: '',
      };
    }

    let orgCode = props.orgCode;
    if (orgCode === 'All') {
      orgCode = await getPatientOrg(taskDetails);
    }
    let orgRef = { code: orgCode.toUpperCase(), display: orgCode.toLowerCase() };
    let patientId = taskDetails.for.reference.split('/')[1];
    if (taskDetails?.reasonCode?.text === 'intervention' && taskDetails.status === 'cancelled') {
      taskDetails.executionPeriod.end = moment().format('YYYY-MM-DD');
    }
    taskDetails = FHIRUtils.addUpdatedBy(taskDetails, personName);

    if (taskDetails?.reasonCode?.text === 'intervention' && taskDetails.status === 'cancelled') {
      // Update all 'requested', 'in-progress',and 'on-hold' tasks to 'cancelled' when intervention task is 'cancelled'
      let updateTasksNPatientStatusPayload = {
        patientId: patientId,
        status: 'INACTIVE',
        reason: 'DISENROLL',
      };

      hooksPatientStatus(updateTasksNPatientStatusPayload, token, personName).finally(() => {
        setTimeout(() => {
          props.tasksMutate();
        }, 3000);
      });
    } else if (
      taskDetails?.reasonCode?.text === 'intervention' &&
      taskDetails.status === 'in-progress' &&
      oldTaskStatus === 'cancelled'
    ) {
      // Update all not due 'cancelled' tasks to 'requested' when intervention task status is updated from 'cancelled' to 'in-progress'
      let updateTasksNPatientStatusPayload = {
        patientId: patientId,
        status: 'ACTIVE',
        reason: 'REENROLL',
      };

      hooksPatientStatus(updateTasksNPatientStatusPayload, token, personName).finally(() => {
        setTimeout(() => {
          props.tasksMutate();
        }, 3000);
      });
    } else {
      setTimeout(() => {
        props.tasksMutate();
      }, 3000);
    }

    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskDetails.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Status has been changed to ${
        taskDetails.status === 'received' ? 'outreach' : taskDetails.status.toString()
      } on ${taskDetails?.code?.coding?.[0]?.code} Task `,
      orgRef
    );
    return [FHIRUtils.buildResourceEntry(taskDetails, false), FHIRUtils.buildResourceEntry(provenance, false)];
  };

  const getPatientOrg = async (taskDetails) => {
    let patientId = taskDetails.for.reference.split('/')[1];
    //get org from task details, if org doesn't exist, fetch patient managing org
    let org = taskDetails?.basedOn
      ?.find((obj) => obj?.reference?.startsWith('Organization'))
      ?.reference?.split('/')?.[1];
    if (!org) {
      //get patient org
      await fhirGet(`/Patient/${patientId}`, token).then(
        (patient) => {
          org = patient?.managingOrganization?.reference?.split('/')?.[1];
        },
        (reason) => {
          console.error('Error fetching patient info: ', reason); // Error!
        }
      );
    }
    return org;
  };

  return (
    <>
      {loading && <Loader inline={'centered'} active />}
      <Form
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          position: 'relative',
          marginTop: '5px',
        }}
        onSubmit={handleSubmit}
      >
        {
          <Form.Dropdown
            icon={'user'}
            floating
            selection
            scrolling
            search
            disabled={props?.disableMultiSelect}
            selectOnBlur={false}
            options={props.practlist}
            placeholder="Reassign &nbsp;"
            value={allOptions.assignee}
            onChange={(e, value) => {
              // eslint-disable-next-line no-useless-computed-key
              setAllOptions({ ...allOptions, ['assignee']: value?.value });
            }}
            name="assignee"
          ></Form.Dropdown>
        }
        <Form.Dropdown
          name="status"
          selection
          selectOnBlur={false}
          key="status"
          disabled={props?.disableMultiSelect}
          placeholder="Change Status"
          value={allOptions.status}
          options={[
            { text: 'REQUESTED', key: 'requested', value: 'requested' },
            { text: 'IN-PROGRESS', key: 'in-progress', value: 'in-progress' },
            { text: 'COMPLETED', key: 'completed', value: 'completed' },
            { text: 'FAILED', key: 'failed', value: 'failed' },
            { text: 'CANCELLED', key: 'cancelled', value: 'cancelled' },
            { text: 'ON-HOLD', key: 'paused', value: 'on-hold' },
            { text: 'OUTREACH', key: 'outreach', value: 'received' },
          ]}
          onChange={(e, value) => {
            // eslint-disable-next-line no-useless-computed-key
            setAllOptions({ ...allOptions, ['status']: value?.value });
          }}
        />
        <Form.Field
          control={SemanticDatepicker}
          placeholder="Change Due Date"
          disabled={props?.disableMultiSelect}
          datePickerOnly
          clearable={false}
          value={allOptions.dueDate}
          onChange={(e, value) => {
            // eslint-disable-next-line no-useless-computed-key
            setAllOptions({ ...allOptions, ['dueDate']: value?.value });
          }}
        />
        <Form.Button primary content="Submit" disabled={props?.disableMultiSelect} />

        {errorMessage && <Message size="small" content={errorMessage} negative onDismiss={() => setErrorMessage('')} />}
      </Form>
    </>
  );
}
