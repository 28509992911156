import { Bundle, Resource } from "fhir/r4";

export const TRANSACTION_BUNDLE_TYPE = "transaction";
export const SDOH_CATEGORY_REFERRAL = "referral";
export const SDOH_CATEGORY_FOOD_INSECURITY = "food-insecurity";
export const SDOH_CATEGORY_TRANSPORTATION_INSECURITY = "transportation-insecurity";

export function buildEmptyBundle(type): Bundle {
  return {
    resourceType: "Bundle",
    type: type
  };
}

export function buildBundle(type, bundleEntries): Bundle {
  return {
    resourceType: "Bundle",
    type: type,
    entry: bundleEntries
  };
}

export function buildResourceEntries(resources: Resource[], deleteFlag: boolean): any {
  return resources.map((resource) => {
    return buildResourceEntry(resource, deleteFlag);
  });
}

export function buildResourceEntry(resource: fhir4.Resource, deleteFlag: boolean): any {
  return {
    fullUrl: resource.resourceType + "/" + resource.id,
    resource: resource,
    request: {
      method: deleteFlag ? "DELETE" : "PUT",
      url: resource.resourceType + "/" + resource.id
    }
  };
}

//TODO: This can be certainly better
export function addUpdatedBy(resource: any, updatedBy: string): any {
  if (updatedBy) {
    let updatedIdentifers: any = [];
    let identifiers = resource.identifier ? resource.identifier : [];
    if (identifiers.length === 0) {
      updatedIdentifers.push({
        system: "https://projectwell.io/fhir/identifiers/last-modified-by",
        value: updatedBy
      });
    } else {
      let updatedByIdentifierExists = false;
      for (let i = 0; i < identifiers.length; i++) {
        let identifier = identifiers[i];
        if (identifier.system === "https://projectwell.io/fhir/identifiers/last-modified-by") {
          identifier.value = updatedBy;
          updatedByIdentifierExists = true;
        }
        updatedIdentifers.push(identifier);
      }
      if (!updatedByIdentifierExists) {
        updatedIdentifers.push({
          system: "https://projectwell.io/fhir/identifiers/last-modified-by",
          value: updatedBy
        });
      }
    }
    resource.identifier = updatedIdentifers;
  }

  return resource;
}
