import { Patient } from "fhir/r4";
import * as FHIRUtils from "../fhirutils";
import * as crypto from "crypto";

export function build(
  managingOrgId: string,
  payermemberId: string,
  firstName: string,
  lastName: string,
  middleName: string,
  dob: string,
  gender: string,
  homePhone: string,
  cellPhone: string,
  email: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  country: string,
  languagePref: string,
  tags: [{ code: string; display: string }],
  updatedBy: string,
  otherId: string | undefined,
  mrnNumber: string | undefined,
  careteamStatus = "UN-ASSIGNED", //('UN-ASSIGNED'|'ASSIGNED')
  provider: string | undefined,
  practitioner: string | undefined,
  memberStatus: 'ACTIVE' | 'INACTIVE' | 'NEW' = 'NEW',
  eligibilityGroup: string | undefined = undefined
): Patient {
  let patientId = crypto
    .createHash("md5")
    .update(managingOrgId.toUpperCase() + payermemberId)
    .digest("hex");

  let patient: Patient = {
    resourceType: "Patient",
    id: patientId,
    active: true,
    identifier: [
      {
        system: "https://projectwell.io/fhir/identifiers/member-id",
        value: payermemberId
      },
      {
        system: "https://projectwell.io/fhir/identifiers/care-team-status",
        value: careteamStatus
      },
      {
        system: "https://projectwell.io/fhir/identifiers/member-status",
        value: memberStatus
      },
      {
        system: "https://projectwell.io/fhir/identifiers/other-id",
        value: otherId ?? null
      },
      {
        system: "https://projectwell.io/fhir/identifiers/mrn-number",
        value: mrnNumber ?? null
      },
      {
        system: 'https://projectwell.io/fhir/identifiers/last-modified-by',
        value: practitioner,
      },
    ],
    managingOrganization: {
      reference: "Organization/" + managingOrgId
    },
    name: [
      {
        family: lastName,
        given: [firstName, middleName]
      }
    ],
    telecom: [
      {
        system: "phone",
        value: homePhone,
        use: "home"
      },
      {
        system: "phone",
        value: cellPhone,
        use: "mobile"
      },
      {
        system: "email",
        value: email
      }
    ],
    gender: gender.toUpperCase() === 'MALE' ? 'male' : gender.toUpperCase() === 'FEMALE' ? 'female' : 'unknown',
    birthDate: dob,
    address: [
      {
        line: [address1, address2],
        city: city,
        state: state,
        postalCode: postalCode,
        country: country
      }
    ],
    communication: [
      {
        language: {
          text: languagePref
        }
      }
    ]
  };
  if (provider) {
    patient.extension = [
      {
        url: "https://projectwell.io/fhir/extensions",
        valueCoding: {
          code: "PCP_ORGANIZATION",
          display: provider,
          system: "https://projectwell.io/fhir/extensions"
        }
      }
    ];
  }

  if (tags && tags.length > 0) {
    patient.meta = {
      tag: tags
        .filter((tagEntry) => tagEntry.code !== "")
        .map((tagEntry) => {
          return {
            system: "https://projectwell.io/fhir/codes/patient-tags/",
            code: tagEntry.code,
            display: tagEntry.display
          };
        })
    };
  }

  if (eligibilityGroup) {
    patient.identifier.push({
      system: 'https://projectwell.io/fhir/identifiers/eligibility-group',
      value: eligibilityGroup,
    });
  }

  return FHIRUtils.addUpdatedBy(patient, updatedBy);
}

export function buildPatientWithTags(
  patient: Patient,
  tags: [{ code: string; display: string }],
  updatedBy: string
): Patient {
  if (tags && tags.length > 0) {
    patient.meta = {
      tag: tags
        .filter((tagEntry) => tagEntry.code !== "")
        .map((tagEntry) => {
          return {
            system: "https://projectwell.io/fhir/codes/patient-tags/",
            code: tagEntry.code,
            display: tagEntry.display
          };
        })
    };
  }

  return FHIRUtils.addUpdatedBy(patient, updatedBy);
}
