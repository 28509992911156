import React, { useState, useEffect } from "react";
import moment from "moment";
import { Message, Table } from "semantic-ui-react";
import useSWR from "swr";

import { useAuth } from "hooks";
import { fhirGet } from "services";
import { DEFAULT_ERROR_MESSAGE } from "utils";

import * as FHIRUtils from "../../../utils/FHIRUtils";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

const ACTIONABLE_INSIGHTS_CODE: Set<string> = new Set([
  "AI-Time-Sensitive",
  "AI-Non-Emergency",
  "AI-Emergency",
  "AI-Time-Sensitive"
]);

type ActionableInsightsProps = {
  patientId: string;
};

const ActionableInsights: React.FC<ActionableInsightsProps> = ({ patientId }) => {
  const { token } = useAuth();
  const [tableData, setTableData] = useState([]);

  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false
  };

  const { data: actionableInsightsData, error: aiError } = useSWR(
    [`/DocumentReference/?patient=${patientId}&status:not=entered-in-error&_sort=-_lastUpdated`, token],
    fhirGet,
    options
  );

  useEffect(() => {
    if (actionableInsightsData && actionableInsightsData.entry && actionableInsightsData.entry.length) {
      setTableData(
        actionableInsightsData.entry
          .map((entry) => FHIRUtils.parseFHIRNotes(entry.resource))
          .filter((note) => ACTIONABLE_INSIGHTS_CODE.has(note.code))
      );
    } else {
      setTableData([]);
    }
  }, [actionableInsightsData]);

  return (
    <div>
      {tableData && !aiError && (
        <Table celled padded color="purple">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>By</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData.map(({ id, author, date, description, type }) => (
              <Table.Row key={id}>
                <Table.Cell>{type}</Table.Cell>
                <Table.Cell>{description}</Table.Cell>
                <Table.Cell>{moment(date).fromNow()}</Table.Cell>
                <Table.Cell>{author}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      {((actionableInsightsData && !actionableInsightsData.entry) || !tableData || tableData.length === 0) && (
        <Message warning header="No Actionable Insights" />
      )}
      {aiError && (
        <Message negative>
          <Message.Header>{DEFAULT_ERROR_MESSAGE}</Message.Header>
          <p>{aiError.message}</p>
        </Message>
      )}
    </div>
  );
};

export default ActionableInsights;
