import React, { useState, useEffect } from "react";
import moment from "moment";
import { HorizontalGridLines, XYPlot, XAxis, YAxis, LineMarkSeries, LabelSeries } from "react-vis";
import { Button, Icon, Label, Popup } from "semantic-ui-react";

import { LAB_ALERT } from "./constants";
import { parseFHIRLab } from "utils/FHIRUtils";

type LabsProps = {
  data: any;
  setActiveTab: any;
  sidebar: boolean;
  updateQRID: any;
};

const Labs: React.FC<LabsProps> = ({ data, sidebar, setActiveTab, updateQRID }) => {
  const [filterGlucose, setFilterGlucose] = useState(false);
  const [graphView, setGraphView] = useState(false);
  const [highlightID, setHighlightID] = useState<any[]>([]);

  useEffect(() => {
    if (data[0]) {
      let lastDataInfo = "";
      let indexOfQuestionnaire = "";
      let currentDataInfo = "";
      let lastUpdatedDate = "";
      let tempHighlightId = highlightID;

      for (let i = 0; i < data.length; i++) {
        // if the data does have surveyResponse id, we will use the surveyResponse id as lastDataInfo
        if (data[i]?.resource?.derivedFrom?.length > 0) {
          if (i === 0) {
            lastUpdatedDate = data[i]?.resource?.effectivePeriod?.start;
          }
          indexOfQuestionnaire = "";
          currentDataInfo = "";
          indexOfQuestionnaire = data[i]?.resource?.["derivedFrom"].findIndex((obj) => (obj.display = "Questionnaire"));
          currentDataInfo = data[i]?.resource?.["derivedFrom"]?.[indexOfQuestionnaire]?.["reference"]?.split("/")?.[1];
        } else {
          //if the data does not have surveyResponse id, we will use the effectivePeriod as lastDataInfo
          currentDataInfo = data[i]?.resource?.effectivePeriod?.start;
        }
        if (!lastDataInfo) {
          lastDataInfo = currentDataInfo;
        }
        if (
          (currentDataInfo === lastDataInfo &&
            moment(lastUpdatedDate).diff(data[i]?.resource?.effectivePeriod?.start, "seconds") < 15) ||
          moment(lastDataInfo).diff(currentDataInfo, "seconds") < 15
        ) {
          if (!highlightID.includes(data[i]?.resource?.id)) {
            tempHighlightId.push(data[i]?.resource?.id);
            setHighlightID(tempHighlightId);
          }
        } else {
          if (highlightID.includes(data[i]?.resource?.id)) {
            tempHighlightId = tempHighlightId.filter((obs) => {
              return obs !== data[i]?.resource?.id;
            });
            setHighlightID(tempHighlightId);
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const glucoseOnly = data.filter((obs) => {
    return obs?.resource?.code?.coding[0]?.display.toLowerCase().includes("glucose");
  });

  const observationsList = (observation: any, i: number, counter: number) => {
    let parsedObservation = parseFHIRLab(observation?.resource);
    let result = LAB_ALERT(parsedObservation?.display, parsedObservation?.quantity);
    if (!sidebar) {
      return (
        <Popup
          key={i}
          flowing
          trigger={
            <Label
              key={"display" + i}
              style={{ margin: "0.15em" }}
              color={highlightID?.includes(observation?.resource?.id) ? "teal" : "grey"}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <b>
                    {parsedObservation.display}
                    {": "} {parsedObservation.quantity} {parsedObservation.unit}
                    {result ? (
                      <span className="bold" style={{ color: "#B03060" }}>
                        {" "}
                        ({result})
                      </span>
                    ) : (
                      ""
                    )}
                  </b>

                  <div>{moment(parsedObservation.effectiveDate).format("MMMM Do YYYY, h:mm:ss a")}</div>
                </div>

                <div style={{ width: 10 }} />

                {parsedObservation.derivedFrom && (
                  <Popup
                    flowing
                    trigger={
                      <Icon
                        size="large"
                        name={"external square"}
                        onClick={() => {
                          setActiveTab("SURVEYS");
                          updateQRID(parsedObservation.derivedFrom.substring(22), false);
                        }}
                      />
                    }
                    content={<span>Go to Survey Response</span>}
                    position="bottom left"
                    on="hover"
                  />
                )}
              </div>
            </Label>
          }
          content={
            <span key={i}>
              <Icon name="clock" />
              {parsedObservation.readingType}
              {parsedObservation.readingType && ". "}
              Last Updated By: {parsedObservation.author}.{" "}
              {moment.utc(parsedObservation.lastUpdated).local().format("MMMM Do YYYY, h:mm:ss a")}.
            </span>
          }
          on="hover"
        />
      );
    } else {
      return (
        <Label key={"sidebar" + i} basic color={highlightID?.includes(observation?.resource?.id) ? "teal" : "grey"}>
          <div style={{ display: "flex" }}>
            <div>
              <div>
                <b>
                  {parsedObservation.display}
                  {": "} {parsedObservation.quantity} {parsedObservation.unit}
                  {result ? (
                    <span className="bold" style={{ color: "#B03060" }}>
                      {" "}
                      ({result})
                    </span>
                  ) : (
                    ""
                  )}
                </b>
              </div>
              <div>{moment(parsedObservation.effectiveDate).format("MMMM Do YYYY, h:mm:ss a")}</div>
            </div>
          </div>
        </Label>
      );
    }
  };

  const graphData = glucoseOnly.map((observation) => {
    let date = new Date(observation.resource.effectivePeriod.start).toLocaleDateString();

    return {
      x: date.substring(0, date.length - 5),
      y: observation.resource.valueQuantity.value
    };
  });

  return (
    <>
      {data && data.length > 0 && (
        <>
          {!sidebar && (
            <div style={{ display: "flex" }}>
              <Button.Group size="mini">
                <Button
                  content={"All"}
                  color={!filterGlucose ? "blue" : "grey"}
                  onClick={() => {
                    setGraphView(false);
                    setFilterGlucose(false);
                  }}
                />
                <Button
                  content={"Glucose ONLY"}
                  color={filterGlucose ? "blue" : "grey"}
                  onClick={() => setFilterGlucose(true)}
                />
              </Button.Group>

              <div style={{ width: "20px" }} />
              {filterGlucose && (
                <Button.Group size="mini">
                  <Button content={"Labels"} color={!graphView ? "blue" : "grey"} onClick={() => setGraphView(false)} />
                  <Button content={"Graph"} color={graphView ? "blue" : "grey"} onClick={() => setGraphView(true)} />
                </Button.Group>
              )}
            </div>
          )}

          {graphView && (
            <XYPlot width={900} height={250} xType="ordinal">
              <HorizontalGridLines />
              <LineMarkSeries
                data={graphData.reverse()}
                style={{
                  strokeWidth: "15px",
                  fill: "none"
                }}
                lineStyle={{ stroke: "orange" }}
                markStyle={{ stroke: "teal", fill: "teal" }}
              />
              <LabelSeries
                key={"glucoselabel"}
                data={graphData}
                getLabel={(d) => `${d.y}`}
                style={{ fill: "white" }}
                labelAnchorX="middle"
                labelAnchorY="middle"
              />
              <XAxis />
              <YAxis />
            </XYPlot>
          )}
          {!graphView &&
            (!filterGlucose ? data : glucoseOnly).map((observation: any, i: number) =>
              observationsList(observation, i, (!filterGlucose ? data : glucoseOnly).length)
            )}
        </>
      )}
    </>
  );
};

export default Labs;
