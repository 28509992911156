import React from "react";
import PatientOrders from "components/common/food/orders";

type FoodProps = {
  patientId: string;
};

const Food: React.FC<FoodProps> = ({ patientId }) => {
  return <PatientOrders patientId={patientId} />;
};

export default Food;
