import { useState, useEffect } from "react";
import moment from "moment";
import { Loader, List, Grid, Icon, Divider, Message, Dropdown } from "semantic-ui-react";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import QuestionnairesList from "./list";
import Questionnaire from "./questionnaire";
import { get } from "lodash";
import { QuestionnaireResponseHelper, QuestionnaireHelper, QuestionnaireExtensionHelper } from "utils/Helpers";
import { useAuth } from "hooks";
import { multipleFhirGet } from "services";

type QuestionnaireResponseCardProps = {
  consentDataMutate: any;
  fhirPatient: any;
  org: string;
  patientEmail: string;
  patientFirstName: string;
  patientId: string;
  patientPhoneNumber: string;
  profileMutate: any;
  QRID: any;
  questionnaireData: any;
  questionnaireResponseData: any;
  questionnaireResponseMutate: any;
  refreshPatientDetails: any;
  tasksMutate: any;
};

const QuestionnaireResponseCard: React.FC<QuestionnaireResponseCardProps> = ({
  consentDataMutate,
  fhirPatient,
  org,
  patientEmail,
  patientFirstName,
  patientId,
  patientPhoneNumber,
  profileMutate,
  QRID,
  questionnaireData,
  questionnaireResponseData,
  questionnaireResponseMutate,
  refreshPatientDetails,
  tasksMutate
}) => {
  const { token } = useAuth();
  const [currentQuestionnaireResponse, setCurrentQuestionnaireResponse] = useState<any>();
  let [QuestionnaireResponseData, setQuestionnaireResponseData] = useState(questionnaireResponseData);
  let [activeItem, setActiveItem] = useState("");
  let [questionnaireResponseFilter, setQuestionnaireResponseFilter] = useState<any>({
    title: null,
    status: null
  });
  const [questionnaireTypesOrg, setQuestionnaireTypesOrg] = useState([]);
  let [dateState, setDateState] = useState("");
  let [startRange, setStartRange] = useState("");
  let [endRange, setEndRange] = useState("");
  let QRDataObj = {};
  const [surveyLanguage, setSurveyLanguage] = useState("");
  const [surveyImage, setSurveyImage] = useState({});
  const [containsLang, setContainsLang] = useState(false);
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState();
  const languagesCareBetty = [
    { key: "es", text: "Español", value: "es" },
    { key: "en-us", text: "English (US)", value: "en-us" },
    { key: "so", text: "Somali", value: "so" }
  ];
  const [imgLoader, setImgLoader] = useState(false);

  let QRHelper = new QuestionnaireResponseHelper(get(QuestionnaireResponseData, "entry[0].resource") ?? []);
  useEffect(() => {
    if (QRID && questionnaireData) {
      if (QRID.isNewResponse && questionnaireData.entry) {
        setActiveItem("");

        let survey =
          questionnaireData.entry.filter((questionnaire) => questionnaire.resource.id === QRID.id)[0] &&
          questionnaireData.entry.filter((questionnaire) => questionnaire.resource.id === QRID.id)[0].resource;
        setCurrentQuestionnaire(survey);
      } else {
        setActiveItem(QRID.id);
        setCurrentQuestionnaire(null);
      }
    } else if (QuestionnaireResponseData.entry) {
      handleItemClick(QRHelper.getId());
      setActiveItem(QRHelper.getId());
      QRDataObj[QRHelper.getId()].hasTranslation ? setContainsLang(true) : setContainsLang(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QuestionnaireResponseData, token, QRID, questionnaireData]);

  let questionnaires = {};
  const handleItemClick = async (name) => {
    QRDataObj[name].hasTranslation ? setContainsLang(true) : setContainsLang(false);

    let questions = QRDataObj[name].questions;
    let valueReferences = [];

    //QAS, questionnaireCategory
    questions &&
      questions.forEach((QAS) => {
        QAS.item &&
          QAS.item.forEach((QA) => {
            let answer = QA.answer;

            if (answer !== undefined && answer?.[answer.length - 1]?.valueReference !== undefined) {
              valueReferences.push(answer[answer.length - 1].valueReference);
            }
          });
      });

    if (valueReferences?.length) {
      let referenceUrls = [];

      valueReferences.forEach((element) => {
        if (element.reference && !surveyImage.hasOwnProperty(element?.reference?.split("/")[1])) {
          referenceUrls.push("/" + element.reference + `?patient=${patientId}`);
        }
      });
      setImgLoader(true);
      let referenceContents: any = await multipleFhirGet(token, ...referenceUrls);
      setImgLoader(false);
      if (referenceContents?.length) {
        let imageContent = {};
        referenceContents.forEach((reference) => {
          imageContent[reference.id] = {
            data: `data:${reference.content[0].attachment.contentType};base64, ${reference.content[0].attachment.data}`,
            contentType: reference.content[0].attachment.contentType
          };
        });
        setSurveyImage({ ...imageContent, ...surveyImage });
      }
    }
    setActiveItem(name);
    setError("");
  };

  const handleLanguageSelect = (event, { name, value }) => {
    setSurveyLanguage(value);
  };

  const handleQRFilter = (event, attributeName) => {
    setSurveyImage({});
    let value = event.target && event.target.textContent;
    setQuestionnaireResponseFilter((prevState) => ({ ...prevState, [attributeName]: value }));
  };

  const handleChangeDate = (event, { name, value }) => {
    setDateState(value);
    let temp = value.split(" - ");
    setStartRange(temp[0]);
    setEndRange(temp[1]);
  };

  const [error, setError] = useState("");

  const getTranslation = (item: any): string | undefined => {
    let translation: string | undefined;
    let extHelper = new QuestionnaireExtensionHelper(item?.extension);
    if (extHelper.getTargetTranslation(surveyLanguage)) {
      if (extHelper.getTargetTranslation(surveyLanguage) === undefined) {
        //use english
        translation = item.valueCoding.display;
      }
      translation = extHelper.getTargetTranslation(surveyLanguage);
    } else {
      translation = item?.valueCoding?.display;
    }
    return translation;
  };

  let refreshQA = (reset) => {
    questionnaireResponseMutate().then((data) => {
      if (data && data.entry && reset && reset !== undefined) {
        setActiveItem(data.entry[0].resource.id);
        setCurrentQuestionnaire(null);
        //props?.setQRID(undefined);
      }
      setQuestionnaireResponseData(data);
    });
  };

  const handleTranslation = (QTitle) => {
    let hasTranslation = false;

    // eslint-disable-next-line array-callback-return
    let survey = questionsList.filter((resources) => {
      if (resources.resource.title === QTitle) return resources.resource;
    });
    if (survey && survey[0]) {
      let qh = new QuestionnaireHelper(survey?.[0]?.resource);
      for (let i = 0; i < qh.getItemListLength(); i++) {
        for (let j = 0; j < qh.getGroupLength(i); j++) {
          let translationHelper = new QuestionnaireExtensionHelper(qh.getQuestionExtension(i, j));
          if (translationHelper.hasTranslation()) {
            hasTranslation = true;
          }
        }
      }
    }
    return hasTranslation;
  };

  const createDataObj = (QRItem, questionnaireTitle) => {
    let translationExists = false;
    translationExists = handleTranslation(questionnaireTitle);
    return {
      authoredOn: QRItem.resource.authored && moment(QRItem.resource.authored, "YYYY-MM-DD").format("MMMM Do, YYYY"),
      questionnaire: QRItem.resource.questionnaire.substring(14, QRItem.resource.questionnaire.length),
      userName: QRItem.resource.identifier ? QRItem.resource.identifier.value : "",
      questions: QRItem.resource.item,
      header: questionnaireTitle,
      hasTranslation: translationExists
    };
  };

  useEffect(() => {
    refreshQA(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  let questions = {};
  let questionsList;
  if (!QuestionnaireResponseData) {
    return <Loader active inline="centered" />;
  } else if (QuestionnaireResponseData) {
    let QRData = QuestionnaireResponseData.entry; // Responses data
    questionsList = questionnaireData.entry; // Questions list

    QRData &&
      QRData.forEach((QRItem) => {
        let QRHelper = new QuestionnaireResponseHelper(QRItem.resource ?? []);
        let questionnaireId = QRHelper.getQuestionnaireID();
        let questionnaireTitle = "";
        if (questionnaireData) {
          if (questionnaireData.entry) {
            for (let i = 0; i < questionnaireData.entry.length; i++) {
              let questionnaireHelper = new QuestionnaireHelper(questionnaireData.entry[i].resource);
              let QExtHelper = new QuestionnaireExtensionHelper(questionnaireHelper.getSurveyExtension());
              if (questionnaireId === questionnaireHelper.getId()) {
                questionnaireTitle = QExtHelper.getInternalTitle() ?? questionnaireHelper.getTitle();
                break;
              }
            }
          }
        }
        QRDataObj[QRHelper.getId()] = createDataObj(QRItem, questionnaireTitle);
      });

    // Preparing questions from all the Questionnaire data using 'linkId' map

    questionsList.forEach((resources) => {
      let resource = resources.resource;
      questions[resource.id] = {};
      questions[resource.id].title = resource.title;
      //questions[resource.id].answers = resource.answers;

      resource.item &&
        resource.item.length &&
        resource.item.forEach((category) => {
          if (questions.hasOwnProperty(category.linkId)) {
            questions[category.linkId].title = category.text;
          } else {
            questions[category.linkId] = {};
            questions[category.linkId].title = category.text;
          }
          category.item &&
            category.item.length &&
            category.item.forEach((question) => {
              if (questions.hasOwnProperty(question.linkId)) {
                let translation: string | undefined = getTranslation(question);
                questions[question.linkId].title = translation ? translation : question.text;
                questions[question.linkId].answers = question.answerOption;
              } else {
                questions[question.linkId] = {};
                questions[question.linkId].title = question.text;
                questions[question.linkId].answers = question.answerOption;
              }
            });
        });
    });

    // building different questions mapper
    questionsList.forEach((questionnaire) => {
      let resource = questionnaire.resource;
      questionnaires[resource.id] = [];

      resource.item &&
        resource.item.length &&
        resource.item.forEach((category) => {
          let translation: string | undefined = getTranslation(category);
          let categoryData = {
            linkId: category.linkId,
            text: translation ? translation.toString() : category.text,
            item: []
          };

          category.item &&
            category.item.length &&
            category.item.forEach((question) => {
              let translation: string | undefined = getTranslation(question);
              let questionData = {
                linkId: question.linkId,
                text: translation ? translation : question.text,
                answerOptions: question.answerOption
              };
              categoryData.item.push(questionData);
            });

          questionnaires[resource.id].push(categoryData);
        });
    });

    let displayImage = (valueReference: any) => {
      let referenceId = valueReference?.reference?.split("/")?.[1];
      return (
        <div>
          {surveyImage[referenceId]?.contentType !== "application/pdf" && (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img key={referenceId} id={referenceId} width="100%" src={surveyImage[referenceId]?.data} alt="image" />
          )}
          {surveyImage[referenceId]?.contentType === "application/pdf" && (
            <object key={referenceId}>
              <embed id={referenceId} type="text/html" width="100%" height="600" src={surveyImage[referenceId].data} />
            </object>
          )}
        </div>
      );
    };

    let displayAnswers = (answers, answerOptions) => {
      let existingAnswer = false;

      return (
        <div>
          {answerOptions
            ? // eslint-disable-next-line array-callback-return
              answerOptions.map((option) => {
                if (option.valueCoding) {
                  let translation: string | undefined = getTranslation(option.valueCoding);
                  let jsx = <div key={option.valueCoding.code}>{translation ?? option.valueCoding.display}</div>;
                  for (let i = 0; i < answers.length; i++) {
                    if (answers[i].valueCoding) {
                      if (option.valueCoding.code === answers[i].valueCoding.code) {
                        existingAnswer = true;
                        jsx = (
                          <List.Header key={option.valueCoding.code}>
                            <span> {translation ?? option.valueCoding.display} </span>
                            <List.Icon name="check" />
                          </List.Header>
                        );
                        break;
                      }
                    }
                  }
                  return jsx;
                } else if ((option.valueInteger && option.valueInteger >= 0) || option.valueInteger === 0) {
                  let QA = option.valueInteger;
                  let jsx = <div key={QA}>{QA}</div>;
                  for (let i = 0; i < answers.length; i++) {
                    if ((answers[i].valueInteger && answers[i].valueInteger >= 0) || answers[i].valueInteger === 0) {
                      if (option.valueInteger === answers[i].valueInteger) {
                        jsx = (
                          <List.Header key={QA}>
                            <span> {QA} </span>
                            <List.Icon name="check" />
                          </List.Header>
                        );
                        break;
                      }
                    }
                  }
                  return jsx;
                }
              }) /* : answers[answers.length - 1].valueString ? (
            <List.Header key={answers[answers.length - 1].valueString}>
              <span> {answers[answers.length - 1].valueString} </span>
              <List.Icon name="check" />
            </List.Header>
          ) */
            : answers[answers.length - 1].valueInteger && (
                <List.Header key={answers[answers.length - 1].valueInteger}>
                  <span> {answers[answers.length - 1].valueInteger} </span>
                  <List.Icon name="check" />
                </List.Header>
              )}
          {
            /* answers.length > 1 &&
            answers[answers.length - 2].valueCoding && */
            answers[answers.length - 1].valueString && (
              <List.Header key={answers[answers.length - 1].valueString}>
                <span style={{ whiteSpace: "pre-wrap" }}> {answers[answers.length - 1].valueString} </span>
                <List.Icon name="check" />
              </List.Header>
            )
          }
          {answers[answers.length - 1].valueDate && (
            <List.Header key={answers[answers.length - 1].valueDate}>
              <span style={{ whiteSpace: "pre-wrap" }}>{answers[answers.length - 1].valueDate}</span>
              <List.Icon name="check" />
            </List.Header>
          )}
          {answers[answers.length - 1].valueDecimal && (
            <List.Header key={answers[answers.length - 1].valueDecimal}>
              <span style={{ whiteSpace: "pre-wrap" }}>{answers[answers.length - 1].valueDecimal}</span>
              <List.Icon name="check" />
            </List.Header>
          )}
          {answers[answers.length - 1].valueReference && displayImage(answers[answers.length - 1].valueReference)}
          {!existingAnswer &&
            // eslint-disable-next-line array-callback-return
            answers.map((answer, index) => {
              if (answer.valueCoding) {
                let translation: string | undefined = getTranslation(answer.valueCoding);
                return (
                  <List.Header key={index}>
                    <span>Retired Answer: {translation ?? answer.valueCoding.display} </span>
                    <List.Icon name="check" />
                  </List.Header>
                );
              }
            })}
        </div>
      );
    };

    let filterResponses = (
      questionnaireTitle: string,
      state: { title: null | string; status: null | string },
      questionnaireStatus: string
    ): boolean => {
      let filterResult: boolean = state?.title?.toLowerCase() === questionnaireTitle?.toLowerCase();
      let statusCheck: boolean = !state.status || questionnaireStatus === state.status;
      if ((!state.title || filterResult) && statusCheck) {
        return true;
      } else if (questionnaireTitle === "") {
        return false;
      }
      return false;
    };

    let displayQA = (QAS, questionnaireCategory) => {
      return (
        <List.List>
          {questionnaireCategory &&
            questionnaireCategory.item &&
            questionnaireCategory.item.map((QA) => {
              let answer =
                QAS &&
                QAS.item &&
                QAS.item.find((q) => q.linkId === QA.linkId) &&
                QAS.item.find((q) => q.linkId === QA.linkId).answer;

              return (
                <List.Item key={QA.linkId}>
                  <List.Icon color="orange" name="file alternate outline" />
                  <List.Content>
                    <List.Description>
                      {QA.text ?? QA.linkId}
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          marginLeft: "15px"
                        }}
                      >
                        {answer && displayAnswers(answer, QA.answerOptions)}
                      </div>
                    </List.Description>
                  </List.Content>
                </List.Item>
              );
            })}
        </List.List>
      );
    };

    let displayCategories = (categories, questionnaireCategories) => {
      return (
        <>
          {imgLoader && <Loader active inline="centered" content={"Loading..."} />}
          {!imgLoader && (
            <List.List>
              {questionnaireCategories &&
                questionnaireCategories.map((category) => {
                  return (
                    <List.Item key={category.linkId}>
                      <List.Content>
                        <List.List>
                          {displayQA(categories && categories.find((cat) => cat.linkId === category.linkId), category)}
                        </List.List>
                      </List.Content>
                    </List.Item>
                  );
                })}
            </List.List>
          )}
        </>
      );
    };

    return (
      <div>
        <div>
          <QuestionnairesList
            patientId={patientId}
            patientFirstName={patientFirstName}
            patientPhoneNumber={patientPhoneNumber}
            patientEmail={patientEmail}
            org={org}
            profileMutate={profileMutate}
            questionnaireData={questionnaireData}
            refreshQA={refreshQA}
            setCurrentQuestionnaire={setCurrentQuestionnaire}
            setCurrentQuestionnaireResponse={setCurrentQuestionnaireResponse}
            setQuestionnaireTypesOrg={setQuestionnaireTypesOrg}
            language={surveyLanguage}
            handleLanguageSelect={handleLanguageSelect}
            handleTranslation={handleTranslation}
            langsList={languagesCareBetty}
            containsLang={containsLang}
            setContainsLang={setContainsLang}
          />
        </div>
        <Divider />
        <List relaxed="very">
          <List.Item>
            {QuestionnaireResponseData && QuestionnaireResponseData.entry && (
              <div>
                <Grid>
                  <Grid.Column
                    width={5}
                    style={{
                      maxHeight: "700px",
                      overflow: "auto",
                      borderRight: "2px ridge"
                    }}
                  >
                    <div>
                      <Dropdown
                        control={"select"}
                        scrolling
                        selection
                        placeholder="Filter by Survey"
                        selectOnBlur={false}
                        clearable
                        options={questionnaireTypesOrg}
                        value={questionnaireResponseFilter.title}
                        onChange={(e) => handleQRFilter(e, "title")}
                      />
                      <div style={{ height: "15px" }} />
                      <DatesRangeInput
                        name="datesRange"
                        placeholder="Filter by Date Range"
                        popupPosition="bottom left"
                        value={dateState}
                        iconPosition="right"
                        dateFormat="YYYY-MM-DD"
                        onChange={handleChangeDate}
                        clearable
                      />

                      <List divided selection>
                        {QRData &&
                          QRData.map((QRItem) => {
                            let currentQRHelper = new QuestionnaireResponseHelper(QRItem?.resource);
                            var QRExtensionHelper = new QuestionnaireExtensionHelper(currentQRHelper.getExtension());

                            let authoredOn =
                              QRItem.resource.authored &&
                              moment(QRItem.resource.authored, "YYYY-MM-DD").local(true).format("MMMM Do, YYYY");
                            let questionnaireId =
                              QRItem.resource.questionnaire && QRItem.resource.questionnaire.split("/")[1];
                            let questionnaireDisplayTitle: string = "";
                            if (questionnaireData) {
                              if (questionnaireData.entry) {
                                for (let i = 0; i < questionnaireData.entry.length; i++) {
                                  if (questionnaireId === questionnaireData.entry[i].resource.id) {
                                    let QHelper = new QuestionnaireHelper(questionnaireData.entry[i].resource);
                                    let QExtHelper = new QuestionnaireExtensionHelper(QHelper.getSurveyExtension());
                                    QExtHelper.getInternalTitle()
                                      ? (questionnaireDisplayTitle = QExtHelper.getInternalTitle())
                                      : (questionnaireDisplayTitle = QHelper.getTitle());

                                    break;
                                  }
                                }
                              }
                            }

                            return (
                              //maybe these filters could go in a useEffect
                              //date range filter
                              (startRange
                                ? moment(startRange).format() <= moment(QRItem.resource.authored).format() &&
                                  moment(endRange).add(1, "days").format() > moment(QRItem.resource.authored).format()
                                : true) &&
                              //questionnaire response filter
                              filterResponses(
                                questionnaireDisplayTitle,
                                questionnaireResponseFilter,
                                QRItem.resource.status
                              ) &&
                              //remove deleted response from displaying
                              QRItem.resource.status !== "entered-in-error" && (
                                <List.Item
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: activeItem === QRItem.resource.id ? "#e7feff" : "white",
                                    padding: "15px",
                                    borderRadius: "8px",
                                    margin: "12px 0px",
                                    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)",
                                    border:
                                      QRItem.resource.status.toString().toLowerCase() === "completed"
                                        ? "2px solid green"
                                        : ""
                                  }}
                                  name={questionnaireId}
                                  key={QRItem.resource.id}
                                  className={activeItem === QRItem.resource.id ? "selectedlistitem" : ""}
                                  active={activeItem === QRItem.resource.id}
                                  onClick={() => {
                                    handleItemClick(QRItem.resource.id);
                                    setCurrentQuestionnaire(null);
                                  }}
                                >
                                  <List.Content className={activeItem === QRItem.resource.id ? "selectedlistitem" : ""}>
                                    <List.Header as="a">{questionnaireDisplayTitle}</List.Header>
                                    <List.Description as="a">
                                      {/* //if completed survey has completed date, check if we have extension, if we do, we use the completedDate, otherwise we use authoredOn date. */}
                                      {QRItem.resource.status.toString().toLowerCase() === "completed"
                                        ? QRExtensionHelper.getCompletedDate()
                                          ? QRItem.resource.status.toString().toLowerCase() +
                                            ": " +
                                            moment(QRExtensionHelper.getCompletedDate()).local(true).fromNow() //.format('MM/DD/YYYY')
                                          : QRItem.resource.status.toString().toLowerCase() +
                                            ": " +
                                            moment(authoredOn, "MMMM Do, YYYY").local(true).fromNow() //.format('MM/DD/YYYY')
                                        : QRItem.resource.status.toString().toLowerCase() +
                                          ": " +
                                          moment(authoredOn, "MMMM Do, YYYY").local(true).format("MM/DD/YYYY")}
                                    </List.Description>
                                    <List.Description as="a">
                                      {QRItem.resource.identifier && QRItem.resource.identifier.value}{" "}
                                      {QRItem.resource.status.toString().toLowerCase() === "completed" && (
                                        <Icon name={"check circle"} color={"green"}></Icon>
                                      )}
                                    </List.Description>
                                    <List.Description as="em"></List.Description>
                                  </List.Content>
                                </List.Item>
                              )
                            );
                          })}
                      </List>
                    </div>
                  </Grid.Column>
                  <Grid.Column style={{ maxHeight: "700px", overflow: "auto" }} width={11}>
                    {currentQuestionnaire && (
                      <Questionnaire
                        questionnaire={currentQuestionnaire}
                        patientId={patientId}
                        refreshQA={refreshQA}
                        profileMutate={profileMutate}
                        currentQuestionnaireResponse={currentQuestionnaireResponse}
                        setCurrentQuestionnaire={setCurrentQuestionnaire}
                        language={surveyLanguage}
                        handleLanguageSelect={handleLanguageSelect}
                        langsList={languagesCareBetty}
                        org={org}
                        consentDataMutate={consentDataMutate}
                        tasksMutate={tasksMutate}
                        fhirPatient={fhirPatient}
                        refreshPatientDetails={refreshPatientDetails}
                      />
                    )}
                    {!currentQuestionnaire && (
                      <div>
                        <h3
                          style={{
                            marginTop: "1.5%",
                            marginBottom: "0%",
                            display: "inline"
                          }}
                        >
                          {activeItem !== "" && QRDataObj[activeItem] && QRDataObj[activeItem].header}
                        </h3>

                        {error.length > 0 && (
                          <Message negative>
                            <Message.Header>{error}</Message.Header>
                          </Message>
                        )}
                        <div style={{ marginTop: "1%", display: "inline" }}>
                          {activeItem !== "" &&
                            QRDataObj[activeItem] &&
                            displayCategories(
                              QRDataObj[activeItem].questions,
                              questionnaires[QRDataObj[activeItem].questionnaire]
                            )}
                        </div>
                      </div>
                    )}
                    {/* {loader && <Loader active inline="centered" content={'Deleting survey response...'} />} */}
                    {QuestionnaireResponseData && activeItem === "" && (
                      <Loader active inline="centered" content={"Loading survey response..."} />
                    )}
                  </Grid.Column>
                </Grid>
              </div>
            )}
            {QuestionnaireResponseData && !QuestionnaireResponseData.entry && (
              <div>
                <Grid>
                  <Grid.Column width={3} style={{ maxHeight: "700px", overflow: "auto" }}>
                    <Message warning header="No Surveys Found!!!" />
                  </Grid.Column>
                  <Grid.Column style={{ maxHeight: "700px", overflow: "auto" }} width={13}>
                    {/* {loader && <Loader active inline="centered" content={'Deleting survey response...'} />} */}
                    {currentQuestionnaire && (
                      <Questionnaire
                        questionnaire={currentQuestionnaire}
                        patientId={patientId}
                        refreshQA={refreshQA}
                        profileMutate={profileMutate}
                        currentQuestionnaireResponse={currentQuestionnaireResponse}
                        setCurrentQuestionnaire={setCurrentQuestionnaire}
                        language={surveyLanguage}
                        handleLanguageSelect={handleLanguageSelect}
                        langsList={languagesCareBetty}
                        org={org}
                        fhirPatient={fhirPatient}
                        refreshPatientDetails={refreshPatientDetails}
                        tasksMutate={tasksMutate}
                      />
                    )}
                  </Grid.Column>
                </Grid>
              </div>
            )}
          </List.Item>
        </List>
      </div>
    );
  }
};

export default QuestionnaireResponseCard;
