const LAB_RANGES = {
  Glucose: {
    min: 70,
    max: 99,
    "borderline-high": 125,
    high: 126
  },
  "Systolic blood pressure": {
    max: 119,
    "Pre-Hypertension": 139,
    Hypertension: 179,
    "Hypertensive Crisis": 180
  },
  "Diastolic blood pressure": {
    max: 79,
    "Pre-Hypertension": 89,
    Hypertension: 119,
    "Hypertensive Crisis": 120
  },
  "Hemoglobin A1c/Hemoglobin. total in Blood": {
    max: 5.6,
    "borderline-high": 6.4,
    high: 6.5
  },
  "CO2 SerPl-sCnc": {
    min: 23,
    max: 29
  },
  "Cholesterol in HDL": {
    min: 60,
    "borderline-low": 41,
    low: 40
  },
  "Creatinine SerPl-mCnc": {
    min: 0.6,
    max: 1.3
  },
  "Potassium SerPl-sCnc": {
    min: 3.5,
    max: 5
  },
  "Triglyceride SerPl-mCnc": {
    max: 149,
    "borderline-high": 199,
    high: 200
  },
  "Sodium-Ser/Plas": {
    min: 136,
    max: 146
  },
  LDL: {
    max: 99,
    "borderline-high": 159,
    high: 160
  },
  "BUN SerPl-mCnc": {
    min: 6,
    max: 21
  },
  "Chloride SerPl-sCnc": {
    min: 95,
    max: 105
  },
  "Albumin SerPl-mCnc": {
    min: 3.5,
    max: 5.5
  },
  "Calcium SerPl-mCnc": {
    min: 8.5,
    max: 10.5
  },
  "ALT SerPl-cCnc": {
    min: 7,
    max: 56
  },
  "Total Cholesterol": {
    max: 199,
    "borderline-high": 239,
    high: 240
  },
  eGFR: {
    min: 90
  },
  uACR: {
    max: 30
  }
};

export const LAB_ALERT = (labName, labResult) => {
  if (LAB_RANGES[labName]) {
    let single_lab_range = LAB_RANGES[labName];
    if ("min" in single_lab_range && labResult < single_lab_range["min"]) {
      if (single_lab_range["borderline-low"] && labResult > single_lab_range["borderline-low"]) {
        return "Borderline-L";
      } else {
        return "L";
      }
    } else if ("max" in single_lab_range && labResult > single_lab_range["max"]) {
      if (single_lab_range["borderline-high"] && labResult < single_lab_range["borderline-high"]) {
        return "Borderline-H";
      } else if (single_lab_range["Pre-Hypertension"] && labResult < single_lab_range["Pre-Hypertension"]) {
        return "Pre-Hypertension";
      } else if (single_lab_range["Hypertension"] && labResult < single_lab_range["Hypertension"]) {
        return "Hypertension";
      } else if (single_lab_range["Hypertensive Crisis"] && labResult < single_lab_range["Hypertensive Crisis"]) {
        return "Hypertensive Crisis";
      } else {
        return "H";
      }
    }
    return "";
  } else {
    return "No reference";
  }
};
