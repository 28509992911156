import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Grid, Loader, Segment, Icon } from 'semantic-ui-react';
import * as FHIRUtils from '../../../../utils/FHIRUtils';
import moment from 'moment';
import TaskDetail from './detail';
import { NewTaskList } from './list';
import AddTask from './add';
import useSWR from 'swr';

import { useAuth } from "hooks";
import { multipleFhirGet } from "services";

import TaskFilters from './filters';
import { AssignTask } from './assign';

const TaskCard = forwardRef((props: any, ref) => {
  let [state, setState] = useState<any>({
    category: null,
    period: null,
    status: null,
    priority: null,
    practitioner: null,
    vendor: null,
    referral: null,
    subCategory: null,
    dateStatus: null,
  });
  let fhirPractitioners = props.PractitionerData;
  let [taskList, setTaskList] = useState<any>(null);
  let [currentTaskId, setCurrentTaskId] = useState<any>(null); //This is for highlighting task in list
  let [newTaskId, setNewTaskId] = useState(null);
  let [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  let [datevalue, setDateValue] = useState([]);
  let [open, setOpen] = useState(false);
  let [loader, setLoader] = useState<any>(true);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [render, setRender] = useState<boolean>(false);
  let [referralIdList, setReferralIdList] = useState([]);
  const [nextPage, setNextPage] = useState<boolean>(false);
  const [nextPageURL, setNextPageURL] = useState(null);
  const [renderURL, setRenderURL] = useState(true);
  //originalTaskList is used to get the original Task List without filtering the subCategory.
  let [originalTaskList, setOriginalTaskList] = useState<any>(null);
  let [disableMultiSelect, setDisableMultiSelect] = useState<boolean>(false); //use to disable multi select tasks when none selected tasks and orgId not ALL

  useEffect(() => {
    if (window.location.search) {
      let taskId = window.location.search?.split('=')?.[1];
      setCurrentTaskId(taskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  //for every change in the state, we need to set the renderURL to true.
  useEffect(() => {
    setRenderURL(true);
    //we want to filter the subCategory if selected, because the url will not fetch again if the url is the same
    if (state.subCategory) {
      setLoader(false);
      if (!originalTaskList) {
        setOriginalTaskList(taskList);
      }
      if (originalTaskList || taskList) {
        let filtereTtaskList = (originalTaskList ?? taskList).filter((t) => {
          return t.reasonCode && t.reasonCode?.text === state.subCategory;
        });
        setTaskList(filtereTtaskList);
      } else {
        setTaskList(null);
      }
    } else {
      setTaskList(originalTaskList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, props.orgId]);

  useEffect(() => {
    if (selectedTasks.length < 1) {
      setDisableMultiSelect(true);
    } else {
      setDisableMultiSelect(false);
    }
  }, [taskList, selectedTasks]);

  let activityBool = props.activity ? props.activity : false;
  const { organizationId, token } = useAuth();

  //remove duplicates in the array
  function onlyUnique(value, index, array) {
    //only need the last occurance elements because they have the most updated data
    return array.findLastIndex((obj) => obj.id === value.id) === index;
  }

  const taskOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    onSuccess: (data) => {
      setLoader(true);
      setOriginalTaskList(null);
      //if state.period is selected but renderURL is false, which means it already reached to the end of the result, then we want to return
      if (state.period !== null && !renderURL) {
        setLoader(false);
        return;
      }
      //compare current URL with previous URL to get whether currentResult is from the same request when filtering date range, if period filter is not selected, we are setting currentURL to null
      let currentURL;
      if (state.period) {
        if (endPoints[0]?.includes('&_page_token')) {
          currentURL = endPoints[0]?.split('&_page_token')?.[0];
        } else {
          currentURL = endPoints[0];
        }
      } else {
        currentURL = null;
      }
      let currentRequest;
      //update previousURL to the currentURL for next render
      if (currentURL && endPoints[0].includes('&_page_token') && state.period) {
        // if(currentURL && (data.link.findIndex(obj=>obj.relation==="next") !== -1 || url.includes("&_page_token")) && state.period) {
        currentRequest = true;
      } else {
        currentRequest = false;
      }

      //update NextPage, NextPageURL and renderURL if the currentRquest is true and has multiple page results
      if (state.period !== null && data[0].link.findIndex((obj) => obj.relation === 'next') !== -1) {
        setNextPage(true);
        let index = data[0].link.findIndex((obj) => obj.relation === 'next');
        let splitURL = data[0].link[index].url.split('&_page_token=');
        setNextPageURL(splitURL[1]);
        setRenderURL(true);
      } else {
        //reset NextPage, NextPageURL and renderURL back to false and null if period filter was not selected or do not have multiple page results
        setNextPage(false);
        setNextPageURL(null);
        setRenderURL(false);
      }
      let tasksItems = [];
      data &&
        data.forEach((item) => {
          item.entry && tasksItems.push(...item.entry);
        });
      let newFetch = tasksItems
        ? tasksItems.map((d) => {
          return d.resource;
        })
        : null;
      let referralFilter = [];
      if (newFetch?.length > 0) {
        let temp: any = newFetch;
        //if the date filter is a range, then we will need to set the upper limit
        if (state.period && state.period.includes('ge') && state.period.includes('le')) {
          temp = temp.filter((t) => {
            return (
              moment(t.executionPeriod?.end ?? t.executionPeriod?.start).format('YYYY-MM-DD') <=
              moment(state.period.substring(25, 40)).format('YYYY-MM-DD') &&
              moment(t.executionPeriod?.end ?? t.executionPeriod?.start).format('YYYY-MM-DD') >=
              moment(state.period.substring(10, 20)).format('YYYY-MM-DD')
            );
          });
        } else if (state.period && state.period.includes('ge')) {
          temp = temp.filter((t) => {
            return (
              moment(t.executionPeriod?.end ?? t.executionPeriod?.start).format('YYYY-MM-DD') <=
              moment(state.period.substring(30, 40)).format('YYYY-MM-DD') &&
              moment(t.executionPeriod?.end ?? t.executionPeriod?.start).format('YYYY-MM-DD') >=
              moment(state.period.substring(10, 20)).format('YYYY-MM-DD')
            );
          });
        } else if (state.period && state.period.includes('gt')) {
          temp = temp.filter((t) => {
            return (
              moment(t.executionPeriod?.end ?? t.executionPeriod?.start).format('YYYY-MM-DD') >
              moment(state.period.substring(10, 20)).format('YYYY-MM-DD')
            );
          });
        } else if (state.period && state.period.includes('lt')) {
          temp = temp.filter((t) => {
            return (
              moment(t.executionPeriod?.end ?? t.executionPeriod?.start).format('YYYY-MM-DD') <
              moment(state.period.substring(10, 20)).format('YYYY-MM-DD')
            );
          });
        } else if (state.period && state.period.includes('eq')) {
          temp = temp.filter((t) => {
            return (
              moment(t.executionPeriod?.end ?? t.executionPeriod?.start).format('YYYY-MM-DD') ===
              moment(state.period.substring(10, 20)).format('YYYY-MM-DD')
            );
          });
        }
        if (state.status) {
          temp = temp.filter((t) => {
            return t.status === state.status;
          });
        }
        if (state.priority) {
          temp = temp.filter((t) => {
            if (!t.priority && state.priority === 'routine') {
              return true;
            }
            return t.priority === state.priority;
          });
        }
        if (state.referral) {
          temp = temp.filter((t) => {
            return (
              t.groupIdentifier &&
              t.groupIdentifier.system === 'https://projectwell.io/fhir/identifiers/referral-id' &&
              t.groupIdentifier.value === state.referral
            );
          });
        }

        temp.sort((a, b) => {
          let aDate: any = new Date(
            moment(a.executionPeriod?.end ?? a.executionPeriod?.start)
              .utc()
              .format()
          ).toDateString();
          let bDate: any = new Date(
            moment(b.executionPeriod?.end ?? b.executionPeriod?.start)
              .utc()
              .format()
          ).toDateString();
          return Date.parse(aDate) - Date.parse(bDate);
        });
        //if the temp.length>0, then we want to update the taskList

        if (temp.length > 0) {
          //if the previousURL is not the same as currentURL, then it's the first time we are fetching this request and we will have to set the taskList to temp
          if (!currentRequest) {
            setOriginalTaskList(temp);
            if (state.subCategory) {
              temp = temp.filter((t) => {
                return t.reasonCode && t.reasonCode?.text === state.subCategory;
              });
            }
            setTaskList(temp);
            setLoader(false);
          } else {
            //if the url is from current request, then we will need to combine the temp data with exsiting taskList data and filter out if there are duplicates
            if (taskList && state.period && currentRequest === true) {
              let newArray = taskList.concat(temp);
              let filteredArray = newArray.filter(onlyUnique);
              setOriginalTaskList(filteredArray);
              if (state.subCategory) {
                filteredArray = filteredArray.filter((t) => {
                  return t.reasonCode && t.reasonCode?.text === state.subCategory;
                });
              }
              setTaskList(filteredArray);
            } else {
              //replace temp data if state.period is null since we are only looping multiple pages data when state.period is updated
              setOriginalTaskList(temp);
              if (state.subCategory) {
                temp = temp.filter((t) => {
                  return t.reasonCode && t.reasonCode?.text === state.subCategory;
                });
              }
              setTaskList(temp);
            }
          }
        } else {
          //when the temp.length =0, we don't have new data need to be added to the taskList
          //check if the existing taskList is from last request or from the current request
          //if they have the same URL, then we will do nothing since no data need to be added to the taskList.
          //if they do not have the same URL, then we will have to set the taskList to null
          if (!currentRequest) {
            setTaskList(null);
            setOriginalTaskList(null);
          } else {
            if (!taskList) {
              setTaskList(null);
              setOriginalTaskList(null);
            }
          }
          setLoader(false);
        }

        if (!currentTaskId) {
          setCurrentTaskId(temp && temp?.length > 0 ? temp[0]?.id : null);
        } else {
          setCurrentTaskId(
            temp &&
              temp.filter((d) => {
                return d.id === currentTaskId;
              }).length === 1
              ? currentTaskId
              : temp[0].id
          );
        }
        if (!state.referral) {
          temp = temp
            .filter((t) => {
              return t.groupIdentifier;
            })
            .forEach((t) => {
              if (
                t.groupIdentifier.system === 'https://projectwell.io/fhir/identifiers/referral-id' &&
                !referralFilter.includes(t.groupIdentifier.value) &&
                t.groupIdentifier.value
              ) {
                referralFilter.push(t.groupIdentifier.value);
              }
            });
          referralFilter.sort();
          setReferralIdList(referralFilter);
        }
      } else {
        setTaskList(null);
        setOriginalTaskList(null);
        setLoader(false);
      }
      if (newTaskId) {
        setCurrentTaskId(newTaskId);
        setNewTaskId(null);
      }
      setLoader(false);
    },
  };

  useImperativeHandle(ref, () => ({
    refreshTasks() {
      tasksMutate();
    },
    loadData() {
      setLoader(true);
    },
  }));

  /* const {
    data: patientData,
    error: patientError,
    isValidating: patientDataLoading,
  } = useSWR([`/Patient/?organization=${organizationId}`, token], fhirGet, options); */

  let endPoints = [];

  /* let uniquePatients = _.uniq(patientData?.entry?.map((entry) => (
    entry.resource?.id
  ))); */
  /* let patientChunks = sliceIntoChunks(uniquePatients, 200); */

  //patientChunks.forEach((patientIds) => {
    let url =
      `/Task/?_sort=period&_count=1000&code:not=fulfill` + // remove this later. code filter needed only for dev due to old tasks
      (props.activity && props.practitionerId ? `&owner=` + props.practitionerId : ``) +
      //(props.activity && patientIds?.length > 0 ? `&patient=` + patientIds.join(',') : ``) +
      ('&based-on=' + organizationId) +
      (state.practitioner ? `&owner=` + state.practitioner : ``) +
      (props.patientId ? `&patient=` + props.patientId : ``) +
      (state.status ? `&status=` + state.status : `&status:not=cancelled`) +
      (state.priority !== null
        ? state.priority === 'routine'
          ? `&priority:not=asap`
          : `&priority=${state.priority}`
        : '') +
      (state.category
        ? `&code=` + state.category
        : `&code=touchpoint,careplan,automated`) + //hide automated and member tasks by default
      (state.period ? (state.period.includes('ge') ? state.period.substring(0) : state.period) : ``) +
      (state.referral ? `&group-identifier=` + state.referral : ``) +
      (nextPage && nextPageURL ? `&_page_token=` + nextPageURL : ``);
    endPoints.push(url);
  //});

  const { mutate: tasksMutate, isValidating: tasksLoading } = useSWR(
    [token, ...endPoints],
    multipleFhirGet,
    taskOptions
  );

  let flatPractitioners = [];
  if (fhirPractitioners) {
    flatPractitioners =
      fhirPractitioners &&
      fhirPractitioners.map((fhirPractitioner) => {
        return FHIRUtils.parseFHIRPractitioner(fhirPractitioner.resource);
      });
  }

  const handleTaskButton = (e: any, taskButtonProps) => {
    setCurrentTaskId(taskButtonProps.taskid);
  };

  const resetFilters = () => {
    setDateValue(null);
    setState({
      category: null,
      period: null,
      status: null,
      priority: null,
      practitioner: null,
      vendor: null,
      referral: null,
      subCategory: null,
      dateStatus: null,
    });
    setOriginalTaskList(null);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleNewTask = (newTaskId) => {
    toggleSidebar();
    setNewTaskId(newTaskId);
    tasksMutate();
  };
  const practlist =
    flatPractitioners &&
    flatPractitioners
      .filter((flatPractitioner) => {
        return flatPractitioner.type === 'CC';
      })
      .map((flatPractitioner) => {
        return { key: flatPractitioner.id, text: flatPractitioner.fullName, value: flatPractitioner.id.toString() };
      });

  return (
    <>
      <Segment>
        {(/* patientDataLoading || */ tasksLoading || loader) && (
          <Loader active inline="centered" content="Fetching Tasks" />
        )}
        {/* {patientError && (
          <Message negative>
            <Message.Header>{"Error with patient data fetch!"}</Message.Header>
            <p>{patientError.message}</p>
          </Message>
        )} */}
        {!taskList && !loader && <h2>No Tasks</h2>}
        {!props.activity ? (
          <>
            <TaskDetail
              tasksMutate={tasksMutate}
              taskId={currentTaskId}
              practlist={practlist}
              taskList={taskList}
              flatPractitioners={flatPractitioners}
              onPatientSelected={props.onPatientSelected}
              activity={activityBool}
              patientId={props.patientId}
              orgId={props.orgId}
              render={render}
              setRenderURL={setRenderURL}
            />
            <Grid columns={2} divided>
              <Grid.Column width={7} style={{ maxHeight: '80vh', minHeight: '70vh', overflow: 'auto' }}>
                <Segment>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>
                      <Icon name="tasks" size="large" color="blue" /> TASKS ({taskList ? taskList.length : '0'})
                    </h4>
                    <TaskFilters
                      patientId={props.patientId}
                      practfilter={props.practfilter}
                      setLoader={setLoader}
                      practlist={practlist}
                      state={state}
                      setState={setState}
                      datevalue={datevalue}
                      setDateValue={setDateValue}
                      resetFilters={resetFilters}
                      referralIdList={referralIdList}
                      activity={props.activity}
                      tasksMutate={tasksMutate}
                      setRenderURL={setRenderURL}
                    />
                    <AddTask
                      open={open}
                      setOpen={setOpen}
                      patientId={props.patientId}
                      orgCode={props.orgId}
                      refresh={tasksMutate}
                      flatPractitioners={flatPractitioners}
                      setCurrentTaskId={setCurrentTaskId}
                      handleNewTask={handleNewTask}
                      activity={activityBool}
                      toggleSidebar={toggleSidebar}
                      setRenderURL={setRenderURL}
                    />
                    <AssignTask
                      taskList={taskList}
                      tasksMutate={tasksMutate}
                      currentTaskId={currentTaskId}
                      flatPractitioners={flatPractitioners}
                      activity={activityBool}
                      selectedTasks={selectedTasks}
                      setSelectedTasks={setSelectedTasks}
                      practlist={practlist}
                      orgCode={props.orgId}
                      patientId={props.patientId}
                      setRender={setRender}
                      render={render}
                      disableMultiSelect={disableMultiSelect}
                    />
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column width={9} stretched>
                <Segment size="small">
                  {taskList && !loader && (
                    <NewTaskList
                      taskList={taskList}
                      resetFilters={resetFilters}
                      handleTaskButton={handleTaskButton}
                      currentTaskId={currentTaskId}
                      flatPractitioners={flatPractitioners}
                      toggleSidebar={toggleSidebar}
                      activity={activityBool}
                      selectedTasks={selectedTasks}
                      orgId={props.orgId}
                      setSelectedTasks={setSelectedTasks}
                      setDisableMultiSelect={setDisableMultiSelect}
                    />
                  )}
                </Segment>
              </Grid.Column>
            </Grid>
          </>
        ) : (
          <>
            <TaskFilters
              patientId={props.patientId}
              practfilter={props.practfilter}
              setLoader={setLoader}
              practlist={practlist}
              state={state}
              setState={setState}
              datevalue={datevalue}
              setDateValue={setDateValue}
              resetFilters={resetFilters}
              referralIdList={referralIdList}
              activity={props.activity}
              tasksMutate={tasksMutate}
              setRenderURL={setRenderURL}
            />
            <Grid columns={2} divided>
              <Grid.Column width={7} style={{ maxHeight: '80vh', minHeight: '70vh', overflow: 'auto' }}>
                <Segment>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>
                      <Icon name="tasks" size="large" color="blue" /> TASKS ({taskList ? taskList.length : '0'})
                    </h4>
                    <AssignTask
                      taskList={taskList}
                      tasksMutate={tasksMutate}
                      currentTaskId={currentTaskId}
                      flatPractitioners={flatPractitioners}
                      activity={activityBool}
                      selectedTasks={selectedTasks}
                      setSelectedTasks={setSelectedTasks}
                      practlist={practlist}
                      orgCode={props.orgId}
                      patientId={props.patientId}
                      setRender={setRender}
                      render={render}
                      disableMultiSelect={disableMultiSelect}
                    />
                  </div>
                  <Segment>
                    {taskList && !loader && (
                      <NewTaskList
                        taskList={taskList}
                        resetFilters={resetFilters}
                        handleTaskButton={handleTaskButton}
                        currentTaskId={currentTaskId}
                        flatPractitioners={flatPractitioners}
                        toggleSidebar={toggleSidebar}
                        activity={activityBool}
                        selectedTasks={selectedTasks}
                        orgId={props.orgId}
                        setSelectedTasks={setSelectedTasks}
                        setDisableMultiSelect={setDisableMultiSelect}
                      />
                    )}
                    {(tasksLoading || loader) && (
                      <Loader active inline="centered" content="Fetching Tasks" />
                    )}
                    {!taskList && !loader && <h2>No Tasks</h2>}
                  </Segment>
                </Segment>
              </Grid.Column>
              <Grid.Column width={9} stretched>
                <Segment size="small">
                  <TaskDetail
                    tasksMutate={tasksMutate}
                    taskId={currentTaskId}
                    practlist={practlist}
                    taskList={taskList}
                    flatPractitioners={flatPractitioners}
                    onPatientSelected={props.onPatientSelected}
                    activity={activityBool}
                    patientId={props.patientId}
                    orgId={props.orgId}
                    render={render}
                    setRenderURL={setRenderURL}
                  />
                </Segment>
              </Grid.Column>
            </Grid>
          </>
        )}
      </Segment>
    </>
  );
});

export default TaskCard;
