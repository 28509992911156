import { Loader, Message } from "semantic-ui-react";
import moment from "moment";
import useSWR from "swr";

import { useAuth } from "hooks";
import { fhirGet } from "services";

import QuestionnaireResponseCard from "./QuestionnaireResponseCard";
import { DEFAULT_ERROR_MESSAGE, defaultSWROptions } from "utils";

type QuestionnairesProps = {
  fhirPatient: any;
  patientId: string;
  patientFirstName: string;
  patientMobilePhone: string;
  patientEmail: string;
  profileMutate: any;
  org: string;
  QRID: {
    id: string;
    isNewResponse: boolean;
  };
  refreshPatientDetails: any;
  tasksMutate: any;
};

const Questionnaires: React.FC<QuestionnairesProps> = ({
  fhirPatient,
  patientId,
  patientFirstName,
  patientMobilePhone,
  patientEmail,
  profileMutate,
  org,
  QRID,
  refreshPatientDetails,
  tasksMutate
}) => {
  const { token } = useAuth();

  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onSuccess: (data) => {
      if (data?.entry?.length > 0) {
        // Sort questionnaire response data so that newest will be on the top
        data?.entry?.sort(function (a, b) {
          return moment(b?.resource?.authored)?.valueOf() - moment(a?.resource?.authored)?.valueOf();
        });
      }
    }
  };

  const { mutate: consentDataMutate } = useSWR(
    [`/Observation/?patient=${fhirPatient.id}&_sort=-date&code=CONSENT`, token],
    fhirGet,
    defaultSWROptions
  );

  const { data: foodSurveyData } = useSWR(
    [`/Questionnaire/?identifier=https://projectwell.io/fhir/identifiers/patient-id|${patientId}`, token],
    fhirGet,
    defaultSWROptions
  );

  // Fetch questionnaire responses
  const {
    data: questionnaireResponseData,
    error: questionnaireResponseError,
    mutate: questionnaireResponseMutate
  } = useSWR(
    [`/QuestionnaireResponse/?patient=${patientId}&_count=150&status:not=entered-in-error`, token],
    fhirGet,
    options
  );

  const { data: questionnaireData } = useSWR(
    [`/Questionnaire/?context=${org}&context:not=FOOD_RATING&_sort=title`, token],
    fhirGet,
    defaultSWROptions
  );

  return (
    <div style={{ minHeight: "500px" }}>
      {!questionnaireData && <Loader active inline="centered" content="Loading Survey Data..." />}
      {!questionnaireResponseData && !questionnaireResponseError && (
        <Loader active inline="centered" content="Loading Survey Response Data..." />
      )}
      {questionnaireData && questionnaireResponseData && questionnaireData.entry && (
        <QuestionnaireResponseCard
          consentDataMutate={consentDataMutate}
          fhirPatient={fhirPatient}
          org={org}
          patientEmail={patientEmail}
          patientFirstName={patientFirstName}
          patientId={patientId}
          patientPhoneNumber={patientMobilePhone}
          profileMutate={profileMutate}
          questionnaireData={
            foodSurveyData && foodSurveyData.entry
              ? {
                  entry: questionnaireData.entry.concat(foodSurveyData.entry)
                }
              : questionnaireData
          }
          QRID={QRID}
          questionnaireResponseData={questionnaireResponseData}
          questionnaireResponseMutate={questionnaireResponseMutate}
          refreshPatientDetails={refreshPatientDetails}
          tasksMutate={tasksMutate}
        />
      )}

      {questionnaireResponseError && (
        <Message negative>
          <Message.Header>{DEFAULT_ERROR_MESSAGE}</Message.Header>
          <p>{questionnaireResponseError.message}</p>
        </Message>
      )}
    </div>
  );
};

export default Questionnaires;
