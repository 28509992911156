import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { Button, Form, FormField, Grid, Header, Icon, Message, Modal, Popup } from "semantic-ui-react";
import QuestionnaireItem from "./item";
import { QuestionnaireExtensionHelper, QuestionnaireHelper, QuestionnaireResponseHelper } from "utils/Helpers";
import * as ObservationBuilder from "../../../fhir-sdoh/common/fhir-builders/observation";

import { useAuth } from "hooks";
import { fhirPut } from "services";
import { Provenance } from "fhir/r4";
import { ProvenanceBuilder } from "fhir-sdoh";

function Questionnaire(props: any) {
  const { personName, personId, token } = useAuth();
  //props: questionnaire, patientId, toggleSurveySidebar, refreshQA, profileMutate, currentQuestionnaireResponse, org
  let currentQRHelper = new QuestionnaireResponseHelper(props.currentQuestionnaireResponse);
  let QHelper = new QuestionnaireHelper(props.questionnaire);
  let QExtHelper = new QuestionnaireExtensionHelper(QHelper.getSurveyExtension());
  const [answersState, setAnswersState] = useState<any[]>();
  const [enableState, setEnableState] = useState<any[]>();
  const [rerender, setRerender] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState([]);
  const [list, setList] = useState<string[]>([]);
  const [QRLoader, setQRLoader] = useState(false);
  const [obsLoader, setObsLoader] = useState(false);
  const [qrId, setQrId] = useState(currentQRHelper.getId());
  const [requiredState, setRequiredState] = useState<any[]>(); //to track if each question is required
  const [requiredQuestionBorder, setRequiredQuestionBorder] = useState<any[]>(); //use to highlight the required question that doesn't have answer

  currentQRHelper = new QuestionnaireResponseHelper(props.currentQuestionnaireResponse);

  useEffect(() => {
    let answers = [];
    let enable = [];
    let required = [];
    let requiredBorder = [];
    for (let i = 0; i < props.questionnaire.item.length; i++) {
      answers[i] = [];
      enable[i] = [];
      required[i] = [];
      requiredBorder[i] = [];

      for (let j = 0; j < props.questionnaire.item[i].item.length; j++) {
        answers[i][j] = null;
        let enableResult = props.questionnaire.item[i].item[j]?.enableWhen ? false : true;
        //setting attachment type question as not required for now until we have ability to submit pii survey in CB
        if (props.questionnaire.item[i].item[j]?.type === "attachment") {
          enable[i][j] = false;
          required[i][j] = false;
          requiredBorder[i][j] = false;
        } else if (disabledQuestionLinkId.includes(props.questionnaire.item[i].item[j]?.linkId)) {
          //disable the consent and upload document question from CB survey
          enable[i][j] = false;
          required[i][j] = false;
          requiredBorder[i][j] = false;
        } else {
          enable[i][j] = enableResult;
          required[i][j] = props.questionnaire?.item?.[i]?.item?.[j]?.required && enableResult ? true : false;
          requiredBorder[i][j] =
            props.questionnaire?.item?.[i]?.item?.[j]?.required &&
            enableResult &&
            (!answers?.[i]?.[j] || answers?.[i]?.[j]?.length === 0)
              ? true
              : false; //we will show the border around the questions that are enabled, required, and have no answer or empty array
        }
        if (
          props.questionnaire.item[i].item[j].enableWhen &&
          props.questionnaire.item[i].item[j].enableWhen?.find(
            (enableQuestion) => enableQuestion.question === "PW-PRACTITIONER"
          )
        ) {
          enable[i][j] = false;
        }
      }
    }
    if (!currentQRHelper.isEmpty()) {
      for (let i = 0; i < currentQRHelper.getItemListLength(); i++) {
        for (let j = 0; j < currentQRHelper.getGroupLength(i); j++) {
          for (let x = 0; x < props.questionnaire.item.length; x++) {
            let bool = false;
            for (let y = 0; y < props.questionnaire.item[x].item.length; y++) {
              if (currentQRHelper.getAnswerLinkId(i, j) === props.questionnaire.item[x].item[y].linkId) {
                answers[x][y] = currentQRHelper.getAnswerResponse(i, j);
                x = i;
                y = j;
                bool = true;
                break;
              }
            }
            if (bool) break;
          }
        }
      }
    }
    setAnswersState(answers);
    setEnableState(enable);
    setRequiredState(required);
    setRequiredQuestionBorder(requiredBorder);
    setQrId(currentQRHelper.getId());
    setSubmit(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentQuestionnaireResponse, props.questionnaire.item, props.questionnaire]);

  useEffect(() => {
    if (answersState && enableState) {
      currentQRHelper.getQResponse() && updateEnable();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersState]);

  //survey warning
  useEffect(() => {
    document.addEventListener("click", handleClick);
    // cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
  const isAnswerNull = () => {
    let hasAnswers = true; //assume null answers initially
    if (answersState?.length > 0) {
      for (let i = 0; i < answersState.length; i++) {
        for (let j = 0; j < answersState[i]?.length; j++) {
          if (!hasAnswers) {
            break;
          }
          if (answersState[i][j] !== null) {
            hasAnswers = false;
          }
        }
      }
    }
    return hasAnswers;
  };
  const handleClick = (event) => {
    const target = event.target;
    if (target.tagName === "A" && !submit && !isAnswerNull()) {
      event.preventDefault();
      setShowModal(true);
    }
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  const getEnable = (questionId: string, answerCode: string, operator: string) => {
    //To enable practitioner true questions in CB: practitioner enableWhen = true is true, != true is false, = false is false, != false is true
    if (questionId === "PW-PRACTITIONER") {
      return false;
    }
    if (disabledQuestionLinkId.includes(questionId)) {
      //disable the consent question from CB survey
      return false;
    }
    for (let i = 0; i < props.questionnaire.item.length; i++) {
      for (let j = 0; j < props.questionnaire.item[i].item.length; j++) {
        if (props.questionnaire.item[i].item[j].linkId === questionId) {
          let bool = operator === "=" ? false : true;
          if (answersState[i][j]) {
            for (let k = 0; k < answersState[i][j].length; k++) {
              if (answersState[i][j][k].valueCoding) {
                if (answersState[i][j][k].valueCoding.code === answerCode) {
                  return !bool;
                }
              }
            }
          }
          return bool;
        }
      }
    }
    return false;
  };

  const updateEnable = () => {
    for (let i = 0; i < props.questionnaire.item.length; i++) {
      let checkQuestionEnable = true;
      if (props.questionnaire.item[i].enableWhen) {
        let enabled = props.questionnaire.item[i].enableBehavior === "all" ? true : false;
        for (let k = 0; k < props.questionnaire.item[i].enableWhen.length; k++) {
          let question = props.questionnaire.item[i].enableWhen[k].question;
          let answerCode = props.questionnaire.item[i].enableWhen[k].answerCoding.code;
          let operator = props.questionnaire.item[i].enableWhen[k].operator;
          if (getEnable(question, answerCode, operator) !== enabled) {
            enabled = getEnable(question, answerCode, operator);
            break;
          }
        }
        let answers = answersState;
        if (!enabled) {
          for (let a = 0; a < answers[i].length; a++) {
            answers[i][a] = null;
          }
          setAnswersState(answers);
          checkQuestionEnable = false;
        }
        let enable = enableState;
        let required = requiredState;
        let requiredBorder = requiredQuestionBorder;
        for (let a = 0; a < enable[i].length; a++) {
          // mark attachment type question as not required for now until we need to submit pii surveys in CB
          if (props.questionnaire.item[i].item[a]?.type === "attachment") {
            enable[i][a] = false;
            required[i][a] = false;
            requiredBorder[i][a] = false;
          } else if (disabledQuestionLinkId.includes(props.questionnaire.item[i].item[a]?.linkId)) {
            //disable the consent question from CB survey
            enable[i][a] = false;
            required[i][a] = false;
            requiredBorder[i][a] = false;
          } else {
            enable[i][a] = enabled;
            required[i][a] = props.questionnaire.item[i]?.item[a]?.required && enabled ? true : false; //if enabled is true and has required, then we need to set required.
            requiredBorder[i][a] =
              props.questionnaire.item[i]?.item[a]?.required &&
              enabled &&
              (!answers?.[i]?.[a] || answers?.[i]?.[a]?.length === 0)
                ? true
                : false; //we will show the border around the questions that are enabled, required, and have no answer or empty array
          }
        }
        setEnableState(enable);
        setRequiredState(required);
        setRequiredQuestionBorder(requiredBorder);
      }
      if (checkQuestionEnable) {
        for (let j = 0; j < props.questionnaire.item[i].item.length; j++) {
          if (props.questionnaire.item[i].item[j].enableWhen) {
            let enabled = props.questionnaire.item[i].item[j].enableBehavior === "all" ? true : false;
            for (let k = 0; k < props.questionnaire.item[i].item[j].enableWhen.length; k++) {
              let question = props.questionnaire.item[i].item[j].enableWhen[k].question;
              let answerCode = props.questionnaire.item[i].item[j].enableWhen[k].answerCoding.code;
              let operator = props.questionnaire.item[i].item[j].enableWhen[k].operator;
              if (getEnable(question, answerCode, operator) !== enabled) {
                enabled = getEnable(question, answerCode, operator);
                break;
              }
            }
            let answers = answersState;
            if (!enabled) {
              answers[i][j] = null;
              setAnswersState(answers);
            }
            let enable = enableState;
            let required = requiredState;
            let requiredBorder = requiredQuestionBorder;
            // mark attachment type question as not required for now until we need to submit pii surveys in CB
            if (props.questionnaire.item[i].item[j]?.type === "attachment") {
              enable[i][j] = false;
              required[i][j] = false;
              requiredBorder[i][j] = false;
            } else if (disabledQuestionLinkId.includes(props.questionnaire.item[i].item[j]?.linkId)) {
              //disable the consent question from CB survey
              enable[i][j] = false;
              required[i][j] = false;
              requiredBorder[i][j] = false;
            } else {
              enable[i][j] = enabled;
              required[i][j] = props.questionnaire.item[i]?.item?.[j]?.required && enabled ? true : false; //if enabled is true and has required, then we need to set required.
              requiredBorder[i][j] =
                props.questionnaire.item[i]?.item?.[j]?.required &&
                enabled &&
                (!answers?.[i]?.[j] || answers?.[i]?.[j]?.length === 0)
                  ? true
                  : false; //we will show the border around the questions that are enabled, required, and have no answer or empty array
            }
            setEnableState(enable);
            setRequiredState(required);
            setRequiredQuestionBorder(requiredBorder);
          }
        }
      }
      setRerender(!rerender);
    }
  };

  const addAnswer = (answer: any, i: number, j: number) => {
    let arr = answersState;
    let requiredBorder = requiredQuestionBorder;
    arr[i][j] = answer;
    //we will show the border around the questions that are required and have no answer or empty array
    if ((answer === null || answer?.length === 0) && requiredState[i][j]) {
      requiredBorder[i][j] = true;
    } else {
      requiredBorder[i][j] = false;
    }
    setAnswersState(arr);
    setRequiredQuestionBorder(requiredBorder);
    updateEnable();
  };

  //Create provinance
  const saveQuestionnaireResponse = (QRId, piiSurvey: boolean) => {
    let provenance: Provenance;
    //if PII survey is false, we still use old way to save provenance
    //but if PII survey is true, the questionnaire response Id will not be found in pw fhirstore and will cause error
    //we will remove it and have the QRID in the reason
    if (!piiSurvey) {
      provenance = ProvenanceBuilder.build(
        `QuestionnaireResponse/${QRId}`,
        {
          reference: "Practitioner/" + personId,
          display: personName
        }, //eg. {Practitioner/oa2,johnsmith},
        props.patientId,
        qrId ? "UPDATE" : "CREATE",
        `A questionnaire response for ${props.questionnaire.title} has been ${qrId ? "updated" : "created"}`,
        { code: props?.org?.toUpperCase(), display: props?.org?.toLowerCase() } //eg. {Organization/HUMANA,humana}
      );
    } else {
      provenance = ProvenanceBuilder.build(
        `Patient/${props.patientId}`,
        {
          reference: "Practitioner/" + personId,
          display: personName
        }, //eg. {Practitioner/oa2,johnsmith},
        props.patientId,
        qrId ? "UPDATE" : "CREATE",
        `A questionnaire response for ${props.questionnaire.title} has been ${qrId ? "updated" : "created"}`,
        { code: props?.org?.toUpperCase(), display: props?.org?.toLowerCase() }, //eg. {Organization/HUMANA,humana}
        QRId
      );
    }

    fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
      (response) => {},

      (response) => {
        console.log(response);
        setQRLoader(false);
      }
    );
  };

  const handleSubmit = async () => {
    setList([]);
    setSubmit(true);
    setError([]);
    let questionnaireResponseId = qrId ? qrId : uuid();
    if (!qrId) setQrId(questionnaireResponseId);
    var QRExtensionHelper = new QuestionnaireExtensionHelper(currentQRHelper.getExtension());
    let piiSurvey: boolean = checkPIISurvey(props.questionnaire);
    let questionnaireAnswers: fhir4.QuestionnaireResponse = {
      id: questionnaireResponseId,
      resourceType: "QuestionnaireResponse",
      questionnaire: `Questionnaire/${props.questionnaire.id}`,
      identifier: {
        system: "https://projectwell.io/fhir/identifiers/last-modified-by",
        value: `${personName}`
      },
      status: "completed",
      authored: new Date().toISOString(),
      subject: {
        reference: `Patient/${props.patientId}`
      },
      extension: [
        {
          url: "https://projectwell.io/fhir/orgid",
          valueString: piiSurvey ? props?.org : "pw" //pass payor fhir store if it's PII survey
        },
        {
          url: "https://projectwell.io/fhir/extensions/completed-date",
          //Old responses do not have completed date. In those cases, use the authored field
          valueDateTime: currentQRHelper.getExtension()
            ? QRExtensionHelper.getCompletedDate() //if the completed date is available, use it, otherwise get authoredDate
              ? QRExtensionHelper.getCompletedDate()
              : currentQRHelper.getAuthoredDate() && currentQRHelper.getStatus() === "completed"
                ? //if no authored field found, create new completed date
                  currentQRHelper.getAuthoredDate()
                : new Date().toISOString()
            : currentQRHelper.getAuthoredDate() && currentQRHelper.getStatus() === "completed"
              ? //if no authored field found, create new completed date
                currentQRHelper.getAuthoredDate()
              : new Date().toISOString()
        },
        {
          url: "https://projectwell.io/fhir/extensions/updated-date",
          valueDateTime: new Date().toISOString()
        }
      ],
      item: []
    };

    if (currentQRHelper.getQResponse() && qrId) {
      //questionnaireAnswers["id"] = props.currentQuestionnaireResponse.id;
      questionnaireAnswers["authored"] = currentQRHelper.getAuthoredDate();
    }

    let observation; //temp, for phone only observation

    let validated = true;
    for (let i = 0; i < props.questionnaire.item.length; i++) {
      let item: any = [];
      for (let j = 0; j < props.questionnaire.item[i].item.length; j++) {
        if (requiredState[i][j] && !answersState[i][j]?.[0]) {
          setSubmit(false);
          setError((e) => ["One or more required (*) questions are missing answers, please answer before submitting!"]);
          validated = false;
          break;
        }

        //if communication selected only has phone, then we want to show phone only as observation
        if (
          props.questionnaire.item[i].item[j].linkId === 'PW-FOOD-DLVRY-07' &&
          props.questionnaire.item[i].item[j].answer?.length === 1 &&
          props.questionnaire.item[i].item[j].answer?.[0]?.valueCoding?.code === 'PW-PHONE'
        ) {
          //create observation for phone only
          observation = ObservationBuilder.buildPreferenceFromQuestionnaireResponse(
            props.patientId,
            new Date().toISOString(),
            { code: "COMMUNICATION-PREFERENCES", display: "Communication Preferences" },
            "Phone Only",
            personName,
            props.questionnaire.id,
            props.questionnaire.title,
            questionnaireAnswers.id,
            false
          );
        }

        item[j] = {
          answer: [],
          linkId: props.questionnaire.item[i].item[j].linkId
        };

        if (answersState[i]) {
          if (answersState[i][j]) {
            item[j].answer = answersState[i][j];
          }
        }
      }

      questionnaireAnswers.item[i] = {
        item: item,
        linkId: props.questionnaire.item[i].linkId
      };
    }
    if (validated) {
      setQRLoader(true);
      await fhirPut(`/QuestionnaireResponse/${questionnaireAnswers.id}`, token, questionnaireAnswers).then(
        async () => {
          setQRLoader(false);
          setList((l) => [...l, "Survey Response submitted"]);

          if (observation) {
            setQRLoader(true);
            await fhirPut(`/Observation/${observation.id}`, token, observation).then(
              () => {
                setQRLoader(false);
                setList((l) => [...l, "Phone-Only Communication Preference submitted"]);

                setSubmit(true);
                props.refreshQA && props.refreshQA(true);
                props.toggleSurveySidebar && props.toggleSurveySidebar(null);
                props.setCurrentQuestionnaire(null); //to change to QR view

                // Delaying intentionally to process all backend tasks
                setTimeout(() => {
                  props.profileMutate();
                  setObsLoader(false);
                }, 11000);
              },
              (error) => {
                setQRLoader(false);
                questionnaireAnswers && setError((e) => [...e, error.toString()]);
              }
            );
          } else {

            setSubmit(true);
            props.refreshQA && props.refreshQA(true);
            props.toggleSurveySidebar && props.toggleSurveySidebar(null);
            props.setCurrentQuestionnaire(null); //to change to QR view

            // Delaying intentionally to process all backend tasks
            setTimeout(() => {
              props.profileMutate();
              setObsLoader(false);
            }, 11000);
          }
        },
        (error) => {
          setQRLoader(false);
          questionnaireAnswers && setError((e) => [...e, error.toString()]);
        }
      );
      //build provenance
      saveQuestionnaireResponse(questionnaireResponseId, piiSurvey);
    }
  };

  const handleLabel = (q) => {
    let displayValue: string | number | undefined;
    if (q?.text) {
      let chosenLang = props.language;
      let extHelper = new QuestionnaireExtensionHelper(q.extension);
      if (extHelper.getTargetTranslation(chosenLang)) {
        displayValue = extHelper.getTargetTranslation(chosenLang);
      } else {
        displayValue = q?.text;
      }
    } else {
      displayValue = q?.linkId;
    }
    return displayValue;
  };

  const checkPIISurvey = (questionnaire) => {
    if (questionnaire?.extension?.find((ext) => ext.url === "https://projectwell.io/fhir/contains-pii")?.valueString) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal basic open={showModal} onClose={handleModalClose} closeOnDimmerClick={false} size="small">
        <Header icon>
          <Icon className="bullhorn" color="blue" />
          Save your progress!!
        </Header>
        <Modal.Content>
          <p>A survey is being filled!! Please double check it and Submit before you proceed.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" inverted onClick={() => setShowModal(false)}>
            <Icon name="checkmark" /> OK!
          </Button>
        </Modal.Actions>
      </Modal>
      {
        <Form success={submit} error={error.length > 0}>
          <Header as="h3">{QExtHelper.getInternalTitle() ?? props.questionnaire.title}</Header>

          {props.questionnaire.item.map((category: any, i: number) => (
            <Form.Group key={i.toString()} grouped>
              {/* <FormField
                inline
                className={enableState && category?.required ? (enableState?.[i] ? 'required' : null) : null}
              >
                <Icon color="blue" name="folder open outline" />
                {/* we will show the border around the questions that are enabled, required, and have no answer or empty
                array *~/}
                <label style={category?.required && requiredQuestionBorder?.[i] ? { border: '1px solid red' } : null}>
                  {handleLabel(category)}
                </label>
              </FormField> */}
              {category.item &&
                category.item.map((question: any, j: number) => (
                  <div key={i.toString() + j.toString()}>
                    <FormField
                      inline
                      className={enableState && question?.required ? (enableState?.[i]?.[j] ? "required" : null) : null}
                    >
                      {/*highlight the label when the question type is a display(prompt), the enable is true, and has text */}
                      {question?.type === "display" &&
                      question?.text &&
                      (enableState ? (enableState[i] ? enableState[i][j] : null) : null) ? (
                        <label /* style={{ border: '2px solid green' }} */>
                          {" "}
                          <Icon color="orange" name="file alternate outline" />
                          {handleLabel(question)}
                        </label>
                      ) : question?.type === "display" ||
                        !(enableState ? (enableState[i] ? enableState[i][j] : null) : null) ? (
                        //show red stop icon when the display/question is disabled
                        <>
                          <label>
                            <Icon color="red" name="ban" />
                            {handleLabel(question)}
                          </label>
                        </>
                      ) : (
                        <label>
                          {" "}
                          <Icon color="orange" name="file alternate outline" />
                          {handleLabel(question)}
                        </label>
                      )}
                    </FormField>

                    <QuestionnaireItem
                      question={question}
                      addAnswer={addAnswer}
                      i={i}
                      j={j}
                      currentAnswer={answersState ? (answersState[i] ? answersState[i][j] : null) : null}
                      enable={enableState ? (enableState[i] ? enableState[i][j] : null) : null}
                      language={props.language}
                    />
                    <br />
                  </div>
                ))}
            </Form.Group>
          ))}
          {submit && <Message success header="Success" list={list} />}
          {(QRLoader || obsLoader) && (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Loading</Message.Header>
                {QRLoader && "Submitting Survey Response..."}
                {obsLoader && `Submitting Profile Labels...`}
              </Message.Content>
            </Message>
          )}
          {error && <Message error header="Error" list={error} />}
          <Grid>
            <Grid.Row>
              {props.toggleSurveySidebar && <Grid.Column width={1} />}
              <Grid.Column width={4}>
                <Form.Button color="blue" content="Submit" onClick={handleSubmit} disabled={QRLoader || obsLoader} />
              </Grid.Column>
              {props.toggleSurveySidebar && <Grid.Column width={7} />}
              {props.toggleSurveySidebar && (
                <Grid.Column width={3}>
                  <Popup
                    content="Please submit before exiting to save the survey response!"
                    trigger={<Form.Button color="red" content="Exit" onClick={() => props.toggleSurveySidebar(null)} />}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Form>
      }
    </>
  );
}

export default Questionnaire;

//below has the disabled question linkId for CB, such as consent, upload document questions
const disabledQuestionLinkId = [
  "FOR_WEB_SURVEY_ONLY_CLICK_WRAP_CONSENT_AGREEMENT",
  "PW-COND-DIAB-12c",
  "PW-COND-DIAB-12c-pg3",
  "PW-COND-DIAB-12c-pg4",
  "PW-COND-DIAB-12c-pg5",
  "PW-FOOD-DLVRY-14a",
  "PW-FOOD-DLVRY-14b",
  "PW-FOOD-DLVRY-15a",
  "PW-FOOD-DLVRY-15b",
  "PW-FOOD-DLVRY-15c",
  "PW-FOOD-DLVRY-15d",
  "PW-FOOD-DLVRY-15e",
  "PW-PERSONAL-01",
  "PW-PERSONAL-02",
  "PW-PERSONAL-03",
  "PW-PERSONAL-04",
  "DELIVERY_ADDRESS_INTRO"
];
