import { useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import { useAuth } from "hooks";
import { Provenance } from 'fhir/r4';
import { FHIRUtils, ProvenanceBuilder } from '../../../../fhir-sdoh';
import moment from 'moment';
import { fhirPut } from "services";
import { hooksPatientStatus } from 'utils/care-hooks';

export function CancelTask(props: any) {
  const { token, personId, personName } = useAuth();
  let taskpayload = props.taskpayload;
  let [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const askConfirmation = (e) => {
    setConfirmOpen(true);
  };
  const deleteTask = () => {
    props.setLoader(true);
    taskpayload.status = 'cancelled';
    if (taskpayload?.reasonCode?.text === 'intervention' && taskpayload.status === 'cancelled') {
      taskpayload.executionPeriod.end = moment().format('YYYY-MM-DD');
    }
    taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);
    if (taskpayload?.reasonCode?.text === 'intervention' && taskpayload.status === 'cancelled') {
      let patientId = taskpayload.for.reference.split('/')[1];
      // Update all 'requested', 'in-progress',and 'on-hold' tasks to 'cancelled' when intervention task is 'cancelled'
      let updateTasksNPatientStatusPayload = {
        patientId: patientId,
        status: 'INACTIVE',
        reason: 'DISENROLL',
      };

      hooksPatientStatus(updateTasksNPatientStatusPayload, token, personName).finally(() => {
        setTimeout(() => {
          props.tasksMutate();
        }, 3000);
      });
    }

    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${props.taskpayload.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      props.patientId,
      'UPDATE',
      `A ${taskpayload?.code?.coding?.[0]?.code} Task has been cancelled`,
      { code: props.patientOrg.toUpperCase(), display: props.patientOrg.toLowerCase() }
    );

    //now post bundle request
    fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      (response) => {
        fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
          (response) => {
            setTimeout(() => {
              setConfirmOpen(false);
              props.setLoader(false);
              props.tasksMutate();
            }, 3000);
          },
          (response) => {
            props.setLoader(false);
            props.setErrorMessage(response.toJSON()['message']);
          }
        );
      },
      (response) => {
        props.setLoader(false);
        props.setErrorMessage(response.toJSON()['message']);
      }
    );
  };

  return (
    <>
      <Button size="small" floated="right" content="CANCEL" onClick={askConfirmation} negative />
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deleteTask}
        content="Cancelling this Task will remove it from your list. Are you sure you want to cancel?"
        size="large"
        confirmButton={<Button content="Cancel Task" negative />}
        cancelButton={<Button content="Never Mind" />}
      />
    </>
  );
}
