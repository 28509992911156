import { useState, useEffect } from "react";
import { Checkbox, Input, Radio } from "semantic-ui-react";
import { QuestionnaireExtensionHelper } from "utils/Helpers";

export default function OpenChoice(props) {
  //props: question, addAnswer, i, j, currentAnswer, enable

  const [selectedAnswer, setSelectedAnswer] = useState([]);
  let extHelper = new QuestionnaireExtensionHelper(props.question?.extension);

  const [isOther, setIsOther] = useState(false);

  useEffect(() => {
    if (props.currentAnswer) {
      setSelectedAnswer(props.currentAnswer);
      if (props.currentAnswer[props.currentAnswer.length - 2]) {
        if (props.currentAnswer[props.currentAnswer.length - 2].valueCoding) {
          setIsOther(
            props.currentAnswer[props.currentAnswer.length - 2].valueCoding?.code === "OTHER" ||
              props.currentAnswer[props.currentAnswer.length - 2].valueCoding?.display === "Other"
          );
        } else {
          setIsOther(false);
        }
      } else {
        setIsOther(false);
      }
    } else {
      setSelectedAnswer([]);
      setIsOther(false);
    }
  }, [props]);

  const optionIndexInSelectedAnswer = (option) => {
    for (let i = 0; i < selectedAnswer.length; i++) {
      if (selectedAnswer[i].valueCoding && option.valueCoding) {
        if (selectedAnswer[i].valueCoding.code === option.valueCoding.code) {
          return i;
        }
      } else if (selectedAnswer[i].valueInteger !== undefined && option.valueInteger !== undefined) {
        if (selectedAnswer[i].valueInteger === option.valueInteger) {
          return i;
        }
      }
    }
    return -1;
  };

  const handleClick = (option) => {
    if (extHelper.isCheckbox()) {
      const index = optionIndexInSelectedAnswer(option);
      let arr = JSON.parse(JSON.stringify(selectedAnswer));
      if (index > -1) {
        if (option.valueCoding && (option.valueCoding?.code === "OTHER" || option.valueCoding?.display === "Other")) {
          arr.splice(index, 2);
          setIsOther(false);
        } else {
          arr.splice(index, 1);
        }
      } else {
        if (option.valueCoding && (option.valueCoding?.code === "OTHER" || option.valueCoding?.display === "Other")) {
          arr.push(option, { valueString: "" });
          setIsOther(true);
        } else {
          arr.push(option);
        }
      }
      arr.sort((a, b) => {
        if (a.valueCoding === undefined) {
          return 1;
        }
        if (b.valueCoding === undefined) {
          return -1;
        }
        return a.valueCoding.code - b.valueCoding.code;
      });
      setSelectedAnswer(arr);
      props.addAnswer(arr, props.i, props.j);
    } else {
      setSelectedAnswer([option]);
      props.addAnswer([option], props.i, props.j);
      setIsOther(false);
      if (option.valueCoding) {
        if (option.valueCoding?.code === "OTHER" || option.valueCoding?.display === "Other") {
          setSelectedAnswer([option, { valueString: "" }]);
          props.addAnswer([option, { valueString: "" }], props.i, props.j);
          setIsOther(true);
        }
      }
    }
  };

  const handleInput = (e) => {
    let arr = JSON.parse(JSON.stringify(selectedAnswer));
    if (isOther) {
      arr[arr.length - 1].valueString = e.target.value;
    } else {
      //if we already have valueString then we just need to update the data, otherwise, we will create a valueString object to store the data
      if (arr[arr.length - 1]?.valueString) {
        arr[arr.length - 1].valueString = e.target.value;
      } else {
        arr.push({ valueString: e.target.value });
      }
    }
    setSelectedAnswer(arr);
    props.addAnswer(arr, props.i, props.j);
  };

  const handleLabel = (option) => {
    let displayValue: string | number | undefined;
    let chosenLang = props.language;
    if (option?.valueCoding) {
      let extHelper = new QuestionnaireExtensionHelper(option?.valueCoding?.extension);
      if (extHelper.getTargetTranslation(chosenLang)) {
        displayValue = extHelper.getTargetTranslation(chosenLang);
      } else {
        displayValue = option.valueCoding.display;
      }
    } else {
      displayValue = option?.valueInteger;
    }
    return displayValue;
  };

  return (
    <div>
      {props.question.answerOption.map((option, i) => (
        <div key={i}>
          {((!extHelper.isEmpty() && extHelper.isCheckbox()) || extHelper.getItemControlCode() === undefined) && (
            <Checkbox
              disabled={!props.enable}
              label={handleLabel(option)}
              checked={optionIndexInSelectedAnswer(option) > -1}
              onChange={() => handleClick(option)}
            />
          )}
          {!extHelper.isEmpty() && extHelper.isRadioButton() && (
            <Radio
              disabled={!props.enable}
              label={handleLabel(option)}
              checked={optionIndexInSelectedAnswer(option) > -1}
              onChange={() => handleClick(option)}
            />
          )}
          {option.valueCoding &&
            (option.valueCoding?.code === "OTHER" || option.valueCoding?.display === "Other") &&
            (isOther ? (
              <>
                <Input
                  disabled={!props.enable}
                  fluid
                  focus
                  placeholder="Enter Answer Here"
                  value={selectedAnswer[selectedAnswer.length - 1].valueString}
                  onChange={handleInput}
                />
              </>
            ) : (
              <Input fluid focus placeholder="Enter Answer Here" value="" disabled />
            ))}
          {i === props.question.answerOption.length - 1 ? (
            <>
              {option.valueCoding?.code !== "OTHER" && option.valueCoding?.display !== "Other" && <br></br>}
              {`Other: `}
              {
                <Input
                  disabled={!props.enable}
                  fluid
                  focus
                  placeholder="Enter Answer Here"
                  value={selectedAnswer[selectedAnswer.length - 1]?.valueString}
                  onChange={handleInput}
                />
              }{" "}
            </>
          ) : (
            <br></br>
          )}
        </div>
      ))}
    </div>
  );
}
