import moment from 'moment';
import { Form, Select } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useEffect, useState } from 'react';

function TaskFilters(props: any) {
  let [disable, setDisable] = useState(false);
  //below is the tempState and will pass over to props once the filter request has been submitted.
  let [tempState, setTempState] = useState<any>({
    category: null,
    period: null,
    status: null,
    priority: null,
    practitioner: null,
    vendor: null,
    referral: null,
    subCategory: null,
    dateStatus: null,
  });
  let { category, status, practitioner, vendor, referral, subCategory, dateStatus, period, priority } = tempState;

  useEffect(() => {
    if (category || status || practitioner || vendor || referral || subCategory || dateStatus || period || priority) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempState]);

  let taskCategories = [
    'touchpoint',
    'careplan',
    'automated',
  ];
  let taskDueDateStatus = ['pastdue', 'today', 'future'];

  const handleDateChange = (e, selectprops) => {
    let name = selectprops.name;
    //clear the period back to null when click the clear due date buttom
    if (!selectprops.value) {
      setTempState((prevState) => ({ ...prevState, [name]: null }));
      return;
    }
    let startDay = selectprops?.value?.[0];
    let endDay = selectprops?.value?.[1];
    let query = '';
    let ge = moment(startDay).startOf('day').format('YYYY-MM-DD');
    if (endDay) {
      let le = moment(endDay ? endDay : startDay)
        .endOf('day')
        .format('YYYY-MM-DD');
      // query = `&period=ge` + ge + `&period=le` + le;
      query = `&period=ge` + ge + `:::le` + le;
    } else {
      query = `&period=eq` + ge;
    }
    setTempState((prevState) => ({ ...prevState, [name]: query }));
    props.setDateValue(selectprops.value);
  };

  const handleChange = (e, selectprops) => {
    e.preventDefault();
    let name = selectprops.name;
    let value = selectprops.value;
    if (selectprops.name === 'date_status') {
      let today = moment().local().format('YYYY-MM-DD');
      let updatedPeriod = '';
      //upate date range when "today/pastdue/future" filter is selected.
      if (value === 'today') {
        updatedPeriod = `&period=eq${today}`;
      } else if (value === 'pastdue') {
        updatedPeriod = `&period=lt${today}`;
      } else if (value === 'future') {
        updatedPeriod = `&period=gt${today}`;
      }
      // eslint-disable-next-line no-useless-computed-key
      setTempState((prevState) => ({ ...prevState, ['period']: updatedPeriod, ['dateStatus']: value }));
    } else {
      setTempState((prevState) => ({ ...prevState, [name]: value }));
    }
    //if category is not "careplan", we want to set subCategory back to null
    if (subCategory && name === 'category' && category.value !== 'careplan' && name !== 'subCategory') {
      setTempState((prevState) => ({ ...prevState, subCategory: null }));
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    props.setLoader(true);
    props.setState(tempState);
    props.tasksMutate();
    props.setRenderURL(true);
  };

  const resetTempFiltersFunc = () => {
    props.setDateValue(null);
    setTempState({
      category: null,
      period: null,
      status: null,
      priority: null,
      practitioner: null,
      vendor: null,
      referral: null,
      subCategory: null,
      dateStatus: null,
    });
  };

  return (
    <Form>
      <Form.Group
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Form.Field
          style={{ marginBottom: "10px" }}
          control={Select}
          selectOnBlur={false}
          name="category"
          placeholder="Filter by Category"
          value={category}
          options={taskCategories.map((k) => {
            return { key: k, value: k, text: k.toUpperCase() };
          })}
          onChange={handleChange}
        />
        {props.practfilter && (
          <Form.Field
            style={{ marginBottom: "10px" }}
            selectOnBlur={false}
            control={Select}
            name="practitioner"
            search
            placeholder="Filter by Owner"
            value={practitioner}
            options={props.practlist}
            onChange={handleChange}
          />
        )}

        {props.activity && (
          <>
            <Form.Field
              name="status"
              selectOnBlur={false}
              control={Select}
              placeholder="Filter by Status"
              value={status}
              options={['requested', 'in-progress', 'on-hold', 'completed', 'cancelled', 'failed', 'received'].map((k) => {
                return {
                  key: k,
                  value: k,
                  text: k === 'received' ? 'OUTREACH' : k.toUpperCase(),
                };
              })}
              onChange={handleChange}
            />
            <Form.Field
              name="priority"
              selectOnBlur={false}
              control={Select}
              placeholder="Filter by Priority"
              value={priority}
              options={['routine', 'asap'].map((k) => {
                let map = { routine: 'standard', asap: 'high priority' };
                return {
                  key: k,
                  value: k,
                  text: map[k].toUpperCase(),
                };
              })}
              onChange={handleChange}
            />
            <Form.Input
              control={Select}
              selectOnBlur={false}
              name="date_status"
              placeholder="Filter by Date Status"
              value={dateStatus}
              options={taskDueDateStatus.map((k) => {
                return { key: k, value: k, text: k.toUpperCase() };
              })}
              onChange={handleChange}
              autoComplete="off"
              className={period && !dateStatus ? 'disabled field' : ''}
            />
            <Form.Input
              autoComplete="off"
              value={props.datevalue}
              onChange={handleDateChange}
              name="period"
              placeholder="Filter by Due Date"
              control={SemanticDatepicker}
              format="YYYY-MM-DD"
              selectOnBlur={false}
              type="range"
              className={dateStatus ? 'disabled field' : ''}
            />
          </>
        )}

        <Form.Button
          style={{ marginBottom: "10px" }}
          type="button"
          content="Clear"
          color="blue"
          basic
          onClick={() => {
            props.resetFilters();
            resetTempFiltersFunc();
          }}
        />
        <Form.Button primary content="Submit" onClick={handleSubmit} disabled={disable} />
      </Form.Group>
    </Form>
  );
}

export default TaskFilters;
