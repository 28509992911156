import * as Helpers from '../../../../utils/Helpers';
import {
  Comment,
  Header,
  Form,
  TextArea,
  Button,
  Confirm,
  Message,
  Select,
  Icon,
  MessageHeader,
  MessageList,
} from 'semantic-ui-react';
import moment from 'moment';
import { useAuth } from "hooks";
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { FlagBuilder, ProvenanceBuilder } from 'fhir-sdoh';
import { Flag, Provenance } from 'fhir/r4';
import { fhirPut, foodCourtPost } from "services";

export function TaskNote(props: any) {
  let [note, setNote] = useState({ noteText: '', noteTag: [] });
  const { token, personId, personName } = useAuth();
  const [processFoodRecommendationContent, setProcessFoodRecommendationContent] = useState('');

  let practitionerName = personName;
  let taskpayload = props.taskpayload;
  let noteList = taskpayload.note
    ? _.sortBy(
      taskpayload.note.filter((note) => {
        return note.authorString;
      }),
      [
        function (note) {
          return note.time;
        },
      ]
    ).reverse()
    : []; // removed initial unnecessary note
  let [selectedPractitioners, setSelectedPractitioners] = useState([]);
  let [allPractitioners, setAllPractitioners] = useState([]);

  useEffect(() => {
    // var a: any = displayProcessFoodRecommendationMsg('');
    // setProcessFoodRecommendationContent(a);
    setProcessFoodRecommendationContent('');

    if (props.flatPractitioners && props.flatPractitioners) {
      let practitionersList = props.flatPractitioners.map((practitioner) => {
        let practitionerName = practitioner.fullName;
        let isMale = practitioner.gender.toLowerCase() === 'male' ? true : false;
        return {
          key: practitioner.id,
          text: practitionerName,
          value: practitioner.id,
          image: { avatar: true, src: '/images/' + Helpers.getImage(isMale) },
        };
      });
      setAllPractitioners(practitionersList);
    }
  }, [props.flatPractitioners]);

  const displayProcessFoodRecommendationMsg = (responseBody: any) => {
    let { recommendation, message, orderId } = JSON.parse(responseBody);

    if (orderId && orderId !== '') {
      return (
        <Message info>
          <MessageHeader>
            <Icon name="food"></Icon> Success, Food Recommendation Done
          </MessageHeader>
          <Message>
            {' '}
            {message} | Order created: {orderId}
          </Message>
        </Message>
      );
    } else if (recommendation.summaryText && recommendation.summaryText.length > 0)
      return (
        <Message negative>
          <MessageHeader>
            <Icon name="food"></Icon> Sorry, Unable to make food recommendation
          </MessageHeader>
          <MessageList items={recommendation.summaryText} />
        </Message>
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setLoader(true);
    let tagnotes = '';
    let noteAlerts = [];

    if (selectedPractitioners?.length > 0) {
      selectedPractitioners?.forEach((practitioner) => (tagnotes += ` #` + practitioner));
    }
    let noteObject = {
      authorString: practitionerName,
      text: selectedPractitioners.length !== 0 ? note.noteText + tagnotes : note.noteText,
      time: moment(),
    };
    //send alerts to tagged practitioners
    if (selectedPractitioners?.length > 0) {
      let flagpayload: Flag = FlagBuilder.build(
        props.patientId,
        personId,
        { code: 'admin', display: 'administrative' },
        { code: 'ALERT', display: 'Alert' },
        note.noteText + ' :Tagged you by ' + personName + ' at Task: ' + taskpayload.description,
        personName,
        props.orgId,
        note.noteTag.toString(), // assignedTo
        'active',
        taskpayload.id
      );
      noteAlerts.push(flagpayload);
    }
    taskpayload.note = [noteObject].concat(noteList);
    // update task with added note
    fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      () => {
        let provenance: Provenance = ProvenanceBuilder.build(
          `Task/${taskpayload.id}`,
          { reference: 'Practitioner/' + personId, display: practitionerName },
          props.patientId,
          'UPDATE',
          `A note (${taskpayload.note[0].text}) has been added to ${taskpayload?.code?.coding?.[0]?.code} task`,
          { code: props.orgId.toUpperCase(), display: props.orgId.toLowerCase() } //eg. {Organization/HUMANA,humana}
        );
        if (noteAlerts[0]) {
          fhirPut(`/Flag/${noteAlerts[0].id}`, token, noteAlerts[0]).then(
            () => {
              fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
                () => {
                  props.refresh();
                  setNote({ noteText: '', noteTag: [] });
                  setSelectedPractitioners([]);
                  setTimeout(() => {
                    props.setLoader(false);
                  }, 1000);
                },
                (error) => {
                  props.setLoader(false);
                  setValidationErrors((state) => [...state, 'Unable to create provenance: ' + error.toString()]);
                }
              );
            },
            (error) => {
              props.setLoader(false);
              setValidationErrors((state) => [...state, 'Unable to create flag: ' + error.toString()]);
            }
          );
        } else {
          fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
            () => {
              props.refresh();
              setNote({ noteText: '', noteTag: [] });
              setSelectedPractitioners([]);
              setTimeout(() => {
                props.setLoader(false);
              }, 1000);
            },
            (error) => {
              props.setLoader(false);
              setValidationErrors((state) => [...state, 'Unable to create provenance: ' + error.toString()]);
            }
          );
        }
      },
      (error) => {
        setValidationErrors((state) => [...state, 'Unable to add task note: ' + error.toString()]);
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNote((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const autoOrder = async () => {
    props.setLoader(true);
    let orderUpdate = {};
    await foodCourtPost(
      `/food-recommendation/auto-order?taskId=${props.taskpayload.id}`,
      token,
      orderUpdate
    ).then(
      (response) => {
        setTimeout(() => {
          props.setLoader(false);
        }, 1000);

        if (response.body) {
          var processedRecommendation: any = displayProcessFoodRecommendationMsg(response.body);
          setProcessFoodRecommendationContent(processedRecommendation);
        } else {
          props.setLoader(false);
          setProcessFoodRecommendationContent('Oops..Food recommendation process resulted in some error');
        }
      },
      (error) => {
        console.log('Error process auto food order...', JSON.stringify(error));
        // setError("ERROR with updating order - " + response.message);
        props.setLoader(false);

        setProcessFoodRecommendationContent('Oops..Food recommendation process resulted in some error');
      }
    );
  };

  const handleNoteTagChange = (e, value) => {
    //to delete the practitioner if delete option is selected
    if (e.target?.className === 'delete icon') {
      setSelectedPractitioners((prevState) =>
        prevState.filter((practitioner) => practitioner !== e.target?.previousSibling?.textContent)
      );
      allPractitioners.forEach((note) => {
        if (note.text === e.target?.previousSibling?.textContent) {
          setNote((prevState) => ({
            ...prevState,
            noteTag: [...prevState.noteTag.filter((practitionerId) => practitionerId !== note.value)],
          }));
        }
      });
    } else if (e.target?.className === 'dropdown icon clear') {
      setNote((prevState) => ({
        ...prevState,
        noteTag: [],
      }));
      setSelectedPractitioners([]);
    } else {
      allPractitioners.forEach((note) => {
        if (note.text === e.target.textContent) {
          setSelectedPractitioners((prevState) => [...prevState, e.target.textContent]);
          setNote((prevState) => ({
            ...prevState,
            noteTag: [...prevState.noteTag, note.value],
          }));
        }
      });
    }
  };

  let sortedNoteList = _.sortBy(noteList, [
    function (note) {
      return note.time;
    },
  ]).reverse();
  let { noteText } = note;

  const [validationErrors, setValidationErrors] = useState([]);
  const [open, setOpen] = useState(null);

  const removeNote = (note) => {
    note = parseInt(note);
    setOpen(null);
    setValidationErrors([]);

    //remove note from sorted list
    let newTaskNoteList = sortedNoteList;
    let removedNote = newTaskNoteList.splice(note, 1);
    //update task with new note list
    let newTaskpayload = taskpayload;
    newTaskpayload.note = newTaskNoteList;

    props.setLoader(true);

    // update task with removed note
    fhirPut(`/Task/${newTaskpayload.id}`, token, newTaskpayload).then(
      () => {
        let provenance: Provenance = ProvenanceBuilder.build(
          `Task/${newTaskpayload.id}`,
          { reference: 'Practitioner/' + personId, display: practitionerName },
          props.patientId,
          'UPDATE',
          `A note (${removedNote[0].text}) has been removed from ${taskpayload?.code?.coding?.[0]?.code} task`,
          { code: props.orgId.toUpperCase(), display: props.orgId.toLowerCase() } //eg. {Organization/HUMANA,humana}
        );
        fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
          () => {
            props.refresh();
            setTimeout(() => {
              props.setLoader(false);
            }, 1000);
          },
          (error) => {
            props.setLoader(false);
            setValidationErrors((state) => [...state, 'Unable to create provenance: ' + error.toString()]);
          }
        );
      },
      (error) => {
        props.setLoader(false);
        setValidationErrors((state) => [...state, 'Unable to remove note: ' + error.toString()]);
      }
    );
  };

  return (
    <>
      <Header style={{ fontSize: '14px' }}>NOTES</Header>
      <Form onSubmit={handleSubmit}>
        {!props.activity ? <>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-note-text"
              width={16}
              rows={3}
              control={TextArea}
              name="noteText"
              value={noteText}
              cols={2}
              placeholder="Add Note"
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <div>
              <Form.Field
                id="form-select-control-notes-tag"
                clearable
                multiple
                control={Select}
                width={3}
                options={allPractitioners}
                selectOnBlur={false}
                placeholder="Tag"
                name="noteTag"
                value={note.noteTag}
                onChange={(e) => handleNoteTagChange(e, note)}
                search
                searchInput={{ id: 'form-select-control-notes-tag' }}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <div>
              <Form.Button size="medium" color="blue" content={'Add'} style={{ marginLeft: '10px' }} />
            </div>
          </Form.Group>
        </>
          : <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-note-text"
              width={8}
              rows={3}
              control={TextArea}
              name="noteText"
              value={noteText}
              cols={2}
              placeholder="Add Note"
              onChange={handleChange}
              required
            />
            <div>
              <Form.Field
                id="form-select-control-notes-tag"
                clearable
                multiple
                control={Select}
                width={3}
                options={allPractitioners}
                selectOnBlur={false}
                placeholder="Tag"
                name="noteTag"
                value={note.noteTag}
                onChange={(e) => handleNoteTagChange(e, note)}
                search
                searchInput={{ id: 'form-select-control-notes-tag' }}
              />
            </div>
            <div>
              <Form.Button size="medium" color="blue" content={'Add'} style={{ marginLeft: '10px' }} />
            </div>
          </Form.Group>}
      </Form>

      {taskpayload?.code?.coding?.[0]?.code && taskpayload?.code?.coding?.[0]?.code === 'food-delivery' ? (
        <>
          <Button
            size="small"
            icon="world"
            color="green"
            onClick={() => autoOrder()}
            content="Process Food Recommendation"
          />
          <div>
            {/* <Label content={processFoodRecommendationContent} /> */}
            {processFoodRecommendationContent}
          </div>
        </>
      ) : (
        ''
      )}

      {validationErrors.length > 0 && (
        <Message error>
          <Message.Header>Error:</Message.Header>
          <Message.List>
            {validationErrors.map((error) => (
              <Message.Item key={error}>{error}</Message.Item>
            ))}
          </Message.List>
        </Message>
      )}
      <Confirm open={open} onCancel={() => setOpen(null)} onConfirm={() => removeNote(open)} />

      <Comment.Group>
        {sortedNoteList.length > 0 ? (
          sortedNoteList.map((note, index) => {
            let notetime = moment(note.time).format('MM-DD-YYYY');
            let authorname = note.authorString;
            return (
              <Comment key={index}>
                <Comment.Avatar
                  src={
                    allPractitioners?.[
                      allPractitioners.findIndex((practitioner) => practitioner.text === note.authorString)
                    ]?.image
                  }
                />
                <Comment.Content>
                  <Comment.Author as="a">{authorname}</Comment.Author>
                  <Comment.Metadata>
                    <div>{notetime}</div>
                    <Button
                      compact
                      size="mini"
                      color="red"
                      content="Remove"
                      onClick={() => setOpen(index.toString())}
                    />
                  </Comment.Metadata>
                  <Comment.Text>{note.text}</Comment.Text>
                </Comment.Content>
              </Comment>
            );
          })
        ) : (
          <>
            <p> Nothing here yet! </p>
            <br />
            <br />
          </>
        )}
      </Comment.Group>
    </>
  );
}

export default TaskNote;
