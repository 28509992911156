import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { buildAWSConfig } from "config/aws-amplify";
import { DEFAULT_ORGANIZATION, getSSOKeysForWhitelist } from "utils";

export const useConfigureAmplify = () => {
  const url = window.location.pathname;
  const currentOrganization = url.split("/")[1];
  const WHITELISTED_ORGANIZATIONS = getSSOKeysForWhitelist();
  const isOrganizationWhitelisted = WHITELISTED_ORGANIZATIONS.includes(currentOrganization);
  const organization = isOrganizationWhitelisted ? currentOrganization : DEFAULT_ORGANIZATION;

  useEffect(() => {
    const resourceAWSConfig = buildAWSConfig(organization);

    Amplify.configure(resourceAWSConfig);
  }, [organization]);
};
