import { forwardRef, useImperativeHandle, useState } from 'react';
import React from 'react';
import useSWR from 'swr';

import { useAuth } from "hooks";
import { fhirGet } from "services";

import Tasks from './tasks';
import { Loader, Message } from 'semantic-ui-react';

const CarePlan = forwardRef((props: any, ref) => {
  const [loading, setLoading] = React.useState(true);
  const [newPractitionerData, setNewPractitionerData] = useState([]);

  const { organizationId, token } = useAuth();

  let tasksError = props.tasksError;
  let tasksMutate = props.tasksMutate;

  // Filter all practitioners that have only 32 digits guid as ID (not email id as GUID)
  const practitionerOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    onSuccess: (data, key, config) => {
      let activePractitioners = data.entry.filter((practitioner) => {
        return (
          practitioner.resource.id.length > 30 &&
          practitioner.resource.identifier?.find(
            (obj) => obj.system === 'https://projectwell.io/fhir/practitioner-types'
          ).value === 'CC' &&
          practitioner.resource.identifier?.find(
              (obj) => obj.system === 'https://projectwell.io/fhir/organization'
          ).value === organizationId
        );
      });
      setNewPractitionerData(activePractitioners);
      setLoading(false);
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error: practitionerError } = useSWR(
    [`/Practitioner?active=true&_count=1000`, token],
    fhirGet,
    practitionerOptions
  );

  useImperativeHandle(ref, () => ({
    refreshTasks() {
      tasksMutate();
    },
    loadData() {
      setLoading(true);
    },
  }));

  return (
    <>
      {loading && <Loader active inline="centered" />}
      <>
        {tasksError && (
          <Message negative>
            <Message.Header>Oops!. Something went wrong.. </Message.Header>
            <p>{tasksError.message}</p>
          </Message>
        )}

        {newPractitionerData && !practitionerError && (
          <Tasks
            orgId={props.org}
            patientId={props.patientId}
            practfilter={true}
            PractitionerData={newPractitionerData}
          />
        )}
      </>
    </>
  );
});

export default CarePlan;
