import React, { memo } from "react";
import * as fhirpath from "fhirpath";
import { List, Loader, Segment } from "semantic-ui-react";

import { filterProfileCodesByOrg, LAB_CODES, PROFILE_CODES, VITAL_CODES } from "utils";

import Labs from "./Lab";
import Observations from "./Observation";
import Vitals from "./Vital";

type ProfileProps = {
  org?: string;
  profileData: any;
  setActiveTab: any;
  sidebar: boolean;
  updateQRID: any;
};

const Profile: React.FC<ProfileProps> = ({ org, profileData, setActiveTab, sidebar, updateQRID }) => {
  const filteredProfileCodes = org ? filterProfileCodesByOrg(org) : PROFILE_CODES;

  const getDataByCode = (code: string) => {
    // Filters observation data to matching profile codes
    let data = profileData?.entry ?? profileData;
    if (data?.length || data?.entry) {
      let filteredData = data?.filter((entry: any) =>
        code
          .toLowerCase()
          .split(",")
          .includes(fhirpath.evaluate(entry?.resource, "code.coding.first().code").toString().toLowerCase())
      );
      let filteredCategoryData = data?.filter((entry: any) =>
        code
          .toLowerCase()
          .split(",")
          .includes(fhirpath.evaluate(entry?.resource, "category.first().coding.first().code").toString().toLowerCase())
      );
      // This will remove duplicate observations. Could happen for labs.
      filteredData = Array.from(new Set([...filteredData, ...filteredCategoryData]));
      if (code === "EDUCATIONAL-MATERIAL-PREFERENCES") {
        // Filter patient observations to remove dupes
        let newFilteredData = [];
        for (let i = 0; i < filteredData.length; i++) {
          let bool = true;
          for (let j = 0; j < newFilteredData.length; j++) {
            if (filteredData[i].resource.valueString.trim() === newFilteredData[j].resource.valueString.trim()) {
              bool = false;
              break;
            }
          }
          if (bool) newFilteredData.push(filteredData[i]);
        }
        filteredData = newFilteredData;
      }

      return filteredData;
    } else {
      return [];
    }
  };

  return (
    <div
      style={{
        width: "100%",
        float: "left",
        marginTop: "15px",
        marginBottom: "15px"
      }}
    >
      {!profileData && <Loader active inline="centered" content="Loading Profile Data..." />}
      {profileData &&
        filteredProfileCodes.map((profile) => (
          <List key={profile.code}>
            <List.Item style={{ textAlign: "left" }}>
              <Segment>
                <List.Content style={{ display: "inline-block" }}>
                  <h4>
                    <List.Icon name={profile.icon} color={profile.color} />
                    {profile.name}: &nbsp;
                  </h4>
                  <Observations code={profile.code} data={getDataByCode(profile.code)} sidebar={sidebar} />
                </List.Content>
              </Segment>
            </List.Item>
          </List>
        ))}
      {profileData &&
        LAB_CODES.map((profile) => (
          <List key={profile.code}>
            <List.Item style={{ textAlign: "left" }}>
              <Segment>
                <List.Content style={{ display: "inline-block" }}>
                  <h4>
                    <List.Icon name={profile.icon} color={profile.color} />
                    {profile.name}: &nbsp;
                  </h4>
                  <Labs
                    data={getDataByCode(profile.code)}
                    setActiveTab={setActiveTab}
                    sidebar={sidebar}
                    updateQRID={updateQRID}
                  />
                </List.Content>
              </Segment>
            </List.Item>
          </List>
        ))}
      {profileData &&
        VITAL_CODES.map((profile) => (
          <List key={profile.code}>
            <List.Item style={{ textAlign: "left" }}>
              <Segment>
                <List.Content style={{ display: "inline-block" }}>
                  <h4>
                    <List.Icon name={profile.icon} color={profile.color} />
                    {profile.name}: &nbsp;
                  </h4>
                  <Vitals vitalsData={getDataByCode(profile.code)} />
                </List.Content>
              </Segment>
            </List.Item>
          </List>
        ))}
    </div>
  );
};

export default memo(Profile);
