import { API } from "aws-amplify";

const fhirEndpoint = (endpoint: string) => `/fhir${endpoint}`;

const fhirHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json"
});

export const fhirGet = async (endpoint: string, token: string) => {
  const data = await API.get(process.env.REACT_APP_API_NAME, fhirEndpoint(endpoint), {
    headers: fhirHeaders(token)
  });

  return data;
};

export const fhirPost = async (endpoint: string, token: string, data: any) => {
  const fhirEndpoint = endpoint.includes("auth-org") ? endpoint : "/fhir" + endpoint;

  const result = await API.post(process.env.REACT_APP_API_NAME, fhirEndpoint, {
    headers: fhirHeaders(token),
    body: data
  });
  return result;
};

export const fhirPut = async (endpoint: string, token: string, data: any) => {
  const result = await API.put(process.env.REACT_APP_API_NAME, fhirEndpoint(endpoint), {
    headers: fhirHeaders(token),
    body: data
  });

  return result;
};

export const multipleFhirGet = (token: string, ...urls: string[]) => {
  if (urls.length) {
    return Promise.all(urls.map((url) => fhirGet(url, token)));
  } else {
    return Promise.resolve();
  }
};
