import { DEFAULT_ORGANIZATION } from "../utils/constants";

type AmplifyOAuthConfig = {
  domain: string;
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
  scope: string[];
};

type AmplifyCookieStorageConfig = {
  domain: string;
};

type AmplifyAuthConfig = {
  cookieStorage: AmplifyCookieStorageConfig;
  mandatorySignIn: string;
  oauth: AmplifyOAuthConfig;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
};

type AmplifyApiEndpointConfig = {
  endpoint: string;
  name: string;
};

type AmplifyApiConfig = {
  endpoints: AmplifyApiEndpointConfig[];
};

export type AmplifyConfig = {
  Auth: AmplifyAuthConfig;
  API: AmplifyApiConfig;
};

const buildAWSAuthConfig = (organization: string): AmplifyAuthConfig => {
  const clientId: string = JSON.parse(process.env.REACT_APP_CLIENTID)[organization];
  const userPool: string = JSON.parse(process.env.REACT_APP_USER_POOL)[organization];
  const userPoolDomain: string = JSON.parse(process.env.REACT_APP_COGNITO_DOMAIN)[organization];

  const buildRedirectUrl = (baseUrl: string, organization: string): string => {
    return `${baseUrl}${organization === DEFAULT_ORGANIZATION ? "" : "/" + organization}`;
  };

  return {
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN
    },
    mandatorySignIn: process.env.REACT_APP_MANDATORY_SIGNIN,
    region: process.env.REACT_APP_REGION,
    userPoolId: userPool,
    userPoolWebClientId: clientId,
    oauth: {
      domain: userPoolDomain,
      redirectSignIn: buildRedirectUrl(process.env.REACT_APP_REDIRECT_SIGNIN_URL, organization),
      redirectSignOut: buildRedirectUrl(process.env.REACT_APP_REDIRECT_SIGNOUT_URL, organization),
      responseType: process.env.REACT_APP_OAUTH_RESPONSETYPE,
      scope: process.env.REACT_APP_SCOPES.split(",")
    }
  };
};

const buildAWSAPIConfig = (): AmplifyApiConfig => {
  return {
    endpoints: [
      {
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        name: process.env.REACT_APP_API_NAME
      },
      {
        endpoint: process.env.REACT_APP_CARE_HOOKS_API_ENDPOINT,
        name: process.env.REACT_APP_CARE_HOOKS_API_NAME
      },
      {
        endpoint: process.env.REACT_APP_FOODCOURT_API_ENDPOINT,
        name: process.env.REACT_APP_FOODCOURT_API_NAME
      }
    ]
  };
};

export const buildAWSConfig = (org: string): AmplifyConfig => {
  const organization = org || DEFAULT_ORGANIZATION;

  return {
    API: buildAWSAPIConfig(),
    Auth: buildAWSAuthConfig(organization)
  };
};
