import { Observation } from 'fhir/r4';
import { v4 as uuid } from 'uuid';
import * as FHIRUtils from '../fhirutils';

export function buildFoodInsecurityObservation(
  patientId: string,
  effectiveDate: string,
  foodInsecure: boolean,
  updatedBy: string
): Observation {
  let observationId = uuid();

  let observation: Observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'food-insecurity',
            display: 'Food Insecurity',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '699653008',
          display: 'Inadequate oral food intake for physiological needs',
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectiveDateTime: effectiveDate,
    valueBoolean: foodInsecure,
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function buildLabObservation(
  patientId: string,
  effectiveDate: string,
  loincLabTypeCode: string,
  labTypeName: string,
  quantity: number,
  unit: string,
  updatedBy: string,
  readingType: string
): Observation {
  let observationId = uuid();

  let observation: Observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: loincLabTypeCode,
          display: labTypeName,
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    extension: [
      {
        url: 'https://projectwell.io/fhir/identifiers/lab-reading-type',
        valueString: readingType,
      },
    ],
    valueQuantity: {
      value: quantity,
      unit: unit,
    },
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function buildPreferenceObservation( //used for both preferences and avoidances
  patientId: string,
  effectiveDate: string,
  preferenceType: { code: string; display: string },
  preferenceName: string,
  updatedBy: string
): Observation {
  let observationId = uuid();

  let observation: Observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    code: {
      coding: [
        {
          system: 'https://projectwell.io/fhir/preference-codes',
          code: preferenceType.code,
          display: preferenceType.display,
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    valueString: preferenceName,
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function buildReferralObservation( //used for adding a new Referal Type preferene observation
  patientId: string,
  effectiveDate: string,
  preferenceType: { code: string; display: string },
  referralType: string,
  referralId: string,
  updatedBy: string,
  orgId: string
): Observation {
  let observationId = uuid();

  let observation: Observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    code: {
      coding: [
        {
          system: 'https://projectwell.io/fhir/preference-codes',
          code: preferenceType.code,
          display: preferenceType.display,
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    identifier: [
      {
        system: 'https://projectwell.io/fhir/identifiers/last-modified-by',
        value: updatedBy,
      },
      {
        system: 'https://projectwell.io/fhir/identifiers/referral-id',
        value: referralId,
      },
      {
        system: 'https://projectwell.io/fhir/identifiers/referral-status',
        value: 'NEW',
      },
      {
        system: 'https://projectwell.io/fhir/identifiers/interventions',
        value: '1',
      },
      {
        system: 'https://projectwell.io/fhir/orgid',
        value: orgId,
      },
    ],
    valueString: referralType,
  };

  return observation;
}
export function buildMedicationAdheranceObservation(
  patientId: string,
  effectiveDate: string,
  ndcCode: string,
  ndcName: string,
  pdcScore: number,
  updatedBy: string
): Observation {
  let observationId = uuid();

  let observation: Observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'http://hl7.org/fhir/sid/ndc',
          code: ndcCode,
          display: ndcName,
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    valueQuantity: {
      value: pdcScore,
    },
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function buildVitalsObservation(
  patientId: string,
  effectiveDate: string,
  vitalTypeCode: string,
  vitalTypeName: string,
  /* quantity: number,
  unit: string, */
  valueString: string,
  updatedBy: string
): Observation {
  let observationId = uuid();

  let observation: Observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'Vital Signs',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: vitalTypeCode,
          display: vitalTypeName,
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    /* valueQuantity: {
      value: quantity,
      unit: unit,
    }, */
    valueString: valueString,
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function buildMaternityHealthObservation(
  patientId: string,
  effectiveDate: string,
  maternityTypeObs: string,
  maternityTypeCode: string,
  maternityTypeName: string,
  valueString: string,
  updatedBy: string
): Observation {
  let observationId = uuid();

  let observation: Observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
            code: maternityTypeCode,
            display: maternityTypeObs,
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          code: 'MATERNITY-HEALTH',
          display: maternityTypeName,
          system: 'https://projectwell.io/fhir/preference-codes',
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    valueString: valueString,
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

//eg. CCI
export function buildSocialAssessmentObservation(
  patientId: string,
  effectiveDate: string,
  code: string,
  name: string,
  integerValue: number,
  updatedBy: string
): Observation {
  let observationId = uuid();

  let observation: Observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History and Assessed Risk factors',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: code,
          display: name,
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    valueInteger: integerValue,
  };
  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function getCommonObservationCodes() {
  return {
    'BP-SYSTOLIC': {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8480-6',
          display: 'Systolic blood pressure',
        },
      ],
    },
    'BP-DIASTOLIC': {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8462-4',
          display: 'Diastolic blood pressure',
        },
      ],
    },
    'CCI-SCORE': {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '762713009',
          display: 'Charlson Comorbidity Index',
        },
      ],
    },
    SRI: {
      coding: [
        {
          system: 'https://projectwell.io/fhir/social-indices',
          code: 'SRI',
          display: 'Social Risk Index',
        },
      ],
    },
  };
}

//TODO: not currently in use, but need to update pii variable if we use this function to submit observations from pii survey
export function buildPreferenceFromQuestionnaireResponse(
  patientId: string,
  effectiveDate: string,
  preferenceType: { code: string; display: string },
  preferenceName: string,
  updatedBy: string,
  questionnaireId: string,
  questionnaireTitle: string,
  questionnaireResponseId: string,
  pii: boolean = false //need to update when we use this function to submit observations from pii survey
): Observation {
  let observationId = uuid();

  let observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
            code: questionnaireId,
            display: questionnaireTitle,
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'https://projectwell.io/fhir/preference-codes',
          code: preferenceType.code,
          display: preferenceType.display,
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    valueString: preferenceName,
  };
  //save PII questionnaireResponse Id as an extension instead of derivedFrom for preferences
  if (pii) {
    observation['extension'] = [
      {
        url: 'https://projectwell.io/fhir/identifiers/questionnaireResponse',
        valueString: questionnaireResponseId,
      },
    ];
  } else {
    observation['derivedFrom'] = [
      {
        reference: 'QuestionnaireResponse/' + questionnaireResponseId,
        display: 'Questionnaire',
      },
    ];
  }
  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function buildLabFromQuestionnaireResponse(
  patientId: string,
  effectiveDate: string,
  labType: { code: string; display: string },
  value: number,
  updatedBy: string,
  questionnaireResponseId: string,
  valueUnit: string
): Observation {
  let observationId = uuid();

  let observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            code: 'laboratory',
            display: 'Laboratory',
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          code: labType.code,
          display: labType.display,
          system: 'https://projectwell.io/fhir/laboratory',
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    valueQuantity: {
      unit: valueUnit,
      value: value,
    },
    derivedFrom: [
      {
        reference: `QuestionnaireResponse/${questionnaireResponseId}`,
        display: 'Questionnaire',
      },
    ],
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function buildVitalsFromQuestionnaireResponse(
  patientId: string,
  effectiveDate: string,
  vital: { code: string; display: string },
  valueString: string,
  updatedBy: string,
  questionnaireResponseId: string
): Observation {
  let observationId = uuid();

  let observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            code: 'vital-signs',
            display: 'Vital Signs',
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          code: vital.code,
          display: vital.display,
          system: 'http://loinc.org',
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    valueString: valueString,
    derivedFrom: [
      {
        reference: `QuestionnaireResponse/${questionnaireResponseId}`,
        display: 'Questionnaire',
      },
    ],
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}

export function buildBloodPressureVitalsFromQuestionnaireResponse(
  patientId: string,
  effectiveDate: string,
  vital: { code: string; display: string },
  systolicValue: number,
  diastolicValue: number,
  valueString: string,
  updatedBy: string,
  questionnaireResponseId: string
): Observation {
  let observationId = uuid();

  let observation = {
    resourceType: 'Observation',
    id: observationId,
    status: 'final',
    category: [
      {
        coding: [
          {
            code: 'vital-signs',
            display: 'Vital Signs',
            system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          code: vital.code,
          display: vital.display,
          system: 'http://loinc.org',
        },
      ],
    },
    subject: {
      reference: 'Patient/' + patientId,
    },
    effectivePeriod: {
      start: effectiveDate,
    },
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '8480-6',
              display: 'Systolic blood pressure',
            },
          ],
        },
        valueQuantity: {
          value: systolicValue,
          unit: 'mmHg',
        },
      },
      {
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '8462-4',
              display: 'Diastolic blood pressure',
            },
          ],
        },
        valueQuantity: {
          value: diastolicValue,
          unit: 'mmHg',
        },
      },
    ],
    valueString: valueString,
    derivedFrom: [
      {
        reference: `QuestionnaireResponse/${questionnaireResponseId}`,
        display: 'Questionnaire',
      },
    ],
  };

  return FHIRUtils.addUpdatedBy(observation, updatedBy);
}
