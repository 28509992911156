import { useState, useEffect } from "react";
import { Patient } from "fhir/r4";
import moment from "moment";
import { Image, List, Icon, Feed, Card, Loader, Popup, Label, Header } from "semantic-ui-react";
import useSWR from "swr";

import * as FHIRUtils from "../../../utils/FHIRUtils";
import PatientCDO from "../../../models/patient";
import EditPatient from "../edit";
import { useAuth } from "hooks";
import { fhirGet } from "services";

type PatientBannerProps = {
  careplan: any;
  careTeamData?: any;
  org?: string;
  patient: Patient;
  setSelectedPatientInfo: (patient: any) => void;
  tasks?: any;
};

const PatientBanner: React.FC<PatientBannerProps> = ({
  careplan,
  careTeamData,
  org,
  patient,
  setSelectedPatientInfo,
  tasks
}) => {
  const { token } = useAuth();

  const [activityData, setActivityData] = useState([]);
  const [careTeam, setCareTeam] = useState({});
  const [flatPatient, setFlatPatient] = useState(PatientCDO);
  const [openEdit, setOpenEdit] = useState(false);
  const [operation, setOperation] = useState("");

  let fhirPatient: Patient = patient;
  const options = { shouldRetryOnError: false, revalidateOnFocus: false };
  const organization = org?.split("-")[0];
  const isOrganizationBCNC = organization === "BCNC";

  useEffect(() => {
    let parsedPatient = FHIRUtils.parseFHIRPatient(fhirPatient);
    setFlatPatient(parsedPatient);
  }, [fhirPatient]);

  useEffect(() => {
    let careTeamResource = careTeamData?.entry?.length && careTeamData?.entry[0]?.resource;
    setCareTeam(FHIRUtils.parseFHIRCareTeam(careTeamResource));
  }, [careTeamData]);

  // Fetch provenance data
  const { data: provenanceData, error: provenanceError } = useSWR(
    [`/Provenance/?patient=${patient.id}&_sort=-_lastUpdated&_count=1000`, token],
    fhirGet,
    options
  );

  useEffect(() => {
    if (provenanceData && provenanceData.entry && provenanceData.entry.length) {
      let flatProvenanceData = provenanceData.entry.map((provenance) => {
        let flatProvenance = FHIRUtils.parseFHIRProvenance(provenance.resource);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        flatProvenance.lastUpdated =
          flatProvenance.lastUpdated &&
          moment(flatProvenance.lastUpdated, "YYYY-MM-DDTHH:mm:ss").format("MMMM Do, YYYY HH:mm:ss");
        return flatProvenance;
      });

      setActivityData(
        flatProvenanceData.filter((flatProvenance) => {
          return (
            flatProvenance.reason.includes("Screen") ||
            flatProvenance.reason.includes("Care Plan") ||
            // flatProvenance.reason.includes("Intake") ||
            flatProvenance.reason.includes("Assessment") ||
            flatProvenance.reason.includes("Intervention")
          ); //include only these
        })
      );
    }
  }, [provenanceData]);

  // For Update Patient Details modal window
  let toggleModal = (flag, fhirPatient) => {
    if (operation === "Update") {
      setOpenEdit(flag);
    }

    if (fhirPatient !== undefined && fhirPatient) {
      const parsedPatient = FHIRUtils.parseFHIRPatient(fhirPatient);
      setFlatPatient(parsedPatient);
      // Updating the patient of Patient360
      setSelectedPatientInfo(parsedPatient);
    }
  };

  let imageName = "molly.png";

  if (flatPatient.gender) {
    if (flatPatient.gender === "male") {
      imageName = "elliot.jpg";
    }
  }

  return (
    <List fluid stretched>
      <List.Item>
        <Card fluid stretched>
          <Image src={"https://react.semantic-ui.com/images/avatar/large/" + imageName} wrapped ui={false} />
          <Card.Content>
            <Card.Header>
              {flatPatient.firstName} {flatPatient.lastName}
              <Popup
                content="Edit Member Details"
                trigger={
                  <Icon
                    data-content="Edit member"
                    color="blue"
                    link
                    onClick={() => {
                      setOpenEdit(true);
                      setOperation("Update");
                    }}
                    name="edit"
                    style={{ marginLeft: 5, fontSize: "1.2rem" }}
                  />
                }
              />
            </Card.Header>
            <Card.Description>
              <div style={{ marginBottom: 10 }}>
                {tasks?.entry?.find(t => t.resource?.reasonCode?.text === "intervention")?.resource.status === "completed"
                  ? "program complete " + moment.utc(tasks.entry.find(t => t.resource.reasonCode.text === "intervention").resource.executionPeriod.start).local().fromNow()
                  : tasks?.entry?.find(t => t.resource?.reasonCode?.text === "intervention")?.resource.status === "failed"
                    || tasks?.entry?.find(t => t.resource?.reasonCode?.text === "intervention")?.resource.status === "cancelled"
                    ? "disenrolled " + moment.utc(tasks.entry.find(t => t.resource.reasonCode.text === "intervention").resource.executionPeriod.start).local().fromNow()
                    : careplan?.entry?.[0]?.resource?.identifier?.find(
                      (i) => i.system === "https://projectwell.io/fhir/identifiers/selected-intervention"
                    )?.value
                      ? "enrolled " + moment.utc(careplan.entry[0].resource.period.start).local().fromNow()
                      : "not yet enrolled"}
              </div>
              {isOrganizationBCNC && (
                <>
                  {flatPatient.groupName && (
                    <>
                      <Header as="h6" style={{ marginBottom: 5 }}>
                        Group Name:
                      </Header>
                      <Label style={{ marginBottom: 5 }}>{flatPatient.groupName}</Label>
                    </>
                  )}
                  {flatPatient.groupId && (
                    <>
                      <Header as="h6" style={{ marginBottom: 5 }}>
                        Group ID:
                      </Header>
                      <Label style={{ marginBottom: 5 }}>{flatPatient.groupId}</Label>
                    </>
                  )}
                </>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </List.Item>
      <List.Item>
        <Card>
          <Card.Content>
            <Card.Header>Program Activity</Card.Header>
          </Card.Content>
          <Card.Content>
            {!provenanceData && <Loader active inline="centered" content="Loading Member..." />}
            {provenanceData && !provenanceError && (
              <Feed size="small">
                {activityData.map(({ id, lastUpdated, reason }) => (
                  <Feed.Event key={id}>
                    <Feed.Label>
                      <Icon size="tiny" name="clock outline" color="purple" />
                    </Feed.Label>
                    <Feed.Content style={{ marginLeft: "0px", position: "relative" }}>
                      <Feed.Date content={moment.utc(lastUpdated, "MMMM Do, YYYY hh:mm:ss").local().fromNow()} />
                      <Feed.Extra text style={{ maxWidth: "8vw" }}>
                        {reason}
                      </Feed.Extra>
                    </Feed.Content>
                  </Feed.Event>
                ))}
              </Feed>
            )}
          </Card.Content>
        </Card>

        {openEdit && (
          <EditPatient
            careteamStatus={careTeam["status"]}
            fhirPatient={fhirPatient}
            organization={flatPatient.org}
            open={openEdit}
            operation={"Update"}
            patient={flatPatient}
            patientId={fhirPatient.id}
            toggleModal={toggleModal}
          />
        )}
      </List.Item>
    </List>
  );
};

export default PatientBanner;
