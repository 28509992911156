import { API } from "aws-amplify";

export const foodCourtGet = async (endpoint: string, token: string) => {
  if (!endpoint) {
    return;
  }

  const data = await API.get(process.env.REACT_APP_FOODCOURT_API_NAME, endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });

  return data;
};

export const foodCourtPost = async (endpoint: string, token: string, data: any) => {
  if (!endpoint) {
    return;
  }

  const result = await API.post(
    process.env.REACT_APP_FOODCOURT_API_NAME,
    endpoint,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: data,
    },
  );

  return result;
};
