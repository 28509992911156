import Carebetty from "./CareBetty";

type CommunicationsProps = {
  patient: any;
};

const Communications: React.FC<CommunicationsProps> = ({ patient }) => {
  return <Carebetty patient={patient} />;
};

export default Communications;
