import React, { useState } from "react";
import { Loader, Message } from "semantic-ui-react";
import useSWR from "swr";

import { useAuth } from "hooks";
import { fhirGet } from "services";
import { DEFAULT_ERROR_MESSAGE } from "utils";

import OrdersTable from "./table";

type PatientOrdersProps = {
  patientId: string;
};

const PatientOrders: React.FC<PatientOrdersProps> = ({ patientId }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onError: (error) => {
      setLoading(false);
      console.error("Error occurred while fetching data:", error);
    },
    onSuccess: (data) => {
      setLoading(false);
    }
  };

  const { data: ordersData, error: ordersError } = useSWR(
    [`/NutritionOrder/?patient=${patientId}`, token],
    fhirGet,
    options
  );

  return (
    <div>
      <div>
        {((!ordersData && !ordersError) || loading) && <Loader active inline="centered" />}
        {ordersData && ordersData.length === 0 && <Message warning header="Info" content="0 items found!" />}
        {ordersError && <Message header={DEFAULT_ERROR_MESSAGE} content={ordersError.message} negative />}
        {ordersData && ordersData.length > 0 && <OrdersTable data={ordersData} />}
      </div>
    </div>
  );
};
export default PatientOrders;
