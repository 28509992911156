import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Image, Loader, Menu, Message } from "semantic-ui-react";
import useSWR from "swr";

import { fhirGet } from "services";
import { DEFAULT_ERROR_MESSAGE } from "utils";

import Patient360 from "..";

const PatientTab: React.FC = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const hostName = window.location.hostname;
  const authToken = localStorage.getItem(`${hostName}-authToken`);

  // Open Carebetty home
  const homeClicked = () => {
    console.log("Home: " + window.location.host);
    window.open(`${window.location.host}/`, "_blank");
  };

  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    onSuccess: () => {
      setLoading(false);
    },
    onError: (err) => {
      console.log(err);
      setLoading(false);
    }
  };

  const patientId = params.patientId;
  const { data: patientData, error: patientError } = useSWR([`/Patient/${patientId}`, authToken], fhirGet, options);

  return (
    <div id="root" className="topbar">
      <Menu size="small" inverted>
        <Menu.Item key="image">
          <Image src="/images/nrx.jpg" />
        </Menu.Item>

        <Menu.Item link={true} key="title" name="CARE BETTY" onClick={homeClicked} />
      </Menu>

      {loading && <Loader active inline="centered" />}
      {patientError && (
        <Message negative>
          <Message.Header>{DEFAULT_ERROR_MESSAGE}</Message.Header>
          <p>{patientError.message}</p>
        </Message>
      )}
      {patientData && <Patient360 patient={patientData} />}
    </div>
  );
};

export default PatientTab;
