import { sortBy } from "lodash";

export const sortReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction: state.direction === "ascending" ? "descending" : "ascending"
        };
      }
      return {
        column: action.column,
        data: sortBy(state.data, [action.column]),
        direction: "ascending"
      };
    case "CLEAR_FILTERS":
      return {
        ...state,
        data: action.data
      };
    default:
      throw new Error();
  }
};
