import React, { useEffect, useState } from "react";
import { Patient } from "fhir/r4";
import { Grid, Loader, Segment, Tab, TabProps } from "semantic-ui-react";
import useSWR from "swr";

import { useAuth } from "hooks";
import { fhirGet } from "services";
import {
  defaultSWROptions,
  filterProfileCodesByOrg,
  LAB_CODES,
  MATERNITY_HEALTH_CODES,
  PROFILE_CODES,
  VITAL_CODES
} from "utils";

import ActionableInsights from "./actionable-insights";
import PatientBanner from "./banner";
import Communications from "./communications";
import Profile from "./profile";
import Questionnaires from "./questionnaires";
import CarePlanComp from "./careplan";
import Food from "../common/food";
import Referrals from "../common/referrals";
import * as FHIRUtils from "../../utils/FHIRUtils";

type Patient360Props = {
  org?: string;
  patient: Patient;
  refreshPatientDetails?: () => void;
};

const Patient360: React.FC<Patient360Props> = ({ org, patient, refreshPatientDetails }) => {
  const { token } = useAuth();

  const fhirPatient: Patient = patient;
  const isOrganizationIONIS = org === "IONIS";

  const [activeTab, setActiveTab] = useState("");
  const [flatpatient, setFlatpatient] = useState(FHIRUtils.parseFHIRPatient(fhirPatient));
  const [profileData, setProfileData] = useState({});
  const [QRID, setQRID] = useState<{ id: string; isNewResponse: boolean }>();

  const handleTabChange = (e: React.MouseEvent<HTMLDivElement>, { activeIndex, panes }: TabProps) => {
    setActiveTab(panes[activeIndex].menuItem.name);
  };

  const updateQRID = (newID, isNewResponse) => {
    setQRID({ id: newID, isNewResponse });
  };

  const profileOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onSuccess: (data) => {
      setProfileData(data);
    }
  };

  const filteredProfileCodes = org ? filterProfileCodesByOrg(org) : PROFILE_CODES;

  const { mutate: profileMutate } = useSWR(
    [
      `/Observation/` +
        `?patient=${fhirPatient.id}` +
        `&_sort=-date` +
        `&code=${
          filteredProfileCodes.map((p) => p.code).join(",") +
          "," +
          LAB_CODES.map((l) => l.code).join(",") +
          "," +
          VITAL_CODES.map((v) => v.code).join(",") +
          "," +
          MATERNITY_HEALTH_CODES.map((m) => m.code).join(",")
        }`,
      token
    ],
    fhirGet,
    profileOptions
  );

  const { data: communicationsData } = useSWR(
    ["/Communication/?patient=" + fhirPatient.id, token],
    fhirGet,
    defaultSWROptions
  );

  const { data: carePlanData } = useSWR(["/CarePlan/?patient=" + fhirPatient.id, token], fhirGet, defaultSWROptions);

  const { data: tasksData, mutate: tasksMutate, error: tasksError } = useSWR(
    ["/Task/?patient=" + fhirPatient.id, token],
    fhirGet,
    defaultSWROptions
  );

  const tabPanes = [
    isOrganizationIONIS && {
      menuItem: {
        key: 'careplan',
        color: 'blue',
        content: 'TASKS',
        name: 'CARE_PLAN',
      },
      pane: {
        key: 'careplans',
        content: (
          <CarePlanComp
            patientId={fhirPatient.id}
            org={flatpatient.org}
            tasksError={tasksError}
            tasksMutate={tasksMutate}
          />
        ),
      },
    },
    {
      menuItem: {
        key: "profile",
        color: "orange",
        content: "PROFILE",
        name: "PROFILE"
      },
      pane: {
        key: "profiles",
        content: (
          <Tab.Pane style={{ overflow: "auto" }} color="orange">
            <Profile
              org={org}
              profileData={profileData}
              setActiveTab={setActiveTab}
              sidebar={false}
              updateQRID={updateQRID}
            />
          </Tab.Pane>
        )
      }
    },
    {
      menuItem: {
        key: "survey",
        color: "blue",
        content: "SURVEYS",
        name: "SURVEYS"
      },
      pane: {
        key: "surveys",
        content: (
          <Questionnaires
            fhirPatient={fhirPatient}
            org={flatpatient.org}
            patientId={fhirPatient.id}
            patientEmail={flatpatient.email}
            patientMobilePhone={flatpatient.mobilePhone}
            patientFirstName={flatpatient.firstName}
            profileMutate={profileMutate}
            QRID={QRID}
            refreshPatientDetails={refreshPatientDetails}
            tasksMutate={tasksMutate}
          />
        )
      }
    },
    {
      menuItem: {
        key: "communication",
        color: "orange",
        content: "COMMUNICATION",
        name: "ENGAGEMENT"
      },
      pane: {
        key: "communications",
        content: <Communications patient={flatpatient} />
      }
    },
    {
      menuItem: {
        key: "order",
        color: "orange",
        content: "FOOD ORDERS",
        name: "ENCOUNTERS"
      },
      pane: {
        key: "orders",
        content: <Food patientId={fhirPatient.id} />
      }
    },
    {
      menuItem: {
        key: "ai",
        color: "orange",
        content: "INSIGHTS",
        name: "AI"
      },
      pane: {
        key: "ais",
        content: <ActionableInsights patientId={fhirPatient.id} />
      }
    },
    {
      menuItem: {
        key: "referral",
        color: "blue",
        content: "REFERRALS",
        name: "REFERRALS"
      },
      pane: {
        key: "referrals",
        content: <Referrals activeTab={activeTab} patientId={fhirPatient.id} org={flatpatient.org} />
      }
    }
  ].filter(
    (pane) =>
      pane &&
      process.env.REACT_APP_TAB_CONFIG &&
      JSON.parse(process.env.REACT_APP_TAB_CONFIG) &&
      !JSON.parse(process.env.REACT_APP_TAB_CONFIG)?.[flatpatient.org]?.includes(pane.menuItem.key)
  );

  useEffect(() => {
    setFlatpatient(FHIRUtils.parseFHIRPatient(fhirPatient));
  }, [fhirPatient]);

  return (
    <Grid cols={2} padded="horizontally" stretched>
      <Grid.Row divided>
        <Grid.Column width="4">
          <Segment>
            {!communicationsData && !carePlanData && !tasksData && (
              <Loader active content="Loading member..." inline="centered" />
            )}
            {communicationsData && carePlanData && tasksData && (
              <PatientBanner
                careplan={carePlanData}
                org={org}
                patient={fhirPatient}
                setSelectedPatientInfo={setFlatpatient}
                tasks={tasksData}
              />
            )}
          </Segment>
        </Grid.Column>
        <Grid.Column width="12">
          <Segment>
            <Tab
              defaultActiveIndex={0}
              menu={{ key: "pusher", secondary: true, pointing: true }}
              onTabChange={handleTabChange}
              panes={tabPanes}
              renderActiveOnly={false}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Patient360;
