import React from "react";
import moment from "moment";
import { Icon, Popup, Table } from "semantic-ui-react";

import { STATUS_COLORS } from "./constants";

type OrdersTableProps = {
  data: any;
};

const OrdersTable: React.FC<OrdersTableProps> = ({ data }) => {
  return (
    <div className="table-div">
      <Table sortable celled singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ordered</Table.HeaderCell>
            <Table.HeaderCell width={1}>Order Detail</Table.HeaderCell>
            <Table.HeaderCell width={1}>Flavor</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>

            <Table.HeaderCell>Expected By</Table.HeaderCell>
            <Table.HeaderCell>Carrier</Table.HeaderCell>
            <Table.HeaderCell>Tracking #</Table.HeaderCell>
            <Table.HeaderCell>Delivery Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((order) => {
            const orderStatus = order.status && order.status.toLowerCase();
            const trackingLink =
              order.deliveryInfo &&
              order.deliveryInfo.trackingCode &&
              order.deliveryInfo.carrier &&
              (order.deliveryInfo.carrier.toLowerCase() === "fedex"
                ? "https://www.fedex.com/apps/fedextrack/?tracknumbers=" + order.deliveryInfo.trackingCode
                : order.deliveryInfo.carrier.toLowerCase() === "ups"
                  ? "https://www.ups.com/track?tracknum=" + order.deliveryInfo.trackingCode
                  : "https://google.com/search?q=" +
                    order.deliveryInfo.trackingCode +
                    "+" +
                    order.deliveryInfo.carrier);
            let colorCode = STATUS_COLORS[orderStatus];
            let itemsContent = order.orderItems && order.orderItems.length > 0 && (
              <Table celled compact color="purple">
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>Food Items</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {order.orderItems.map((item, ind) => {
                    return (
                      <Table.Row key={ind}>
                        <Table.Cell>{item.name}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            );
            const orderName = (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{order.foodTypeName}</div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div>
                  {itemsContent && (
                    <Popup
                      wide
                      on="click"
                      position="right center"
                      closeOnEscape
                      closeOnDocumentClick
                      content={itemsContent}
                      trigger={<Icon link name="clipboard list" color="purple" />}
                    />
                  )}
                </div>
              </div>
            );
            return (
              <Table.Row
                positive={colorCode === "yy"}
                warning={colorCode === "warning"}
                negative={colorCode === "negative"}
                error={colorCode === "error"}
                style={{
                  backgroundColor: (colorCode === "purple" && "#F0F8FF") || (colorCode === "purple" && "#ffefd5"),
                  color: colorCode === "zz" && "#808080"
                }}
              >
                <Table.Cell>{order.orderDate ? moment.utc(order.orderDate).local().fromNow() : ""}</Table.Cell>
                <Table.Cell>{orderName}</Table.Cell>
                <Table.Cell>{order.flavorName}</Table.Cell>
                <Table.Cell>{order.status ? order.status.toString().toUpperCase() : ""}</Table.Cell>
                <Table.Cell>{order.estimatedDeliveryDate ? order.estimatedDeliveryDate : ""}</Table.Cell>
                <Table.Cell>{order.deliveryInfo ? order.deliveryInfo.carrier : ""}</Table.Cell>
                <Table.Cell>
                  {order.deliveryInfo ? (
                    <a href={trackingLink} rel="noreferrer" target="_blank">
                      {order.deliveryInfo.trackingCode}
                    </a>
                  ) : (
                    ""
                  )}
                </Table.Cell>
                <Table.Cell>
                  {order.deliveryDate ? moment.utc(order.deliveryDate).local().format("YYYY-MM-DD") : ""}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default OrdersTable;
