import { useState, useEffect } from "react";
import { v4 as uuid } from 'uuid';
import { FhirResource } from "fhir/r4";
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  Button,
  Checkbox,
  Confirm,
  Dimmer,
  Form,
  Header,
  Icon,
  Input,
  Loader,
  Message,
  Modal,
  Segment,
  Select
} from "semantic-ui-react";

import { useAuth, useOrganizations } from "hooks";
import { fhirGet, fhirPut } from "services";
import { states } from "utils";

import { FetchStateAbbr, PreparePatientPayLoad, ValidatePatient } from "./utils";
import * as Helpers from "../../../utils/Helpers";
import PatientCDO from "../../../models/patient";
import { FHIRUtils } from "../../../fhir-sdoh";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

const genderOptions = [
  { key: "m", text: "Male", value: "Male" },
  { key: "f", text: "Female", value: "Female" },
  { key: "na", text: "Unknown", value: "Unknown" }
];

const genderConervension = {
  male: "Male",
  female: "Female",
  unknown: "Unknown"
};

type PatientProps = {
  careteamStatus: string;
  fhirPatient?: any;
  open: boolean;
  operation: string;
  organization: string;
  patient: any;
  patientId: string;
  toggleModal: (flag: boolean, patient?: any) => void;
  onNewPatient?: () => void;
};

// UI12: modal flag 'open' passed here from PatientCard
const Patient: React.FC<PatientProps> = ({
  careteamStatus,
  fhirPatient,
  open,
  operation,
  organization,
  patient,
  patientId,
  toggleModal,
  onNewPatient
}) => {
  const { personName, token, validateUSPSAddress } = useAuth();
  const { selectedEligibilityGroup } = useOrganizations();
  let practitionerName = personName;

  // Open the popup window to confirm to create the patient
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmToCreatePatient, setConfirmToCreatePatient] = useState(false);
  const [dimmerProcess, setDimmerProcess] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [displayDeliveryAddress, setDisplayDeliveryAddress] = useState(true);

  let flatPatient = patient;
  let setFlag = toggleModal;

  useEffect(() => {
    if (confirmToCreatePatient) {
      //if the confirmToCreatePatient is set to true, need to update validatePatientDetails with the updated confirmToCreatePatient state
      validatePatientDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmToCreatePatient]);

  let [patientDate, setPatientDate] = useState(moment("1990-01-01").toDate());

  // Object template for patient builder to create Patient object
  let [patientDetails, setPatientDetails] = useState(PatientCDO);

  // Default values population for Country and DOB
  useEffect(() => {
    flatPatient.country =
      flatPatient.country === undefined || flatPatient.country === "" ? ["USA"] : flatPatient.country;
    flatPatient.delivery_country =
      flatPatient.delivery_country === undefined || flatPatient.delivery_country === ""
        ? ["USA"]
        : flatPatient.delivery_country;
    flatPatient.org = organization;
    flatPatient.gender = flatPatient.gender ? genderConervension[flatPatient.gender.toLowerCase()] : "";
    let dob =
      flatPatient.dob === undefined || flatPatient.dob === ""
        ? moment("1990-01-01").toDate()
        : moment(flatPatient.dob).toDate();
    setPatientDate(dob);
    flatPatient.dob = moment(dob).format("MM/DD/YYYY");

    flatPatient.state[0] = flatPatient.state[0] !== undefined && FetchStateAbbr(flatPatient.state[0]);
    flatPatient.delivery_state[0] =
      flatPatient.delivery_state[0] !== undefined && FetchStateAbbr(flatPatient.delivery_state[0]);
    setPatientDetails(flatPatient);

    if (flatPatient && operation === "Update") {
      if (
        flatPatient.addressLine1[0] === flatPatient.delivery_addressLine1[0] &&
        flatPatient.addressLine2[0].trim() === flatPatient.delivery_addressLine2[0] &&
        flatPatient.city[0] === flatPatient.delivery_city[0] &&
        flatPatient.state[0] === flatPatient.delivery_state[0] &&
        flatPatient.zip[0] === flatPatient.delivery_zip[0]
      ) {
        setDisplayDeliveryAddress(true);
      } else {
        setDisplayDeliveryAddress(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatPatient, operation]);

  const handleChange = (e, attributeName) => {
    //let { name, value } = e.target;
    if (e && e.target) {
      let name = e.target.name;
      let value = "";
      if (attributeName === "dob") {
        name = attributeName;
        let rawdob = e.target.dataset.testid.split("-");
        value = rawdob[3] + "/" + rawdob[4] + "/" + rawdob[2];
      } else if (attributeName === "gender" || attributeName === "state" || attributeName === "delivery_state") {
        name = attributeName;
        value = e.target.textContent;
      } else {
        let xmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
        let jsRegex = /\w+\s*=\s*function\s*\(.*?\)\s*\{.*?\}/;
        if (e.target.value.match(xmlRegex) || e.target.value.match(jsRegex)) {
          setValidationErrors((state) => [
            ...state,
            attributeName?.name + ": could not validate, please try a different value"
          ]);
        } else {
          value = e.target.value;
        }
      }
      let textObjs = ["org", "dob", "gender"];

      // Pass the data as an array for objects other than dob, gender and Organization
      setPatientDetails((prevState) => ({
        ...prevState,
        [name]: textObjs.includes(name) ? value : [value]
      }));
    }
  };

  // Validation of Form fields
  const validatePatientDetails = async () => {
    for (const property in patientDetails) {
      if (Array.isArray(patientDetails[property]) && patientDetails[property][0] !== undefined) {
        patientDetails[property][0] = patientDetails[property][0].trim();
      } else if (typeof patientDetails[property] === "string" && patientDetails[property] !== undefined) {
        patientDetails[property] = patientDetails[property].trim();
      }
    }
    let tempConfirmToCreatePatient = confirmToCreatePatient; //used to update the confirmToCreatePatient but not update the state

    //TEMP, add eligibility group to patientDetails
    patientDetails.eligibilityGroup = selectedEligibilityGroup;

    let patientPayLoad = PreparePatientPayLoad(
      patientDetails,
      operation,
      practitionerName,
      displayDeliveryAddress,
      patientId,
      careteamStatus,
      fhirPatient ?? undefined //patient original data
    );

    setValidationErrors((state) => []);
    let validations = ValidatePatient(patientDetails, operation, practitionerName, displayDeliveryAddress, patientId);
    setValidationErrors(validations.errors);
    if (validations.valid) {
      //setDimmerProcess(true);
      if (operation === "Add") {
        //check if the patient with same member id exists in the fhirstore
        let orgId = patientPayLoad?.managingOrganization?.reference?.split('/')?.[1];
        let memberId = patientPayLoad?.identifier?.find(
          (i) => i?.system === 'https://projectwell.io/fhir/identifiers/member-id'
        )?.value;

        let key = memberId && orgId && `/Patient/?${`organization=${orgId}&_content:contains=${memberId}`}`;
        if (key) {
          //check in the payer patient or the pw fhirstore
          let patientResult = await fhirGet(key, token);
          //if patient with same member id exists, show popup to confirm if it's okay to create the patient
          if (patientResult?.total > 0 && !confirmToCreatePatient) {
            //patient with the same member id exists
            setConfirmOpen(true);
          } else {
            //if the patient doesn't exist, we don't need to confirm to create the patient, set tempConfirmToCreatePatient to true
            tempConfirmToCreatePatient = true;
          }

          if (patientResult?.total > 0 && confirmToCreatePatient) {
            //using GUID if patient exists and confirm to create patient
            patientPayLoad.id = uuid();
          }
        } else {
          setValidationErrors((state) => [
            ...state,
            `${!orgId ? 'Organization' : 'Member id'} is missing. please update.`,
          ]);
        }
      } else {
        //when we update the patient information, always set confirmToCreatePatient to true as the member already exist in the fhirstore
        tempConfirmToCreatePatient = true;
      }
    }

    if (validations.valid && validateUSPSAddress) {
      // USPS address validation
      let addressValidationPromise: number = 0;
      for (let item of patientPayLoad.address) {
        Helpers.validateUSPSAddress(item.line[0], item.line[1], item.city, item.state, item.postalCode).then(
          // eslint-disable-next-line no-loop-func
          (success) => {
            addressValidationPromise++;
            if (
              (patientPayLoad.address.length === addressValidationPromise || displayDeliveryAddress) &&
              tempConfirmToCreatePatient
            ) {
              savePatient(token, patientPayLoad);
            }
          },
          (errors) => {
            if (errors.length) {
              let type = item.type === "physical" ? "Delivery Address: " : "Mailing Address: ";
              errors.forEach((error) => {
                setValidationErrors((state) => [...state, type + error]);
              });
            }
          }
        );
        if (displayDeliveryAddress) {
          return false;
        }
      }
    } else if (validations.valid && !validateUSPSAddress && tempConfirmToCreatePatient) {
      savePatient(token, patientPayLoad);
    }
  };

  // FHIR Call to update patient details
  const savePatient = (token, patientPayLoad) => {
    setDimmerProcess(true);

    const fhirBundle: FhirResource = FHIRUtils.buildEmptyBundle("transaction");
    const bundleEntries: any[] = [];

    //now post bundle request
    fhirBundle.entry = bundleEntries;

    fhirPut("/Patient/" + patientPayLoad.id, token, patientPayLoad).then(
      (response) => {
        setDimmerProcess(false);
        setFlag(false, response);
        if (onNewPatient) {
          onNewPatient();
        }
      },
      () => {
        setDimmerProcess(false);
        setValidationErrors((state) => [
          ...state,
          "Unable to " + operation.toLowerCase() + " member details...Please contact helpdesk"
        ]);
      }
    );
  };

  // Populate validation/error messages on the form
  const setValidationMessages = () => {
    return validationErrors.map((error) => {
      return <Message.Item>{error}</Message.Item>;
    });
  };

  //pull fields to disable/display from env var.
  let fieldsToDisable,
    fieldsToDisplay = [];
  if (process.env.REACT_APP_MEMBER_EDIT_CONFIG) {
    fieldsToDisable = JSON.parse(process.env.REACT_APP_MEMBER_EDIT_CONFIG)?.["disable"]?.[organization] ?? [];
    fieldsToDisplay = JSON.parse(process.env.REACT_APP_MEMBER_EDIT_CONFIG)?.["display"]?.[organization] ?? [];
  }

  return (
    <div>
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => setFlag(false)}
        onOpen={() => setFlag(true)}
        open={open}
      >
        <Header icon="edit" color="red" content={operation + " Member Details"} />

        <Modal.Content>
          {validationErrors.length > 0 && (
            <Message error>
              <Message.Header>Error:</Message.Header>
              <Message.List>{setValidationMessages()}</Message.List>
            </Message>
          )}

          <Form className="patientForm" onSubmit={validatePatientDetails} autoComplete="off" error>
            <Form.Group>
              <Form.Field
                id="form-input-control-first-name"
                control={Input}
                label="First Name"
                width={8}
                placeholder="First Name"
                name="firstName"
                value={patientDetails.firstName[0]}
                onChange={handleChange}
                required
                readOnly={operation === "Update" && fieldsToDisable.includes("firstName")}
              />
              <Form.Field
                id="form-input-control-middle-name"
                control={Input}
                width={2}
                label="Middle Name"
                placeholder="Middle Name"
                name="middleName"
                value={patientDetails.middleName[0]}
                onChange={handleChange}
                readOnly={operation === "Update" && fieldsToDisable.includes("middleName")}
              />
              <Form.Field
                id="form-input-control-last-name"
                control={Input}
                width={6}
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                value={patientDetails.lastName[0]}
                onChange={handleChange}
                required
                readOnly={operation === "Update" && fieldsToDisable.includes("lastName")}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field
                id="form-input-control-memberid"
                control={Input}
                label="Member Id"
                name="memberId"
                onChange={handleChange}
                value={patientDetails.memberId[0]}
                required
                readOnly={operation === "Update"}
              />
              {fieldsToDisplay.includes("otherId") && (
                <Form.Field
                  id="form-input-control-otherid"
                  control={Input}
                  label="Other Id"
                  name="otherId"
                  onChange={handleChange}
                  value={patientDetails?.otherId?.[0]}
                />
              )}
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field
                id="form-input-control-organization"
                control={Input}
                label="Organization"
                placeholder="Organization"
                name="org"
                value={patientDetails.org}
                onChange={handleChange}
                readOnly
                required
              />
              <Form.Field
                id="form-input-control-provider"
                control={Input}
                label="Provider"
                placeholder="Provider"
                name="provider"
                value={patientDetails.provider ? patientDetails.provider : ""}
                onChange={handleChange}
              />
              <Form.Field
                id="form-input-control-eligbility-group"
                control={Input}
                label="Eligiblity Group"
                name="eligibligtyGroup"
                value={selectedEligibilityGroup}
                readOnly
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field
                id="form-select-control-gender"
                control={Select}
                options={genderOptions}
                label={{
                  children: "Gender",
                  htmlFor: "form-select-control-gender"
                }}
                placeholder="Gender"
                name="gender"
                value={patientDetails.gender}
                onChange={(e) => handleChange(e, "gender")}
                search
                searchInput={{ id: "form-select-control-gender" }}
              />
              <Form.Field
                id="form-input-control-language"
                control={Input}
                label="Language"
                placeholder="language"
                name="language"
                value={patientDetails.language[0]}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-control-home-phone"
                control={Input}
                label="Home Phone"
                placeholder="Home Phone"
                name="homePhone"
                value={patientDetails.homePhone[0]}
                onChange={handleChange}
              />
              <Form.Field
                id="form-input-control-mobile-phone"
                control={Input}
                label="Mobile Phone"
                placeholder="Mobile Phone"
                name="mobilePhone"
                value={patientDetails.mobilePhone[0]}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <SemanticDatepicker
                required
                autoComplete="false"
                datePickerOnly
                name="dob"
                value={patientDate}
                clearable={false}
                format="MM/DD/YYYY"
                id="form-input-control-dob"
                label="Date Of Birth"
                onChange={(e) => handleChange(e, "dob")}
                className="dobCalendar"
                disabled={operation === "Update" && fieldsToDisable.includes("dob")}
              />

              <Form.Field
                id="form-input-control-email"
                control={Input}
                label="Email"
                placeholder="Email"
                name="email"
                value={patientDetails.email[0]}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Field id="form-input-control-mailingaddress-label" size="large" label="Mailing Address: " />
            </Form.Group>
            <Segment>
              <Form.Group>
                <Form.Field
                  id="form-input-control-addressLine1"
                  control={Input}
                  width={8}
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  name="addressLine1"
                  required
                  value={patientDetails.addressLine1[0]}
                  onChange={handleChange}
                />

                <Form.Field
                  id="form-input-control-addressLine2"
                  control={Input}
                  width={8}
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  name="addressLine2"
                  value={patientDetails.addressLine2[0]}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-city"
                  control={Input}
                  label="City"
                  placeholder="City"
                  name="city"
                  required
                  value={patientDetails.city[0]}
                  onChange={handleChange}
                />

                <Form.Field
                  id="form-select-control-state"
                  control={Select}
                  options={states.list}
                  label={{
                    children: "State",
                    htmlFor: "form-select-control-state"
                  }}
                  placeholder="State"
                  name="state"
                  value={patientDetails.state[0]}
                  onChange={(e) => handleChange(e, "state")}
                  search
                  required
                  searchInput={{ id: "form-select-control-state" }}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-zip"
                  control={Input}
                  label="Zip"
                  placeholder="Zip"
                  name="zip"
                  value={patientDetails.zip[0]}
                  required
                  onChange={handleChange}
                />
                <Form.Field
                  id="form-input-control-country"
                  control={Input}
                  label="Country"
                  placeholder="Country"
                  name="country"
                  value={patientDetails.country[0]}
                  onChange={handleChange}
                  required
                  readOnly
                />
              </Form.Group>
            </Segment>

            <Form.Group>
              <Form.Field>
                <Checkbox
                  checked={displayDeliveryAddress}
                  onChange={(e) => setDisplayDeliveryAddress(!displayDeliveryAddress)}
                  label="Delivery Address same as Mailing Address"
                />
              </Form.Field>
            </Form.Group>
            {!displayDeliveryAddress && (
              <div>
                <Form.Group size="massive">
                  <Form.Field id="form-input-control-mailingaddress-label" size="massive" label="Delivery Address: " />
                </Form.Group>
                <Segment>
                  <Form.Group>
                    <Form.Field
                      id="form-input-control-delivery_addressLine1"
                      control={Input}
                      width={8}
                      label="Address Line 1"
                      placeholder="Address Line 1"
                      name="delivery_addressLine1"
                      required
                      value={patientDetails.delivery_addressLine1[0]}
                      onChange={handleChange}
                    />

                    <Form.Field
                      id="form-input-control-delivery_addressLine2"
                      control={Input}
                      width={8}
                      label="Address Line 2"
                      placeholder="Address Line 2"
                      name="delivery_addressLine2"
                      value={patientDetails.delivery_addressLine2[0]}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="form-input-control-delivery_city"
                      control={Input}
                      label="City"
                      placeholder="City"
                      name="delivery_city"
                      required
                      value={patientDetails.delivery_city[0]}
                      onChange={handleChange}
                    />

                    <Form.Field
                      id="form-select-control-delivery_state"
                      control={Select}
                      options={states.list}
                      label={{
                        children: "State",
                        htmlFor: "form-select-control-state"
                      }}
                      placeholder="State"
                      name="delivery_state"
                      value={patientDetails.delivery_state[0]}
                      onChange={(e) => handleChange(e, "delivery_state")}
                      search
                      required
                      searchInput={{ id: "form-select-control-delivery_state" }}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="form-input-control-delivery_zip"
                      control={Input}
                      label="Zip"
                      placeholder="Zip"
                      name="delivery_zip"
                      value={patientDetails.delivery_zip[0]}
                      required
                      onChange={handleChange}
                    />
                    <Form.Field
                      id="form-input-control-delivery_country"
                      control={Input}
                      label="Country"
                      placeholder="Country"
                      name="delivery_country"
                      value={patientDetails.delivery_country[0]}
                      onChange={handleChange}
                      required
                      readOnly
                    />
                  </Form.Group>
                </Segment>
              </div>
            )}
            <Modal.Actions style={{ textAlign: "center" }}>
              <Button color="red" onClick={() => setFlag(false)}>
                <Icon name="remove" /> Cancel
              </Button>
              <Button color="green" type="submit">
                <Icon name="checkmark" /> {operation}
              </Button>
            </Modal.Actions>
          </Form>
          <Dimmer active={dimmerProcess}>
            <Loader />
          </Dimmer>
          <Confirm
            content="The patient with same member id exsits, are you sure that you still want to create this member?"
            open={confirmOpen}
            closeOnDimmerClick={false}
            onCancel={() => {
              setConfirmOpen(false);
              setDimmerProcess(false);
            }}
            onConfirm={() => {
              setConfirmToCreatePatient(true);
              setConfirmOpen(false);
            }}
            style={{ marginTop: "20%" }}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default Patient;
