import {
  Card,
  Loader,
  Image,
  Label,
  Select,
  Form,
  Container,
  Icon,
  Message,
  Dimmer,
  Grid,
} from 'semantic-ui-react';
import { CancelTask } from './cancel';
import TaskNote from './notes';
import moment from 'moment';

import { useAuth } from "hooks";
import { fhirGet, fhirPut } from "services";

import { useEffect, useState } from 'react';
import useSWR from 'swr';
import * as Helpers from '../../../../utils/Helpers';
import * as FHIRUtility from '../../../../utils/FHIRUtils';
import {
  FHIRUtils,
  ProvenanceBuilder,
} from '../../../../fhir-sdoh';
import { Provenance, Task } from 'fhir/r4';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { get } from 'lodash';
import PatientCDO from 'models/patient';
import { formatPhoneNumber } from 'utils/phone';
import { hooksPatientStatus } from 'utils/care-hooks';

function TaskDetail(props: any) {
  let flatPractitioners = props.flatPractitioners;
  const { token, personId, personName } = useAuth();
  let [loader, setLoader] = useState(false);
  let [saveOption, setSaveOption] = useState(false);
  let [referralId, setReferralId] = useState(null);
  let [errorMessage, setErrorMessage] = useState('');
  const [flatPatient, setFlatPatient] = useState(PatientCDO);
  let practlist = props.practlist;

  useEffect(() => {
    setLoader(true);
    tasksMutate().then((data) => {
      setLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.render, props.taskList]);

  const taskOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onSuccess: (data) => {
      let rid = data?.entry?.[0]?.resource?.groupIdentifier ? data.entry[0].resource.groupIdentifier.value : '';
      setReferralId(rid);
    },
  };

  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onSuccess: (data, key, config) => {
      setFlatPatient(FHIRUtility.parseFHIRPatient(data));
    },
  };

  const { data: tasksData, mutate: tasksMutate } = useSWR(
    props.taskId && [`/Task/?patient=` + (props.patientId ?? props.taskList?.find((t) => t.id === props.taskId)?.for?.reference?.split('/')?.[1]) + `&_id=` + props.taskId, token],
    fhirGet,
    taskOptions
  );
  let taskpayload: Task = tasksData?.entry?.[0]?.resource;
  //TODO move this patient id fetch to a helper function..
  let patientId =
    taskpayload &&
    taskpayload.for &&
    taskpayload.for.reference &&
    taskpayload.for.reference.split('/').length > 1 &&
    taskpayload.for.reference.split('/')[1];

  const { data: patientData, error: patientError } = useSWR(
    patientId && [`/Patient/${patientId}`, token],
    fhirGet,
    options
  );

  const patientName = (
    <>
      {!patientData && !patientError && <Loader active inline />}{' '}
      {patientData &&
        !patientError &&
        patientData.name &&
        patientData.name[0].given[0] + ' ' + patientData.name[0].family}{' '}
      {patientError && 'Error Loading Data'}
    </>
  );

  const patientOrg =
    patientData &&
    patientData.managingOrganization &&
    patientData.managingOrganization.reference &&
    patientData.managingOrganization.reference.split('/')[1];

  const changeStatus = async (newStatus) => {
    //e.preventDefault()
    setLoader(true);
    let oldTaskStatus = taskpayload.status;
    taskpayload.status = newStatus; //e.target.textContent.toLowerCase();
    if (taskpayload?.statusReason) {
      taskpayload.statusReason = {
        text: '',
      };
    }
    let orgCode = patientOrg && patientOrg;
    let orgRef = { code: orgCode && orgCode.toUpperCase(), display: orgCode && orgCode.toLowerCase() };
    if (taskpayload?.reasonCode?.text === 'intervention' && taskpayload.status === 'cancelled') {
      taskpayload.executionPeriod.end = moment().format('YYYY-MM-DD');
    }
    taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);

    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskpayload.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Status has been changed to ${taskpayload.status === 'received' ? 'outreach' : taskpayload.status.toString()
      } on ${taskpayload?.code?.coding?.[0]?.code} Task `,
      orgRef
    );
    //now post bundle request
    await fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      async (response) => {
        await fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
          async (response) => {
            if (taskpayload?.reasonCode?.text === 'intervention' && taskpayload.status === 'cancelled') {
              // Update all 'requested' and 'in-progress' tasks to 'cancelled' when intervention task is 'cancelled'
              let updateTasksNPatientStatusPayload = {
                patientId: patientId,
                status: 'INACTIVE',
                reason: 'DISENROLL',
              };

              await hooksPatientStatus(updateTasksNPatientStatusPayload, token, personName).finally(() => {
                reloadTasks();
                setTimeout(() => {
                  setLoader(false);
                }, 1000);
              });
            } else if (
              taskpayload?.reasonCode?.text === 'intervention' &&
              taskpayload.status === 'in-progress' &&
              oldTaskStatus === 'cancelled'
            ) {
              // Update all not due 'cancelled' tasks to 'requested' when intervention task status is updated from 'cancelled' to 'in-progress'
              let updateTasksNPatientStatusPayload = {
                patientId: patientId,
                status: 'ACTIVE',
                reason: 'REENROLL',
              };

              await hooksPatientStatus(updateTasksNPatientStatusPayload, token, personName).finally(() => {
                reloadTasks();
                setTimeout(() => {
                  setLoader(false);
                }, 1000);
              });
            } else {
              reloadTasks();
              setTimeout(() => {
                setLoader(false);
              }, 1000);
            }
          },
          (response) => {
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            setErrorMessage(response.toJSON()['message']);
          })
      },
      (response) => {
        setTimeout(() => {
          setLoader(false);
        }, 1000);
        setErrorMessage(response.toJSON()['message']);
      }
    );
  };

  const reloadTasks = () => {
    props.tasksMutate();
    tasksMutate();
    props.setRenderURL(true);
  };

  const changeRequest = (e) => {
    e.preventDefault();
    setLoader(true);
    taskpayload.businessStatus = { text: e.target.textContent.toLowerCase() };
    taskpayload.executionPeriod.start = moment(taskpayload.executionPeriod.start).format('YYYY-MM-DD');
    taskpayload.executionPeriod.end = moment(taskpayload.executionPeriod.end).format('YYYY-MM-DD');
    let orgCode = patientOrg && patientOrg;
    let orgRef = { code: orgCode && orgCode.toUpperCase(), display: orgCode && orgCode.toLowerCase() };
    taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);
    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskpayload.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Request has been changed to ` +
      e.target.textContent.toLowerCase() +
      ` on ${taskpayload?.code?.coding?.[0]?.code} Task `,
      orgRef
    );
    //now post bundle request
    fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      (response) => {
        fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
          (response) => {
            props.tasksMutate();
            tasksMutate();
            props.setRenderURL(true);
            setTimeout(() => {
              setLoader(false);
            }, 1000);
          },
          (response) => {
            setLoader(false);
            setErrorMessage(response.toJSON()['message']);
          })
      },
      (response) => {
        setLoader(false);
        setErrorMessage(response.toJSON()['message']);
      }
    );
  };

  const changePriority = (newPrio) => {
    //e.preventDefault();
    let oldPrio = taskpayload.priority;
    setLoader(true);
    taskpayload.priority = newPrio;
    let orgCode = patientOrg && patientOrg;
    let orgRef = { code: orgCode && orgCode.toUpperCase(), display: orgCode && orgCode.toLowerCase() };
    taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);
    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskpayload.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Priority has been changed from ${oldPrio ?? 'no priority'} to ${newPrio}
         on ${taskpayload?.code?.coding?.[0]?.code} Task `,
      orgRef
    );
    //now post bundle request
    fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      (response) => {
        fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
          (response) => {
            props.tasksMutate();
            tasksMutate();
            props.setRenderURL(true);
            setTimeout(() => {
              setLoader(false);
            }, 1000);
          },
          (response) => {
            setLoader(false);
            setErrorMessage(response.toJSON()['message']);
          })
      },
      (response) => {
        setLoader(false);
        setErrorMessage(response.toJSON()['message']);
      }
    );
  };

  const changeAssignee = (e, dropdownprops) => {
    setLoader(true);
    e.preventDefault();
    taskpayload.owner = {
      reference: 'Practitioner/' + dropdownprops.value.toString(),
      display: flatPractitioners.filter((x) => {
        return x.id === dropdownprops.value;
      })[0].fullName,
    };
    let orgCode = patientOrg && patientOrg;
    let orgRef = { code: orgCode && orgCode.toUpperCase(), display: orgCode && orgCode.toLowerCase() };
    taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);
    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskpayload.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Assignee has been changed to ` +
      taskpayload.owner.display.toString() +
      ` on ${taskpayload?.code?.coding?.[0]?.code} Task`,
      orgRef
    );
    //now post bundle request
    fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      (response) => {
        fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
          (response) => {
            props.tasksMutate();
            tasksMutate();
            props.setRenderURL(true);
            setTimeout(() => {
              setLoader(false);
            }, 1000);
          },
          (response) => {
            setLoader(false);
            setErrorMessage(response.toJSON()['message']);
          })
      },
      (response) => {
        setLoader(false);
        setErrorMessage(response.toJSON()['message']);
      }
    );
  };
  const handleChangeDate = (e, semanticprops) => {
    if (
      semanticprops.value === null ||
      moment(taskpayload.executionPeriod.end ?? taskpayload.executionPeriod.start).format('YYYY-MM-DD') ===
      moment(semanticprops.value).format('YYYY-MM-DD')
    ) {
      return false;
    }
    if (taskpayload?.code?.coding?.[0]?.code !== 'automated') {
      if (
        moment(semanticprops.value).format('YYYY-MM-DD') < moment(taskpayload.meta.lastUpdated).format('YYYY-MM-DD') &&
        moment(semanticprops.value).format('YYYY-MM-DD') <
        moment(taskpayload.executionPeriod?.start).format('YYYY-MM-DD') &&
        moment(semanticprops.value).format('YYYY-MM-DD') < moment(taskpayload.executionPeriod?.end).format('YYYY-MM-DD')
      ) {
        //if the selected new due date is smaller than last updated date and both executionPeriod, then we will update the executionPeriod to selected new due date
        taskpayload.executionPeriod.start = moment(semanticprops.value).format('YYYY-MM-DD');
        taskpayload.executionPeriod.end = moment(semanticprops.value).format('YYYY-MM-DD');
      } else if (
        moment(taskpayload.executionPeriod?.start).format('YYYY-MM-DD') >
        moment(semanticprops.value).format('YYYY-MM-DD')
      ) {
        //if both start date and last updated date is greater than the current new due date, then we will set start date as today. Otherwise, we will mark last updated date as start date
        if (
          moment(taskpayload.meta.lastUpdated).format('YYYY-MM-DD') > moment(semanticprops.value).format('YYYY-MM-DD')
        ) {
          taskpayload.executionPeriod.start = moment().format('YYYY-MM-DD');
        } else {
          taskpayload.executionPeriod.start = moment(taskpayload.meta.lastUpdated).format('YYYY-MM-DD');
          taskpayload.executionPeriod.end = moment(semanticprops.value).format('YYYY-MM-DD');
        }
      } else {
        taskpayload.executionPeriod.start = moment(taskpayload.executionPeriod.start).format('YYYY-MM-DD');
        taskpayload.executionPeriod.end = moment(semanticprops.value).format('YYYY-MM-DD');
      }
    }
    //set both execution period the same date for automated tasks
    else if (taskpayload?.code?.coding?.[0]?.code === 'automated') {
      taskpayload.executionPeriod = {
        end: moment(semanticprops.value).format('YYYY-MM-DD'),
        start: moment(semanticprops.value).format('YYYY-MM-DD'),
      };
    }
    taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);
    let orgCode = patientOrg && patientOrg;
    let orgRef = { code: orgCode && orgCode.toUpperCase(), display: orgCode && orgCode.toLowerCase() };
    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskpayload.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Task due date has been changed to ` +
      taskpayload.executionPeriod.end +
      ` on ${taskpayload?.code?.coding?.[0]?.code} Task`,
      orgRef
    );
    //now post bundle request
    setLoader(true);
    fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      (response) => {
        fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
          (response) => {
            props.tasksMutate();
            tasksMutate();
            props.setRenderURL(true);
            setTimeout(() => {
              setLoader(false);
            }, 1000);
          },
          (response) => {
            setLoader(false);
            setErrorMessage(response.toJSON()['message']);
          })
      },
      (response) => {
        setLoader(false);
        setErrorMessage(response.toJSON()['message']);
      }
    );
  };

  const handleReferralId = () => {
    setLoader(true);
    setSaveOption(false);
    taskpayload.groupIdentifier = {
      system: 'https://projectwell.io/fhir/identifiers/referral-id',
      value: referralId,
    };
    //now post bundle request
    fhirPut('/Task/' + taskpayload.id, token, taskpayload).then(() => {
      props.tasksMutate();
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    });
  };

  const changeStatusReason = (e) => {
    e.preventDefault();
    setLoader(true);
    taskpayload.statusReason = { text: e.target.textContent.toLowerCase() };
    let orgCode = patientOrg && patientOrg;
    let orgRef = { code: orgCode && orgCode.toUpperCase(), display: orgCode && orgCode.toLowerCase() };
    taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);
    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskpayload.id!}`,
      { reference: 'Practitioner/' + personId, display: personName }, //eg. {Practitioner/oa2,johnsmith},
      patientId,
      'UPDATE',
      `Status Reason has been changed to ` +
      taskpayload.statusReason.text.toString() +
      ` on ${taskpayload?.code?.coding?.[0]?.code} Task`,
      orgRef
    );
    //now post bundle request
    fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      (response) => {
        fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
          (response) => {
            props.tasksMutate();
            tasksMutate();
            props.setRenderURL(true);
            setTimeout(() => {
              setLoader(false);
            }, 1000);
          },
          (response) => {
            setLoader(false);
            setErrorMessage(response.toJSON()['message']);
          })
      },
      (response) => {
        setLoader(false);
        setErrorMessage(response.toJSON()['message']);
      }
    );
  };
  let numTasks = props.taskList && props.taskList.length;
  return (
    <>
      <Dimmer active={loader} inverted>
        <Loader inverted />
      </Dimmer>

      {taskpayload && numTasks > 0 && (
        !props.activity
          ? <>
            <Grid columns={2} divided>
              <Grid.Column width={11} style={{ maxHeight: '80vh', minHeight: '65vh', overflow: 'auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    <h4>
                      <Icon name="newspaper" size="large" color="orange" style={{ float: 'left', display: 'flex' }} /> TASK
                      DETAIL{' '}
                    </h4>
                  </span>
                  {props.activity && (
                    <div>
                      <Label style={{ color: 'black' }} as="a" color="teal" image>
                        <Image circular size="mini" src={'../../../images/' + Helpers.getImage(true)} />

                        {patientName}
                        <Label.Detail>{flatPatient?.memberId}</Label.Detail>
                        {flatPatient?.language?.[0] ? <Label.Detail>{flatPatient?.language[0]}</Label.Detail> : ''}
                        {flatPatient?.provider ? <Label.Detail>{flatPatient?.provider}</Label.Detail> : ''}
                        {flatPatient?.dob?.length > 0 ? <Label.Detail>{flatPatient?.dob}</Label.Detail> : ''}
                        {flatPatient?.mobilePhone?.length > 0 ? (
                          <Label.Detail>{formatPhoneNumber(flatPatient?.mobilePhone?.[0])}</Label.Detail>
                        ) : (
                          ''
                        )}
                      </Label>
                    </div>
                  )}
                </div>
                <Card fluid>
                  <Card.Content>
                    <Card.Header>
                      {taskpayload.description}
                      {taskpayload.status !== 'cancelled' && (
                        <CancelTask
                          tasksMutate={props.tasksMutate}
                          patientId={patientId}
                          patientOrg={patientOrg}
                          setLoader={setLoader}
                          taskpayload={taskpayload}
                          setErrorMessage={setErrorMessage}
                        />
                      )}
                    </Card.Header>
                    <Card.Meta style={{ fontSize: '16px' }}>{taskpayload.reasonCode.text}</Card.Meta>
                  </Card.Content>
                </Card>
                <Card fluid>
                  <Card.Content>
                    {taskpayload && (
                      <Card.Description>
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>
                            <Form autoComplete="off">
                              <Form.Group>
                                <Form.Field
                                  style={{ color: 'black' }}
                                  control={SemanticDatepicker}
                                  default
                                  label="Due Date"
                                  placeholder={
                                    taskpayload.executionPeriod &&
                                    moment(taskpayload.executionPeriod.end ?? taskpayload.executionPeriod.start)
                                      .local(true)
                                      .format('YYYY-MM-DD')
                                      .toString()
                                  }
                                  onChange={(e, data) => handleChangeDate(e, data)}
                                  clearable={false}
                                  value={
                                    taskpayload.executionPeriod &&
                                    moment(taskpayload.executionPeriod.end ?? taskpayload.executionPeriod.start)
                                      .local(true)
                                      .toDate()
                                  }
                                  size="small"
                                />
                                <Form.Field
                                  name="owner"
                                  label="OWNER:"
                                  selectOnBlur={false}
                                  control={Select}
                                  search
                                  key="owner"
                                  placeholder="Care Team"
                                  value={taskpayload.owner ? taskpayload.owner.reference?.split('/')[1] : null}
                                  options={practlist}
                                  onChange={changeAssignee}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Input
                                  name="referralId"
                                  label="REFERRAL ID:"
                                  value={referralId}
                                  type="text"
                                  placeholder="Enter Referral ID"
                                  onChange={(e) => {
                                    setSaveOption(true);
                                    setReferralId(e.target.value);
                                  }}
                                />
                                {saveOption && (
                                  <Form.Button size="tiny" color="blue" content="Save" onClick={handleReferralId} />
                                )}
                                <span style={{ width: '7px' }}></span>
                                <Form.Field
                                  name="status"
                                  data-testid="status"
                                  label="STATUS:"
                                  selectOnBlur={false}
                                  control={Select}
                                  search
                                  key="status"
                                  placeholder="CANCELLED"
                                  value={taskpayload.status}
                                  options={[
                                    { text: 'REQUESTED', key: 'requested', value: 'requested' },
                                    { text: 'IN-PROGRESS', key: 'in-progress', value: 'in-progress' },
                                    { text: 'COMPLETED', key: 'completed', value: 'completed' },
                                    { text: 'FAILED', key: 'failed', value: 'failed' },
                                    { text: 'CANCELLED', key: 'cancelled', value: 'cancelled' },
                                    { text: 'ON-HOLD', key: 'paused', value: 'on-hold' },
                                    { text: 'OUTREACH', key: 'outreach', value: 'received' },
                                  ]}
                                  onChange={(e, { value }) => changeStatus(value)}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Field
                                  name="priority"
                                  data-testid="priority"
                                  label="PRIORITY:"
                                  selectOnBlur={false}
                                  control={Select}
                                  search
                                  key="priority"
                                  placeholder="N/A"
                                  value={taskpayload.priority ?? 'ROUTINE'}
                                  options={[
                                    { text: 'STANDARD', key: 'routine', value: 'routine' },
                                    /*{ text: 'URGENT', key: 'urgent', value: 'urgent' },*/
                                    { text: 'HIGH PRIORITY', key: 'asap', value: 'asap' },
                                    /*{ text: 'STAT', key: 'stat', value: 'stat' },*/
                                  ]}
                                  onChange={(e, { value }) => changePriority(value)}
                                />
                              </Form.Group>
                              <Form.Group>
                                {/*Conditional components*/}
                                {taskpayload.code &&
                                  taskpayload.code.coding &&
                                  taskpayload.code.coding[0] &&
                                  taskpayload.code.coding[0].code === 'triage' && (
                                    <Form.Field
                                      name="request"
                                      label="REQUEST:"
                                      selectOnBlur={false}
                                      control={Select}
                                      search
                                      key="request"
                                      placeholeder="No requests"
                                      value={taskpayload.businessStatus && taskpayload.businessStatus.text}
                                      options={[
                                        { text: 'RESHIP', key: 'reship', value: 'reship' },
                                        { text: 'BONUS PACK', key: 'bonus-pack', value: 'bonus pack' },
                                        { text: 'OTHER', key: 'other', value: 'other' },
                                      ]}
                                      onChange={changeRequest}
                                    />
                                  )}
                                {get(taskpayload, `taskpayload.code.coding[0].code`) === 'outreach' && (
                                  <Form.Field
                                    name="request"
                                    label="REQUEST:"
                                    selectOnBlur={false}
                                    control={Select}
                                    search
                                    key="request"
                                    placeholeder="No requests"
                                    value={taskpayload.businessStatus && taskpayload.businessStatus.text}
                                    options={[
                                      { text: 'RESHIP', key: 'reship', value: 'reship' },
                                      { text: 'BONUS PACK', key: 'bonus-pack', value: 'bonus pack' },
                                      { text: 'OTHER', key: 'other', value: 'other' },
                                    ]}
                                    onChange={changeRequest}
                                  />
                                )}
                                {['cancelled', 'on-hold', 'paused'].includes(taskpayload.status) && (
                                  <Form.Field
                                    name="statusReason"
                                    label="STATUS REASON:"
                                    selectOnBlur={false}
                                    control={Select}
                                    search
                                    key="statusReason"
                                    placeholder="SELECT REASON"
                                    value={taskpayload.statusReason && taskpayload.statusReason.text}
                                    options={[
                                      { text: 'CHANGED INSURANCE', key: 'changed-insurance', value: 'changed insurance' },
                                      { text: 'DECEASED', key: 'deceased', value: 'deceased' },
                                      { text: 'DECLINED', key: 'Declined', value: 'declined' },
                                      { text: 'HOSPITALIZED', key: 'Hospitalized', value: 'hospitalized' },
                                      { text: 'LOCATION CHANGE', key: 'location-change', value: 'location change' },
                                      {
                                        text: 'MEMBER DIS-ENROLLED',
                                        key: 'Member Dis-enrolled',
                                        value: 'member dis-enrolled',
                                      },
                                      { text: 'NO PHONE NUMBER', key: 'no-phone-number', value: 'no phone number' },
                                      { text: 'NRx DIS-ENROLLED', key: 'NRx Dis-enrolled', value: 'nrx dis-enrolled' },
                                      { text: 'WRONG PHONE NUMBER', key: 'wrong-phone-number', value: 'wrong phone number' },
                                      { text: 'SILENT MEMBER', key: 'silent-member', value: 'silent member' },
                                    ]}
                                    onChange={changeStatusReason}
                                  />
                                )}
                                {['received'].includes(taskpayload.status) && (
                                  <Form.Field
                                    name="outreachReason"
                                    label="OUTREACH REASON:"
                                    data-testid="outreachReason"
                                    selectOnBlur={false}
                                    control={Select}
                                    search
                                    key="statusReason"
                                    placeholder="SELECT REASON"
                                    value={taskpayload.statusReason && taskpayload.statusReason.text}
                                    options={[
                                      {
                                        text: 'CAll BACK REQUESTED',
                                        key: 'call-back-requested',
                                        value: 'call back requested',
                                      },
                                      {
                                        text: 'DID NOT ANSWER/NO VM',
                                        key: 'did-not-answer/no-vm',
                                        value: 'did not answer/no vm',
                                      },
                                      {
                                        text: 'INCORRECT PHONE NUMBER',
                                        key: 'incorrect-phone-number',
                                        value: 'incorrect phone number',
                                      },
                                      {
                                        text: 'INTAKE SCHEDULED',
                                        key: 'intake-scheduled',
                                        value: 'intake scheduled',
                                      },
                                      { text: 'NO PHONE NUMBER', key: 'no-phone-number', value: 'no phone number' },
                                      { text: 'VOICEMAIL', key: 'voicemail', value: 'voicemail' },
                                    ]}
                                    onChange={changeStatusReason}
                                  />
                                )}
                              </Form.Group>
                              {errorMessage && (
                                <Message size="small" content={errorMessage} negative onDismiss={() => setErrorMessage('')} />
                              )}
                            </Form>
                          </span>
                        </span>
                      </Card.Description>
                    )}
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column width={3} stretched >
                <TaskNote
                  taskpayload={taskpayload}
                  flatPractitioners={props.flatPractitioners}
                  refresh={props.tasksMutate}
                  patientId={patientData?.id}
                  orgId={patientOrg}
                  setLoader={setLoader}
                  loader={loader}
                  activity={props.activity}
                />
              </Grid.Column>
            </Grid>
          </>
          : <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                <h4>
                  <Icon name="newspaper" size="large" color="orange" style={{ float: 'left', display: 'flex' }} /> TASK
                  DETAIL{' '}
                </h4>
              </span>
              {props.activity && (
                <div>
                  <Label style={{ color: 'black' }} as="a" color="teal" image>
                    <Image circular size="mini" src={'../../../images/' + Helpers.getImage(true)} />

                    {patientName}
                    <Label.Detail>{flatPatient?.memberId}</Label.Detail>
                    {flatPatient?.language?.[0] ? <Label.Detail>{flatPatient?.language[0]}</Label.Detail> : ''}
                    {flatPatient?.provider ? <Label.Detail>{flatPatient?.provider}</Label.Detail> : ''}
                    {flatPatient?.dob?.length > 0 ? <Label.Detail>{flatPatient?.dob}</Label.Detail> : ''}
                    {flatPatient?.mobilePhone?.length > 0 ? (
                      <Label.Detail>{formatPhoneNumber(flatPatient?.mobilePhone?.[0])}</Label.Detail>
                    ) : (
                      ''
                    )}
                  </Label>
                </div>
              )}
            </div>
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  {taskpayload.description}
                  {taskpayload.status !== 'cancelled' && (
                    <CancelTask
                      tasksMutate={props.tasksMutate}
                      patientId={patientId}
                      patientOrg={patientOrg}
                      setLoader={setLoader}
                      taskpayload={taskpayload}
                      setErrorMessage={setErrorMessage}
                    />
                  )}
                </Card.Header>
                <Card.Meta style={{ fontSize: '16px' }}>{taskpayload.reasonCode.text}</Card.Meta>
              </Card.Content>
            </Card>

            <Card fluid>
              <Card.Content>
                {taskpayload && (
                  <Card.Description>
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        <Form autoComplete="off">
                          <Form.Group>
                            <Form.Field
                              style={{ color: 'black' }}
                              control={SemanticDatepicker}
                              default
                              label="Due Date"
                              placeholder={
                                taskpayload.executionPeriod &&
                                moment(taskpayload.executionPeriod.end ?? taskpayload.executionPeriod.start)
                                  .local(true)
                                  .format('YYYY-MM-DD')
                                  .toString()
                              }
                              onChange={(e, data) => handleChangeDate(e, data)}
                              clearable={false}
                              value={
                                taskpayload.executionPeriod &&
                                moment(taskpayload.executionPeriod.end ?? taskpayload.executionPeriod.start)
                                  .local(true)
                                  .toDate()
                              }
                              size="small"
                            />
                            <Form.Field
                              name="owner"
                              label="OWNER:"
                              selectOnBlur={false}
                              control={Select}
                              search
                              key="owner"
                              placeholder="Care Team"
                              value={taskpayload.owner ? taskpayload.owner.reference?.split('/')[1] : null}
                              options={practlist}
                              onChange={changeAssignee}
                            />
                            <Form.Field
                              name="priority"
                              data-testid="priority"
                              label="PRIORITY:"
                              selectOnBlur={false}
                              control={Select}
                              search
                              key="priority"
                              placeholder="N/A"
                              value={taskpayload.priority ?? 'ROUTINE'}
                              options={[
                                { text: 'STANDARD', key: 'routine', value: 'routine' },
                                /*{ text: 'URGENT', key: 'urgent', value: 'urgent' },*/
                                { text: 'HIGH PRIORITY', key: 'asap', value: 'asap' },
                                /*{ text: 'STAT', key: 'stat', value: 'stat' },*/
                              ]}
                              onChange={(e, { value }) => changePriority(value)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Input
                              name="referralId"
                              label="REFERRAL ID:"
                              value={referralId}
                              type="text"
                              placeholder="Enter Referral ID"
                              onChange={(e) => {
                                setSaveOption(true);
                                setReferralId(e.target.value);
                              }}
                            />
                            {saveOption && (
                              <Form.Button size="tiny" color="blue" content="Save" onClick={handleReferralId} />
                            )}
                            <span style={{ width: '7px' }}></span>
                            <Form.Field
                              name="status"
                              data-testid="status"
                              label="STATUS:"
                              selectOnBlur={false}
                              control={Select}
                              search
                              key="status"
                              placeholder="CANCELLED"
                              value={taskpayload.status}
                              options={[
                                { text: 'REQUESTED', key: 'requested', value: 'requested' },
                                { text: 'IN-PROGRESS', key: 'in-progress', value: 'in-progress' },
                                { text: 'COMPLETED', key: 'completed', value: 'completed' },
                                { text: 'FAILED', key: 'failed', value: 'failed' },
                                { text: 'CANCELLED', key: 'cancelled', value: 'cancelled' },
                                { text: 'ON-HOLD', key: 'paused', value: 'on-hold' },
                                { text: 'OUTREACH', key: 'outreach', value: 'received' },
                              ]}
                              onChange={(e, { value }) => changeStatus(value)}
                            />
                          </Form.Group>
                          <Form.Group>
                            {/*Conditional components*/}
                            {taskpayload.code &&
                              taskpayload.code.coding &&
                              taskpayload.code.coding[0] &&
                              taskpayload.code.coding[0].code === 'triage' && (
                                <Form.Field
                                  name="request"
                                  label="REQUEST:"
                                  selectOnBlur={false}
                                  control={Select}
                                  search
                                  key="request"
                                  placeholeder="No requests"
                                  value={taskpayload.businessStatus && taskpayload.businessStatus.text}
                                  options={[
                                    { text: 'RESHIP', key: 'reship', value: 'reship' },
                                    { text: 'BONUS PACK', key: 'bonus-pack', value: 'bonus pack' },
                                    { text: 'OTHER', key: 'other', value: 'other' },
                                  ]}
                                  onChange={changeRequest}
                                />
                              )}

                            {get(taskpayload, `taskpayload.code.coding[0].code`) === 'outreach' && (
                              <Form.Field
                                name="request"
                                label="REQUEST:"
                                selectOnBlur={false}
                                control={Select}
                                search
                                key="request"
                                placeholeder="No requests"
                                value={taskpayload.businessStatus && taskpayload.businessStatus.text}
                                options={[
                                  { text: 'RESHIP', key: 'reship', value: 'reship' },
                                  { text: 'BONUS PACK', key: 'bonus-pack', value: 'bonus pack' },
                                  { text: 'OTHER', key: 'other', value: 'other' },
                                ]}
                                onChange={changeRequest}
                              />
                            )}
                            {['cancelled', 'on-hold', 'paused'].includes(taskpayload.status) && (
                              <Form.Field
                                name="statusReason"
                                label="STATUS REASON:"
                                selectOnBlur={false}
                                control={Select}
                                search
                                key="statusReason"
                                placeholder="SELECT REASON"
                                value={taskpayload.statusReason && taskpayload.statusReason.text}
                                options={[
                                  { text: 'CHANGED INSURANCE', key: 'changed-insurance', value: 'changed insurance' },
                                  { text: 'DECEASED', key: 'deceased', value: 'deceased' },
                                  { text: 'DECLINED', key: 'Declined', value: 'declined' },
                                  { text: 'HOSPITALIZED', key: 'Hospitalized', value: 'hospitalized' },
                                  { text: 'LOCATION CHANGE', key: 'location-change', value: 'location change' },
                                  {
                                    text: 'MEMBER DIS-ENROLLED',
                                    key: 'Member Dis-enrolled',
                                    value: 'member dis-enrolled',
                                  },
                                  { text: 'NO PHONE NUMBER', key: 'no-phone-number', value: 'no phone number' },
                                  { text: 'NRx DIS-ENROLLED', key: 'NRx Dis-enrolled', value: 'nrx dis-enrolled' },
                                  { text: 'WRONG PHONE NUMBER', key: 'wrong-phone-number', value: 'wrong phone number' },
                                  { text: 'SILENT MEMBER', key: 'silent-member', value: 'silent member' },
                                ]}
                                onChange={changeStatusReason}
                              />
                            )}
                            {['received'].includes(taskpayload.status) && (
                              <Form.Field
                                name="outreachReason"
                                label="OUTREACH REASON:"
                                data-testid="outreachReason"
                                selectOnBlur={false}
                                control={Select}
                                search
                                key="statusReason"
                                placeholder="SELECT REASON"
                                value={taskpayload.statusReason && taskpayload.statusReason.text}
                                options={[
                                  {
                                    text: 'CAll BACK REQUESTED',
                                    key: 'call-back-requested',
                                    value: 'call back requested',
                                  },
                                  {
                                    text: 'DID NOT ANSWER/NO VM',
                                    key: 'did-not-answer/no-vm',
                                    value: 'did not answer/no vm',
                                  },
                                  {
                                    text: 'INCORRECT PHONE NUMBER',
                                    key: 'incorrect-phone-number',
                                    value: 'incorrect phone number',
                                  },
                                  {
                                    text: 'INTAKE SCHEDULED',
                                    key: 'intake-scheduled',
                                    value: 'intake scheduled',
                                  },
                                  { text: 'NO PHONE NUMBER', key: 'no-phone-number', value: 'no phone number' },
                                  { text: 'VOICEMAIL', key: 'voicemail', value: 'voicemail' },
                                ]}
                                onChange={changeStatusReason}
                              />
                            )}
                          </Form.Group>
                          {errorMessage && (
                            <Message size="small" content={errorMessage} negative onDismiss={() => setErrorMessage('')} />
                          )}
                        </Form>
                      </span>
                    </span>
                  </Card.Description>
                )}
              </Card.Content>

              <Card.Content
                description={
                  <TaskNote
                    taskpayload={taskpayload}
                    flatPractitioners={props.flatPractitioners}
                    refresh={props.tasksMutate}
                    patientId={patientData?.id}
                    orgId={patientOrg}
                    setLoader={setLoader}
                    loader={loader}
                    activity={props.activity}
                  />
                }
              />
            </Card>
          </>
      )}
      {!taskpayload && props.taskId && <Loader active />}
      {props.updatingTask && <Loader active />}
      {(!props.taskId || numTasks === 0) && (
        <Container as="h4" textAlign="center" style={{ opacity: '70%' }}>
          Please Select a Task
        </Container>
      )}
    </>
  );
}

export default TaskDetail;
