import { useEffect, useState } from "react";
import { Loader, Dropdown, Form } from "semantic-ui-react";
import { get } from "lodash";
import { QuestionnaireExtensionHelper, QuestionnaireHelper } from "utils/Helpers";
import { useOrganizations } from "hooks";

export default function QuestionnairesList(props: any) {
  const { selectedEligibilityGroup } = useOrganizations();
  const [questionnaireTypesCB, setQuestionnaireTypesCB] = useState([]); //CareBetty surveys

  useEffect(() => {
    // limit surveys by org
    let orgSurveys = props.questionnaireData.entry.filter((questionnaire) => {
      if (questionnaire.resource.useContext) {
        let orgMatch = false;
        for (let i = 0; i < questionnaire.resource.useContext.length; i++) {
          if (questionnaire.resource.useContext[i].code.code === "program") {
            if (
              get(questionnaire, `resource.useContext[${i}].valueCodeableConcept.text`) === props.org ||
              get(questionnaire, `resource.useContext[${i}].valueCodeableConcept.text`)?.toUpperCase() === "ALL"
            ) {
              orgMatch = true;
            } else {
              //orgMatch = false; Keep set to false, do nothing
            }
          }
        }
        return orgMatch;
      } else {
        return true;
      }
    });

    // TEMP - BCNC, hide 2025 survey for 2024 members
    if (props.org.includes("BCNC") && selectedEligibilityGroup.includes("2024")) {
      orgSurveys = orgSurveys.filter((questionnaire) => !questionnaire.resource.id.includes("2025"));
    }

    // limit surveys by workflow - PAYOR - includes ones w/o workflow
    let surveysCB = orgSurveys.filter((questionnaire) => {
      if (questionnaire.resource.useContext) {
        let orgMatch = false;
        for (let i = 0; i < questionnaire.resource.useContext.length; i++) {
          if (questionnaire.resource.useContext[i].code.code === "workflow") {
            if (questionnaire.resource.useContext[i].valueCodeableConcept.text.includes("PAYOR")) {
              orgMatch = true;
            } // else return false;
          }
        }
        return orgMatch;
      } else {
        return true;
      }
    });

    //all surveys with inactive and active status used for survey dropdown filter
    let typesCB = surveysCB.map((option) => {
      let QHelper = new QuestionnaireHelper(option.resource);
      let QExtHelper = new QuestionnaireExtensionHelper(QHelper.getSurveyExtension());
      return {
        key: option.resource.id,
        text: QExtHelper.getInternalTitle() ?? option.resource.title,
        value: option.resource.title
      };
    });

    //disable the pii survey selection
    let addTypesCBPIIIdendifier = surveysCB
      .filter((questionnaire) => questionnaire?.resource?.status === "active")
      .map((option) => {
        let QHelper = new QuestionnaireHelper(option.resource);
        let QExtHelper = new QuestionnaireExtensionHelper(QHelper.getSurveyExtension());
        let obj = {
          key: option.resource.id,
          text: QExtHelper.getInternalTitle() ?? option.resource.title,
          value: option.resource.title
        };
        return obj;
      });

    setQuestionnaireTypesCB(addTypesCBPIIIdendifier); // CB dropdown list

    // limit surveys by workflow - MEMBER - does NOT include ones w/o workflow
    let surveysMA = orgSurveys.filter((questionnaire) => {
      if (questionnaire.resource.useContext) {
        let orgMatch = false;
        for (let i = 0; i < questionnaire.resource.useContext.length; i++) {
          if (questionnaire.resource.useContext[i].code.code === "workflow") {
            if (questionnaire.resource.useContext[i].valueCodeableConcept.text.includes("MEMBER")) {
              orgMatch = true;
            } // else return false;
          }
        }
        return orgMatch;
      } else {
        return false;
      }
    });

    let typesMA = surveysMA.map((option) => {
      let QHelper = new QuestionnaireHelper(option.resource);
      let QExtHelper = new QuestionnaireExtensionHelper(QHelper.getSurveyExtension());
      return {
        key: option.resource.id,
        text: QExtHelper.getInternalTitle() ?? option.resource.title,
        value: option.resource.title
      };
    });

    let typesOrg = [{ key: "Food Rating", text: "Food Rating", value: "Food Rating" }, ...typesMA, ...typesCB];
    const unique = typesOrg.filter((obj, index) => typesOrg.findIndex((item) => item.key === obj.key) === index);
    props.setQuestionnaireTypesOrg(unique); // QR filter

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.org, props.questionnaireData.entry]);

  const onSetDropdown = (e: any) => {
    let filteredTableData;
    for (let key in questionnaireTypesCB) {
      if (questionnaireTypesCB[key].text === e.target.textContent) {
        filteredTableData = props.questionnaireData.entry.filter((x) => {
          return x.resource.id === questionnaireTypesCB[key].key;
        })[0].resource;
        filteredTableData.title && props.handleTranslation(filteredTableData.title.toString())
          ? props.setContainsLang(true)
          : props.setContainsLang(false);
      }
    }
    props.setCurrentQuestionnaire(filteredTableData);
    props.setCurrentQuestionnaireResponse(undefined);
  };

  /* const handleWebSurvey = (e: any) => {
    let filteredTableData = {};
    for (let key in questionnaireTypesMA) {
      if (questionnaireTypesMA[key].text === e.target.textContent) {
        filteredTableData = props.questionnaireData.entry.filter((x) => {
          return x.resource.id === questionnaireTypesMA[key].key;
        })[0].resource;
      }
    }
    webSurvey = filteredTableData;
  };

  const handleWebSurveyMedium = (e: any) => {
    webSurveyMedium = e.target.textContent;
  }; */

  /* const webClick = (s: any, type: any) => {
    setErrorMessage("Oops! Something went wrong..");
    try {
      if (s === "") throw new Error("Unsupported action");
      let body = {};
      switch (type) {
        case "Email":
          if (!props.patientEmail[0]) {
            setErrorMessage("Missing Patient Email");
            throw new Error("Missing Information");
          }
          body = {
            patientId: props.patientId,
            memberName: props.patientFirstName,
            sender: `${authInfo.practitionerName}`,
            email: props.patientEmail,
            emailTemplate: "d-285bf9513f8a4c6ebe0821a5e39fbbfb",
            subject: s["title"],
            questionnaireId: s["id"],
            text: "web based survey",
          };
          break;
        case "Text":
          if (!props.patientPhoneNumber[0]) {
            setErrorMessage("Missing Patient Mobile Phone Number");
            throw new Error("Missing Information");
          }
          body = {
            patientId: props.patientId,
            sender: `${authInfo.practitionerName}`,
            phoneNumber: "+1" + props.patientPhoneNumber,
            subject:
              s["title"] === "Terms and Conditions - healthAlign"
                ? "Please agree to the NourishedRx Terms and Conditions by clicking below:"
                : "DO NOT REPLY. Please answer your " + s["title"] + " survey.", //Added no reply message for members to not text back
            questionnaireId: s["id"],
            emailTitle: s["title"],
            text: "web based survey",
          };
          break;
        default:
          throw new Error("Unsupported action");
      }

      HooksPoster(`/hooks/web-survey`, authInfo.token, body).then((data) => {
        console.log(data.message);
      });
      setLaunchStatus(messageStatus.success);
      //probably should move where this cleaning is handled
      webSurveyMedium = "";
      webSurvey = "";
    } catch (e) {
      console.error(e);
      e.message === "Missing Information"
        ? setLaunchStatus(messageStatus.warning)
        : setLaunchStatus(messageStatus.error);
      webSurveyMedium = "";
      webSurvey = "";
    }
  };

  const textClick = (s: any) => {
    setErrorMessage("Oops! Something went wrong..");
    try {
      if (!props.patientPhoneNumber[0]) {
        setErrorMessage("Missing Patient Mobile Phone Number");
        throw new Error("Missing Information");
      }
      let filteredTableData = {};
      for (let key in questionnaireTypesMA) {
        if (questionnaireTypesMA[key].text === s.target.textContent) {
          filteredTableData = props.questionnaireData.entry.filter((x) => {
            return x.resource.id === questionnaireTypesMA[key].key;
          })[0].resource;
        }
      }
      let body = {
        patientId: props.patientId,
        sender: `${authInfo.practitionerName}`,
        phoneNumber: "+1" + props.patientPhoneNumber,
        subject: filteredTableData["title"],
        questionnaireId: filteredTableData["id"],
        text: "web based survey",
      };

      HooksPoster(`/hooks/text-survey`, authInfo.token, body).then((data) =>
        console.log(data.message)
      );
    } catch (e) {
      console.error(e);
    }
  }; */

  return (
    <>
      <div>
        {!props.questionnaireData && <Loader active inline="centered" />}{" "}
        {props.questionnaireData && (
          <span>
            <Form>
              <Form.Group>
                <div style={{ width: "10px" }} />

                <Dropdown
                  pointing="left"
                  color="blue"
                  icon="write"
                  button
                  className="icon cb_primarybutton"
                  text="Take New Survey &nbsp;"
                >
                  <Dropdown.Menu>
                    <Dropdown.Menu onClick={(e) => onSetDropdown(e)} scrolling>
                      {questionnaireTypesCB.map((option) => (
                        <Dropdown.Item key={option.key} {...option} />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  control={"select"}
                  style={{ marginLeft: "1%", maxHeight: "3px" }}
                  scrolling
                  selection
                  placeholder={!props.containsLang ? "English (US)" : "Language"}
                  disabled={!props.containsLang}
                  compact
                  options={props.langsList}
                  value={!props.containsLang ? "English (US)" : props.language}
                  onChange={props.handleLanguageSelect}
                />
              </Form.Group>
            </Form>
          </span>
        )}
      </div>
    </>
  );
}
