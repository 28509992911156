import { useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { TextArea, Form, Select, Loader, Dimmer, Button, Modal, Checkbox, Message } from 'semantic-ui-react';
import { FHIRUtils, FlagBuilder, ProvenanceBuilder, TaskBuilder } from '../../../../fhir-sdoh';
import { useAuth } from "hooks";
import { Flag, Provenance } from 'fhir/r4';
import moment from 'moment';
import { toInteger } from 'lodash';
import { fhirPut } from 'services';

function AddTask(props: any) {
  const blankPayload = {
    identifiers: [],
    code: { display: '', code: '' },
    description: '',
    priority: '',
    forRef: '',
    basedOnRefs: [],
    focusRef: '',
    reason: null,
    note: '',
    partofRef: '',
    startDate: '',
    endDate: '',
    ownerRef: { display: '', reference: '' },
    restriction: null,
  };
  let [taskDetails, setTaskDetails] = useState(blankPayload);
  let [formCode, setFormCode] = useState(null);
  let [formPriorirty, setFormPriority] = useState(null);
  let [formStartDate, setFormStartDate] = useState(null);
  let [formEndDate, setFormEndDate] = useState(null);
  let [formDate, setFormDate] = useState(null);
  let [formDescription, setFormDescription] = useState('');
  let [formOwner, setFormOwner] = useState(null);
  let [recurringInterval, setRecurringInterval] = useState(null);
  let flatPractitioners = props.flatPractitioners;
  let [dimmerprocess, setDimmerProcess] = useState<boolean>(false);
  let [recurring, setRecurring] = useState<boolean>(false);

  const { token, personId, personName } = useAuth();
  let practitionerName = personName;
  let practitionerId = personId;

  let codeDisplays = {
    survey: 'Conduct a Questionnaire',
    'food-delivery': 'Meal Delivery',
    'food-card': 'Food Card Delivery',
    consultation: 'Consultation',
    education: 'Send Educational Material',
    touchpoint: 'Member Touchpoint',
    communication: 'Member Engagement',
    care: 'Care Management task',
    'food-box': 'Food Box',
    'food-recall': 'Food Recall',
    triage: 'Triage',
    outreach: 'Outreach',
  };
  const handleChange = (e, fieldprops) => {
    let newTaskDetails = taskDetails;
    newTaskDetails.startDate = moment(new Date()).format('YYYY-MM-DD');
    if (fieldprops.name === 'endDate') {
      newTaskDetails[fieldprops.name] = moment(fieldprops.value).format('YYYY-MM-DD');
      setFormEndDate(moment(fieldprops.value).format('YYYY-MM-DD'));
      setFormDate(fieldprops.value);
    } else if (fieldprops.name === 'code') {
      newTaskDetails[fieldprops.name] = {
        code: fieldprops.value,
        display: codeDisplays[fieldprops.value],
      };
      setFormCode(fieldprops.value);
    } else if (fieldprops.name === 'priority') {
      newTaskDetails[fieldprops.name] = fieldprops.value;
      setFormPriority(fieldprops.value);
    } else if (fieldprops.name === 'ownerRef') {
      newTaskDetails[fieldprops.name] = {
        reference: 'Practitioner/' + fieldprops.value.toString(),
        display: flatPractitioners.filter((x) => {
          return x.id === fieldprops.value;
        })[0].fullName,
      };
      setFormOwner(fieldprops.value);
    } else if (fieldprops.name === 'description') {
      newTaskDetails[fieldprops.name] = fieldprops.value;
      setFormDescription(fieldprops.value);
    }
    setTaskDetails({ ...taskDetails, ...newTaskDetails });
  };

  const submitTask = (e: any) => {
    e.preventDefault();
    setDimmerProcess(true);
    let orgCode = props.orgCode;
    let orgRef = {
      code: orgCode.toUpperCase(),
      display: orgCode.toLowerCase(),
    };
    let taskpayload = TaskBuilder.build(
      taskDetails.code,
      null,
      taskDetails.description, // formDescription
      'Patient/' + props.patientId.toString(), //eg: Patient/patient
      ['Organization/' + orgCode], //eg: ServiceRequest/exampel
      null, //eg: Questionnaire/example
      taskDetails.reason,
      taskDetails.note,
      null, //parent taskId
      taskDetails.startDate,
      taskDetails.endDate,
      taskDetails.ownerRef,
      personName,
      taskDetails.restriction ? taskDetails.restriction.recipient : null,
      null,
      null,
      taskDetails.priority === 'asap' ? 'asap' : 'routine'
    );
    taskpayload.requester = {
      reference: 'Practitioner/' + practitionerId.toString(),
      display: practitionerName,
    };
    taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);
    let flagpayload: Flag = FlagBuilder.build(
      props.patientId.toString(),
      personId,
      { code: 'admin', display: 'administrative' },
      { code: 'ALERT', display: 'Alert' },
      taskpayload.code.coding[0].code.toString().toUpperCase() +
        ' related task created and assigned to you by ' +
        personName,
      personName,
      orgCode,
      taskpayload.owner.reference.split('/')[1],
      'active',
      taskpayload.id
    );
    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Task/${taskpayload.id!}`,
      {
        reference: 'Practitioner/' + personId,
        display: practitionerName,
      }, //eg. {Practitioner/oa2,johnsmith},
      props.patientId.toString(),
      'CREATE',
      `A ${taskpayload?.code?.coding?.[0]?.code} task has been created`,
      orgRef
    );
    //now post bundle request
    fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
      (response) => {
        fhirPut(`/Flag/${flagpayload.id}`, token, flagpayload).then(
          (response) => {
            fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
              (response) => {
                setDimmerProcess(false);
                props.setOpen(false);
                clearForm();
                props.handleNewTask(taskpayload.id);
                props.setRenderURL(true);
              },
              (response) => {}
            );
          },
          (response) => {}
        );
      },
      (response) => {}
    );
  };

  const submitTaskBundle = async () => {
    let totalInterval = moment(formEndDate).diff(moment(formStartDate), 'days');
    let i = 0;

    setDimmerProcess(true);
    while (i < totalInterval) {
      let orgCode = props.orgCode;

      let taskpayload = TaskBuilder.build(
        taskDetails.code,
        null,
        taskDetails.description, // formDescription
        'Patient/' + props.patientId.toString(), //eg: Patient/patient1
        ['Organization/' + orgCode], //eg: ServiceRequest/exampel1
        null, //eg: Questionnaire/exampleQ
        taskDetails.reason,
        taskDetails.note,
        null, //parent taskId
        (taskDetails.startDate = moment(new Date()).format('YYYY-MM-DD')),
        (taskDetails.endDate = moment(formStartDate).add(i, 'days').format('YYYY-MM-DD')),
        taskDetails.ownerRef,
        personName,
        taskDetails.restriction ? taskDetails.restriction.recipient : null,
        null,
        null,
        taskDetails.priority === 'asap' ? 'asap' : 'routine'
      );
      taskpayload.requester = {
        reference: 'Practitioner/' + practitionerId.toString(),
        display: practitionerName,
      };
      let orgRef = {
        code: orgCode.toUpperCase(),
        display: orgCode.toLowerCase(),
      };
      taskpayload = FHIRUtils.addUpdatedBy(taskpayload, personName);
      let flagpayload: Flag = FlagBuilder.build(
        props.patientId.toString(),
        personId,
        { code: 'admin', display: 'administrative' },
        { code: 'ALERT', display: 'Alert' },
        taskpayload.code.coding[0].code.toString().toUpperCase() +
          ' related task created and assigned to you by ' +
          personName,
        personName,
        orgCode,
        taskpayload.owner.reference.split('/')[1],
        'active',
        taskpayload.id
      );
      //create Provenance
      let provenance: Provenance = ProvenanceBuilder.build(
        `Task/${taskpayload.id!}`,
        {
          reference: 'Practitioner/' + personId,
          display: practitionerName,
        }, //eg. {Practitioner/oa2,johnsmith},
        props.patientId.toString(),
        'CREATE',
        `A ${taskpayload?.code?.coding?.[0]?.code} task has been created`,
        orgRef
      );
      //now post bundle request
      fhirPut(`/Task/${taskpayload.id}`, token, taskpayload).then(
        (response) => {
          fhirPut(`/Flag/${flagpayload.id}`, token, flagpayload).then(
            (response) => {
              fhirPut(`/Provenance/${provenance.id}`, token, provenance).then(
                (response) => {
                  setDimmerProcess(false);
                  props.setOpen(false);
                  clearForm();
                  props.handleNewTask(taskpayload.id);
                },
                (response) => {}
              );
            },
            (response) => {}
          );
        },
        (response) => {}
      );
      i += recurringInterval;
    }
  };

  const clearForm = () => {
    setFormPriority(null);
    setFormCode(null);
    setFormDate(null);
    setFormStartDate(null);
    setFormEndDate(null);
    setRecurringInterval(null);
    setRecurring(false);
    setFormDescription('');
    setFormOwner(null);
    setTaskDetails(blankPayload);
  };

  let taskCategories = [
    'touchpoint',
    //'survey',
    //'food-card',
    //'food-box',
    //'food-delivery',
    //'food-recall',
    //'education',
    //'consultation',
    'careplan',
    //'communication',
    //'triage',
    //'outreach',
  ];
  const priorityCategories = ['routine', 'asap'];
  const priorityMap = { routine: 'STANDARD', asap: 'HIGH PRIORITY' };

  const temp = taskCategories.map((category) => {
    return { key: category, text: category.toUpperCase(), value: category };
  });
  const priorityOptions = priorityCategories.map((prio) => {
    return { key: prio, text: priorityMap[prio].toUpperCase(), value: prio };
  });
  const practlist = flatPractitioners.map((flatPractitioner) => {
    return {
      key: flatPractitioner.id,
      text: flatPractitioner.fullName,
      value: flatPractitioner.id.toString(),
    };
  });

  return (
    <Modal
      as={Form}
      onOpen={() => props.setOpen(true)}
      onClose={() => props.setOpen(false)}
      open={props.open}
      onSubmit={recurring ? submitTaskBundle : submitTask}
      trigger={<Button icon="add" content="New Task" color="green" size="small" />}
      size="large"
      verticalAlign="middle"
    >
      <Modal.Content>
        <Form.Group widths={4}>
          <Form.Input
            autoComplete="off"
            value={formCode}
            onChange={handleChange}
            fluid
            name="code"
            label="Task Category:"
            control={Select}
            options={temp}
            required
            selectOnBlur={false}
          />
          <Form.Input
            autoComplete="off"
            value={formPriorirty}
            onChange={handleChange}
            fluid
            name="priority"
            label="Task Priority:"
            control={Select}
            options={priorityOptions}
            required
            selectOnBlur={false}
          />
        </Form.Group>
        <Form.Input
          control={Checkbox}
          checked={recurring}
          label="This is a recurring task"
          onChange={(e, checkprops) => {
            setRecurring(checkprops.checked);
            setFormEndDate(null);
          }}
        />
        {!recurring && (
          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              value={formDate}
              onChange={handleChange}
              name="endDate"
              label="Task Date:"
              control={SemanticDatepicker}
              format="YYYY-MM-DD"
              required
              selectOnBlur={false}
              default={moment().format('YYYY-MM-DD')}
            />
          </Form.Group>
        )}
        {recurring && (
          <Form.Group widths="equal">
            <>
              <Form.Input
                autoComplete="off"
                value={formStartDate}
                onChange={(e, fieldprops) => {
                  setFormStartDate(fieldprops.value);
                }}
                name="recurringStartDate"
                label="Recurring Start Date:"
                control={SemanticDatepicker}
                format="YYYY-MM-DD"
                required
                selectOnBlur={false}
              />
              <Form.Input
                autoComplete="off"
                value={formEndDate}
                onChange={(e, fieldprops) => {
                  setFormEndDate(fieldprops.value);
                }}
                name="recurringEndDate"
                label="Recurring End Date:"
                control={SemanticDatepicker}
                format="YYYY-MM-DD"
                required
                selectOnBlur={false}
              />
              <Form.Input
                autoComplete="off"
                label="Interval (days)"
                type="number"
                name="interval"
                onChange={(e, fieldprops) => {
                  setRecurringInterval(toInteger(fieldprops.value));
                }}
                required
              />
            </>
          </Form.Group>
        )}
        {formDate !== null && moment(formDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? (
          <Message className="red inline fields" size="tiny">
            Please select a valid date. The due date cannnot be updated to a pastdate.
          </Message>
        ) : (
          ''
        )}
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            value={formOwner}
            onChange={handleChange}
            name="ownerRef"
            control={Select}
            search
            options={practlist}
            label="Assigned to:"
            selectOnBlur={false}
            required
          />
        </Form.Group>
        <Form.Field
          autoComplete="off"
          value={formDescription}
          onChange={handleChange}
          name="description"
          control={TextArea}
          label="Task Description:"
          placeholder="Enter Task Description"
          required
        />
        {dimmerprocess && (
          <Dimmer active>
            {' '}
            <Loader active inline />
          </Dimmer>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Reset" onClick={clearForm} />
        <Button content="Close" type="button" color="red" onClick={() => props.setOpen(false)} />
        <Button
          content="Submit"
          type="submit"
          positive
          className={
            formDate !== null && moment(formDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
              ? 'disabled field'
              : ''
          }
        />
      </Modal.Actions>
    </Modal>
  );
}

export default AddTask;
