import { useState, useEffect } from "react";
import { Checkbox, Radio } from "semantic-ui-react";
import { QuestionnaireExtensionHelper } from "utils/Helpers";

export default function Choice(props) {
  //props: question, addAnswer, i, j, currentAnswer, enable

  const [selectedAnswer, setSelectedAnswer] = useState([]);
  let extHelper = new QuestionnaireExtensionHelper(props.question?.extension);

  useEffect(() => {
    props.currentAnswer ? setSelectedAnswer(props.currentAnswer) : setSelectedAnswer([]);
  }, [props]);

  const optionIndexInSelectedAnswer = (option) => {
    for (let i = 0; i < selectedAnswer.length; i++) {
      if (selectedAnswer[i].valueCoding && option.valueCoding) {
        if (selectedAnswer[i].valueCoding.code === option.valueCoding.code) {
          return i;
        }
      } else if (selectedAnswer[i].valueInteger !== undefined && option.valueInteger !== undefined) {
        if (selectedAnswer[i].valueInteger === option.valueInteger) {
          return i;
        }
      }
    }
    return -1;
  };

  const handleClick = (option) => {
    if (extHelper.isCheckbox()) {
      const index = optionIndexInSelectedAnswer(option);
      let arr = JSON.parse(JSON.stringify(selectedAnswer));
      index > -1 ? arr.splice(index, 1) : arr.push(option);
      arr.sort((a, b) => {
        return a.valueCoding.code - b.valueCoding.code;
      });
      setSelectedAnswer(arr);
      props.addAnswer(arr, props.i, props.j);
    } else {
      setSelectedAnswer([option]);
      props.addAnswer([option], props.i, props.j);
    }
  };

  const handleLabel = (option) => {
    let displayValue: string | number | undefined;
    let chosenLang = props.language;
    if (option?.valueCoding) {
      let extHelper = new QuestionnaireExtensionHelper(option?.valueCoding?.extension);
      if (extHelper.getTargetTranslation(chosenLang)) {
        displayValue = extHelper.getTargetTranslation(chosenLang);
      } else {
        displayValue = option.valueCoding.display;
      }
    } else {
      displayValue = option?.valueInteger;
    }
    return displayValue;
  };

  return (
    <div>
      {props.question.answerOption &&
        props.question.answerOption.map((option, i) => (
          <div key={i}>
            {!extHelper.isEmpty() && extHelper.isCheckbox() && (
              <Checkbox
                disabled={!props.enable}
                label={handleLabel(option)}
                checked={optionIndexInSelectedAnswer(option) > -1}
                onChange={() => handleClick(option)}
              />
            )}
            {((!extHelper.isEmpty() && extHelper.isRadioButton()) ||
              extHelper.getItemControlCode() === undefined ||
              (!extHelper.isEmpty() && extHelper.isSlider())) && (
              //we want to show the slider type for NPS question in web survey, it needs tobe adjusted to display as choice as usual here
              <Radio
                disabled={!props.enable}
                label={handleLabel(option)}
                checked={optionIndexInSelectedAnswer(option) > -1}
                onChange={() => handleClick(option)}
              />
            )}
            <br></br>
          </div>
        ))}
    </div>
  );
}
