export enum ProfileCodes {
  Allergies = "ALLERGIES",
  CommunicationPreferences = "COMMUNICATION-PREFERENCES",
  DeliveryPreferences = "DELIVERY-PREFERENCES",
  Diagnosis = "DIAGNOSIS",
  EducationalMaterialPreferences = "EDUCATIONAL-MATERIAL-PREFERENCES,access-content",
  FoodAvoidance = "FOOD-AVOIDANCE",
  FoodPreferences = "FOOD-PREFERENCES",
  MedicationPreferences = "MEDICATION-PREFERENCES",
  OtherPreferences = "OTHER-PREFERENCES",
  QualificationStatus = "QUALIFICATION-STATUS",
  ReferralType = "REFERRAL-TYPE",
  Resources = "RESOURCES"
}
