export const getSSOKeysForWhitelist = () => {
  const REACT_APP_CLIENTID = JSON.parse(process.env.REACT_APP_CLIENTID);
  const REACT_APP_COGNITO_DOMAIN = JSON.parse(process.env.REACT_APP_COGNITO_DOMAIN);
  const REACT_APP_USER_POOL = JSON.parse(process.env.REACT_APP_USER_POOL);
  const envVariables = [REACT_APP_CLIENTID, REACT_APP_USER_POOL, REACT_APP_COGNITO_DOMAIN];

  const ORGANIZATION_WHITELIST_KEYS = envVariables
    .map(Object.keys)
    .reduce((acc, keys) => acc.filter((key) => keys.includes(key)));

  return ORGANIZATION_WHITELIST_KEYS.filter((key) => key !== "default");
};
