let PatientCDO = {
  org: "",
  id: "",
  firstName: [""],
  lastName: [""],
  middleName: [""],
  dob: "",
  age: 0,
  gender: "",
  genderPrefix: "",
  homePhone: [""],
  mobilePhone: [""],
  email: [""],
  memberId: [""],
  otherId: [""],
  language: [""],
  addressLine1: [""],
  addressLine2: [""],
  city: [""],
  state: [""],
  zip: [""],
  address: "",
  country: ["USA"],
  delivery_addressLine1: [""],
  delivery_addressLine2: [""],
  delivery_city: [""],
  delivery_state: [""],
  delivery_zip: [""],
  delivery_address: "",
  delivery_country: ["USA"],
  provider: [""],
  groupId: "",
  groupName: "",
  eligibilityGroup: '',
};
// TODO Check if the country USA is always the constant
export default PatientCDO;
