import { createContext, ReactNode, useState } from "react";

type OrganizationState = {
  eligibilityGroups: string[];
  isEligibilityGroupsLoading: boolean;
  organizations: string[];
  setIsEligibilityGroupsLoading: (isLoading: boolean) => void;
  setEligibilityGroups: (eligibilityGroups: string[]) => void;
  setSelectedEligibilityGroup: (selectedEligibilityGroup: string | null) => void;
  selectedEligibilityGroup: string | null;
  setOrganizations: (organizations: string[]) => void;
};

const initialOrganizationState: OrganizationState = {
  eligibilityGroups: [],
  isEligibilityGroupsLoading: false,
  organizations: [],
  selectedEligibilityGroup: null,
  setIsEligibilityGroupsLoading: () => {},
  setEligibilityGroups: () => {},
  setSelectedEligibilityGroup: () => {},
  setOrganizations: () => {}
};

export const OrganizationContext = createContext<OrganizationState | undefined>(undefined);

export const OrganizationProvider = ({ children }: { children: ReactNode }) => {
  const [organizations, setOrganizations] =
    useState<Omit<OrganizationState, "setOrganizations">>(initialOrganizationState);

  const setIsEligibilityGroupsLoading = (isLoading: boolean) => {
    setOrganizations((prevState) => ({
      ...prevState,
      isEligibilityGroupsLoading: isLoading
    }));
  };

  const setSelectedEligibilityGroup = (selectedEligibilityGroup: string | null) => {
    setOrganizations((prevState) => ({
      ...prevState,
      selectedEligibilityGroup
    }));
  };

  const updateEligibilityGroups = (eligibilityGroups: string[]) => {
    setOrganizations((prevState) => ({
      ...prevState,
      eligibilityGroups
    }));
  };

  const updateOrganizations = (organizations: string[]) => {
    setOrganizations((prevState) => ({
      ...prevState,
      organizations
    }));
  };

  return (
    <OrganizationContext.Provider
      value={{
        ...organizations,
        setEligibilityGroups: updateEligibilityGroups,
        setIsEligibilityGroupsLoading,
        setOrganizations: updateOrganizations,
        setSelectedEligibilityGroup
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
