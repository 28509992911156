import React from "react";
import moment from "moment";
import { Label } from "semantic-ui-react";
import * as FHIRUtils from "../../../utils/FHIRUtils";

type VitalsProps = {
  vitalsData: any;
};

const Vitals: React.FC<VitalsProps> = ({ vitalsData }) => {
  const fhirVitals = vitalsData;
  let flatVitalsUnsorted = [];

  if (fhirVitals) {
    flatVitalsUnsorted = fhirVitals.map((fhirVital) => {
      return FHIRUtils.parseFHIRVital(fhirVital.resource);
    });
    let flatVitals = [];
    if (flatVitalsUnsorted) {
      flatVitals = flatVitalsUnsorted.sort((c1, c2) => {
        return moment(c2.effectiveDate, "YYYY-MM-DD").valueOf() - moment(c1.effectiveDate, "YYYY-MM-DD").valueOf();
      });
    }

    return (
      <>
        {flatVitals.map((flatVital) => {
          let effectiveDate = moment(flatVital.effectiveDate, "YYYY-MM-DD").format("MMMM Do, YYYY");
          let heightInFtAndInch = undefined;
          //show height observation in ft and inch when the data is stored in inch
          if (flatVital?.code === '8302-2' && flatVital?.unit === 'inches' && flatVital?.quantity) {
            const feet = Math.floor(flatVital.quantity / 12);
            const remainingInches = flatVital.quantity % 12;
            heightInFtAndInch = `${feet} Feet ${remainingInches} Inch`;
          }
          return (
            <Label key={flatVital.id} style={{ margin: "0.15em" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <a href={`https://loinc.org/${flatVital.code}`} target="_blank" rel="noopener noreferrer">
                    {flatVital.display}
                  </a>
                  {": "}
                  {flatVital.valueString?.length > 0 && <b>{flatVital.valueString}</b>}
                  {flatVital.quantity && (
                    <b>{heightInFtAndInch ? heightInFtAndInch : `${flatVital.quantity} ${flatVital.unit}`}</b>
                  )}
                  {flatVital.valueCode && (
                    <a
                      href={`https://icdlist.com/?t=icd10&s=${flatVital.valueCode}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {flatVital.valueCode}
                    </a>
                  )}

                  <div>{effectiveDate}</div>
                </div>
              </div>
            </Label>
          );
        })}
      </>
    );
  } else {
    return <></>;
  }
};

export default Vitals;
