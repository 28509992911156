import { useContext } from "react";
import { OrganizationContext } from "../../contexts";

export const useOrganizations = () => {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error("useOrganizations must be used within an OrganizationProvider");
  }

  return context;
};
