import React, { useState } from "react";
import moment from "moment";
import { Icon, Loader, Message, SemanticCOLORS, SemanticICONS, Table } from "semantic-ui-react";
import useSWR from "swr";

import { useAuth } from "hooks";
import { fhirGet } from "services";
import { DEFAULT_ERROR_MESSAGE } from "utils";

type CareBettyProps = {
  patient: any;
};

const CareBetty: React.FC<CareBettyProps> = ({ patient }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);

  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onSuccess: () => {
      setLoading(false);
    }
  };
  const { data: communicationsData, error: communicationsError } = useSWR(
    ["/Communication/?patient=" + patient.id + "&status=completed", token],
    fhirGet,
    options
  );

  return (
    <div>
      {loading && !communicationsData && !communicationsError && <Loader active inline="centered" />}

      {communicationsData && !communicationsData.entry && (
        <Message warning header="Info" content="No Communications found!" />
      )}
      {communicationsData && communicationsData.entry && (
        <Table sortable celled singleLine color="orange">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Channel</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Subject</Table.HeaderCell>
              <Table.HeaderCell>DateTime</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {communicationsData.entry.map((communication) => {
              const commResource = communication && communication.resource;
              const commType = commResource.medium && commResource.medium[0] && commResource.medium[0].text;
              const commSubject = commResource.topic && commResource.topic.text;

              return (
                <Table.Row key={communication.id}>
                  <Table.Cell>
                    <ChannelIcon channel={commType} />
                  </Table.Cell>
                  <Table.Cell>outbound</Table.Cell>
                  <Table.Cell>{commSubject}</Table.Cell>
                  <Table.Cell>{moment.utc(commResource.sent).local().format("YYYY-MM-DD hh:mm:ss a")}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}

      {communicationsError && (
        <Message negative>
          <Message.Header>{DEFAULT_ERROR_MESSAGE}</Message.Header>
          <p>{communicationsError.message}</p>
        </Message>
      )}
    </div>
  );
};

const ChannelIcon = (props: any) => {
  let iconName: SemanticICONS = "phone volume";
  let iconColor: SemanticCOLORS = "green";

  switch (props.channel) {
    case "voice":
      iconName = "phone volume";
      if (props.type === "outbound") {
        iconColor = "orange";
      } else if (props.type === "voicemail") {
        iconColor = "orange";
      }
      break;
    case "email":
      iconName = "mail outline";
      iconColor = "orange";
      break;
    case "sms":
      iconName = "chat";
      iconColor = "orange";
      break;
    case "email-survey":
      iconName = "mail outline";
      iconColor = "orange";
      break;
    case "sms-survey":
      iconName = "chat";
      iconColor = "orange";
      break;
    default:
      break;
  }

  return <Icon color={iconColor} name={iconName} size="large" />;
};

export default CareBetty;
