import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import { SuspenseLoader } from "components";
import AppRouter from "./AppRouter";
import { AuthProvider, OrganizationProvider } from "./contexts";
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.css";
import "./index.css";

// TODO:
// 1. Have a suspense fallback that is more than just a div.
// 2. Update Providers not to be nested.

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SuspenseLoader />}>
      <OrganizationProvider>
        <AuthProvider>
          <RouterProvider router={AppRouter} />
        </AuthProvider>
      </OrganizationProvider>
    </Suspense>
  </React.StrictMode>,
  document.querySelector("#root")
);
