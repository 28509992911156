import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { ProfileCodes } from "enums";

export const LAB_CODES = [
  {
    name: "Laboratory",
    code: "laboratory,1742-6,1751-7,1920-8,1975-2,2028-9,2075-0,2085-9,2089-1,2093-3,2160-0,2345-7,2571-8,2823-3,2885-2,2951-2,3094-0,8462-4,8480-6,15074-8,17861-6,59261-8,BLOOD_GLUCOSE_PERCENT_TIME_IN_RANGE,BLOOD_SUGAR_READING,EGFR,GLUCOSE,UACR",
    icon: "flask" as SemanticICONS,
    color: "black" as SemanticCOLORS
  }
];

export const MATERNITY_HEALTH_CODES = [
  {
    name: "Maternity Health",
    // Ingestion maternity health observations are in caps
    code: "maternity-health,MATERNITY-HEALTH",
    icon: "hourglass" as SemanticICONS,
    color: "pink" as SemanticCOLORS
  }
];

export const IONIS_RESTRICTED_PROFILE_CODES = [
  ProfileCodes.DeliveryPreferences,
  ProfileCodes.FoodAvoidance,
  ProfileCodes.FoodPreferences
];

export const PROFILE_CODES = [
  {
    name: "Qualification Status",
    code: ProfileCodes.QualificationStatus,
    icon: "star" as SemanticICONS,
    color: "green" as SemanticCOLORS
  },
  {
    name: "Allergies",
    code: ProfileCodes.Allergies,
    icon: "bug" as SemanticICONS,
    color: "red" as SemanticCOLORS
  },
  {
    name: "Food Avoidances",
    code: ProfileCodes.FoodAvoidance,
    icon: "food" as SemanticICONS,
    color: "red" as SemanticCOLORS
  },
  {
    name: "Food Preferences",
    code: ProfileCodes.FoodPreferences,
    icon: "like" as SemanticICONS,
    color: "pink" as SemanticCOLORS
  },
  {
    name: "Delivery Preferences",
    code: ProfileCodes.DeliveryPreferences,
    icon: "truck" as SemanticICONS,
    color: "blue" as SemanticCOLORS
  },
  {
    name: "Communication Preferences",
    code: ProfileCodes.CommunicationPreferences,
    icon: "american sign language interpreting" as SemanticICONS,
    color: "teal" as SemanticCOLORS
  },
  {
    name: "Referral Type",
    code: ProfileCodes.ReferralType,
    icon: "group" as SemanticICONS,
    color: "orange" as SemanticCOLORS
  },
  {
    name: "Education",
    code: ProfileCodes.EducationalMaterialPreferences,
    icon: "book" as SemanticICONS,
    color: "blue" as SemanticCOLORS
  },
  {
    name: "Other Preferences",
    code: ProfileCodes.OtherPreferences,
    icon: "setting" as SemanticICONS,
    color: "yellow" as SemanticCOLORS
  },
  {
    name: "Resources",
    code: ProfileCodes.Resources,
    icon: "star" as SemanticICONS,
    color: "green" as SemanticCOLORS
  },
  {
    name: "Diagnosis",
    code: ProfileCodes.Diagnosis,
    icon: "stethoscope" as SemanticICONS,
    color: "black" as SemanticCOLORS
  },
  {
    name: "Medications",
    code: ProfileCodes.MedicationPreferences,
    icon: "pills" as SemanticICONS,
    color: "purple" as SemanticCOLORS
  }
];

export const VITAL_CODES = [
  {
    name: "Vitals",
    code: "8462-4,8480-6,29463-7,39156-5,55284-4,WEIGHT,8302-2", //Must not be space seperated, only comma
    icon: "user doctor" as SemanticICONS,
    color: "green" as SemanticCOLORS
  }
];
