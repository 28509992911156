import { createBrowserRouter } from "react-router-dom";
import PatientsTab from "./components/patient/patientTab";
import PrivateRoute from "./routes/PrivateRoute";
import { HomePage } from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    children: [
      {
        path: ":org",
        element: (
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        )
      }
    ]
  },
  {
    path: "/patients/:patientId",
    element: (
      <PrivateRoute>
        <PatientsTab />
      </PrivateRoute>
    )
  }
]);

export default router;
