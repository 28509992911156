import { Flag } from "fhir/r4";
import moment from "moment";
import { v4 as uuid } from "uuid";
import * as FHIRUtils from "../fhirutils";

export function build(
  patientId: string,
  practitionerId: string,  // Author
  flagCategory: { code: string; display: string }, //https://www.hl7.org/fhir/valueset-flag-category.html
  flagCode: { code: string; display: string }, //custom: projectWell: https://projectwell.io/fhir/codes/flag-code/ (redflag, warning etc)
  flagText: string,
  updatedBy: string,
  organization: string, // Organization. This is only for Alerts.
  assignedTo: string = null, // Practitioner Ids. This is only for Alerts. 
  flagStatus: 'active' | 'inactive' | 'entered-in-error' = 'active',
  taskId?: string //where the flag was created from a task
): Flag {
  let flagId = uuid();

  let flag: Flag = {
    resourceType: "Flag",
    id: flagId,
    status: flagStatus,
    identifier: [
      {
        system: "https://projectwell.io/fhir/identifiers/organization",
        value: organization,
      },
    ],
    category: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/flag-category",
            code: flagCategory.code,
            display: flagCategory.display,
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: "https://projectwell.io/fhir/codes/flag-code/",
          code: flagCode.code,
          display: flagCode.display,
        },
      ],
      text: flagText,
    },
    subject: {
      reference: "Patient/" + patientId,
    },
    period: {
      start: moment().utc().format()
    },
    author: {
      reference: "Practitioner/" + practitionerId,
      display: updatedBy,
    },
  };

  // For Alerts
  if (assignedTo) {
    let practitioners = assignedTo.split(',');
    practitioners.forEach((practitioner) =>
      flag.identifier.push({
        system: 'https://projectwell.io/fhir/identifiers/assigned-to',
        value: 'Practitioner/' + practitioner, // Practitioner Id
      })
    );
  }

  if (taskId) {
    let practitioners = assignedTo.split(',');
    practitioners.forEach((practitioner) =>
      flag.identifier.push({
        system: 'https://projectwell.io/fhir/identifiers/taskId',
        value: taskId,
      })
    );
  }
  return FHIRUtils.addUpdatedBy(flag, updatedBy);
}
