import React, { useState, useEffect, useReducer } from "react";
import { uniq } from "lodash";
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Label,
  Loader,
  Message,
  Popup,
  Select,
  SemanticCOLORS,
  Table
} from "semantic-ui-react";
import useSWR from "swr";
import * as FHIRUtils from "../../../utils/FHIRUtils";
import { Intervene } from "../../../fhir-sdoh";
import moment from "moment";
import { useAuth } from "hooks";
import { fhirGet } from "services";
import { sortReducer } from "utils";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

const REFERRAL_OBSERVATION_CODE = "REFERRAL-TYPE";

type ReferralsProps = {
  // TODO: Revisit undefined props if it needs to be removed
  activeItem?: string;
  activeTab: string;
  handleMenuItemClick?: (menuItem: string) => void;
  menuIndex?: number;
  org: string;
  patientId: string;
  searchString?: string;
};

const Referrals: React.FC<ReferralsProps> = ({
  activeItem,
  activeTab,
  handleMenuItemClick,
  menuIndex,
  org,
  patientId,
  searchString
}) => {
  const { token } = useAuth();
  const [tableData, setTableData] = useState([]);
  const [statusvalue, setStatusValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [dateQuery, setDateQuery] = useState("");
  const [searchId, setSearchId] = useState("");
  const [referralTableData, setReferralTableData] = useState([]);
  // These two states are empty for now, might be useful in future
  const [, setUrls] = useState([]);
  const [, setReferralTypeOption] = useState([]);
  const [state, dispatch] = useReducer(sortReducer, {
    column: null,
    data: tableData,
    direction: null
  });
  const { column, data, direction } = state;

  const referralStatusColors = {
    NEW: "blue" as SemanticCOLORS,
    CANCELLED: "grey" as SemanticCOLORS,
    COMPLETED: "green" as SemanticCOLORS,
    "IN-PROGRESS": "orange" as SemanticCOLORS,
    OUTREACH: "yellow" as SemanticCOLORS,
    REJECTED: "red" as SemanticCOLORS,
    INELIGIBLE: "black" as SemanticCOLORS,
    PENDING: "pink" as SemanticCOLORS,
    UNREACHABLE: "purple" as SemanticCOLORS
  };

  useEffect(() => {
    if (activeTab === "REFERRALS") {
      // This will trigger SWR to re-Fetch
      referralDataMutate();
      // Get plan definition and interventionActionId to get referral types
      if (org === "HA" || org === "UHC") {
        fhirGet(`/PlanDefinition?identifier=${org}&status=active`, token).then((plan) => {
          let planDefinition;
          let plannedInterventionActionId;
          let index;
          if (plan && plan.entry) {
            planDefinition = plan.entry[0].resource;
            index = plan.entry[0].resource?.action?.findIndex((obj) => obj.id === "intervention");
            if (index >= 0) {
              plannedInterventionActionId = plan.entry[0].resource?.action?.[index]?.action?.[0]?.id;
            }
            if (planDefinition && plannedInterventionActionId) {
              let interventionSubActions = Intervene.getInterventionSubActions(
                planDefinition,
                plannedInterventionActionId
              );
              if (interventionSubActions.length > 0) {
                setReferralTypeOption(
                  interventionSubActions.map((k) => {
                    return { key: k.title, value: k.title, text: k.title };
                  })
                );
              }
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (activeItem === "referrals" && menuIndex === 5 && org !== "HA" && org !== "UHC") {
      handleMenuItemClick("all members");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem, org]);

  useEffect(() => {
    dispatch({ type: "CLEAR_FILTERS", data: referralTableData });
  }, [referralTableData]);
  let daysDict = {
    TODAY: 0,
    "PAST 7 DAYS": 7,
    "PAST 14 DAYS": 14,
    "PAST 30 DAYS": 30,
    "PAST 60 DAYS": 60
  };

  const sliceIntoChunks = function (arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    onSuccess: (data) => {
      const patientIds = [];
      setTableData([]);
      const tempTableData = [];

      if (data && data.entry && data.entry.length > 0) {
        const referralsList = data.entry;

        referralsList.forEach((entry) => {
          const patientRef = entry.resource.subject.reference;
          const patientId = patientRef.substring(patientRef.indexOf("/") + 1);
          patientIds.push(patientId);

          const flatReferral = FHIRUtils.parseFHIRreferral(entry.resource);
          setTableData((result) => {
            return [...result, flatReferral];
          });
          tempTableData.push(flatReferral);
        });
        setReferralTableData((prev) => tempTableData);

        let uniquePatients = uniq(patientIds);
        let endPoints = [];
        let patientChunks = sliceIntoChunks(uniquePatients, 99);

        patientChunks.forEach((chunk) => {
          endPoints.push(`/Patient/?organization=${org}&_id=${chunk.join(",")}`);
          endPoints.push(`/CareTeam/?subject=${chunk.join(",")}`);
        });

        setUrls((prev) => [...endPoints]);
      }
    }
  };

  // Fetch referral data
  const {
    data: referralData,
    error: referralError,
    mutate: referralDataMutate,
    isValidating: referralDatasetLoading
  } = useSWR(
    [
      `/Observation/?code=${REFERRAL_OBSERVATION_CODE}&identifier=${org}${
        statusvalue ? ":::" + statusvalue : ""
      }&_sort=-date&_count=500${patientId || searchString ? "&patient=" + (patientId || searchString) : ""}${
        searchId ? "&_content:exact=" + searchId : ""
      }${dateQuery ? dateQuery : ""}`,
      token
    ],
    fhirGet,
    options
  );

  const handleSearchById = (e, searchProps) => {
    setSearchId(searchProps.value);
  };

  const resetFilters = () => {
    setSearchId("");
    setStatusValue("");
    setDateValue("");
    setDateQuery("");
  };

  const handleDateChange = (e, selectprops) => {
    let numDays = daysDict[selectprops.value];
    let query;
    if (numDays === 0) {
      query = `&date=eq` + moment().format("YYYY-MM-DD");
    } else {
      let sa = moment().subtract(numDays, "day").format("YYYY-MM-DD");
      query = `&date=ge` + sa;
    }
    setDateQuery(query);
    setDateValue(selectprops.value);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form autoComplete="off">
          <Form.Group>
            <Form.Input
              placeholder="Search by ID"
              icon={<Icon name="search" onClick={handleSearchById} />}
              value={searchId}
              onChange={(e, searchProps) => setSearchId(searchProps.value)}
              name="searchId"
            />
            <Form.Field
              control={Select}
              selectOnBlur={false}
              name="period"
              placeholder="Filter by Date"
              value={dateValue}
              options={["TODAY", "PAST 7 DAYS", "PAST 14 DAYS", "PAST 30 DAYS", "PAST 60 DAYS"].map((k) => {
                return { key: k, value: k, text: k };
              })}
              onChange={handleDateChange}
            />
            <Form.Field
              control={Select}
              selectOnBlur={false}
              name="category"
              placeholder="Filter by Status"
              value={statusvalue}
              options={Object.keys(referralStatusColors).map((x) => {
                return { key: x, text: x.toString().toUpperCase(), value: x };
              })}
              onChange={(e, statusprops) => setStatusValue(statusprops.value)}
            />
            <Form.Button content="Clear" color="blue" onClick={resetFilters} />
          </Form.Group>
        </Form>
      </div>
      {referralDatasetLoading && <Loader active inline="centered" />}
      {referralData && !referralData.entry && <Message warning header="No Referrals" />}
      {referralError && (
        <Message negative>
          <Message.Header>Oops!. Something went wrong.. </Message.Header>
          <p>{referralError.message}</p>
        </Message>
      )}
      <div className="table-div">
        {!referralDatasetLoading && !referralError && (
          <Table sortable celled padded singleLine>
            <Dimmer inverted>
              <Loader />
            </Dimmer>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "referralId" ? direction : null}
                  onClick={() => dispatch({ type: "CHANGE_SORT", column: "referralId" })}
                >
                  Referral Id (Total: {referralData && referralData.entry && data ? data.length : "0"})
                </Table.HeaderCell>
                {!patientId && (
                  <Table.HeaderCell
                    width={1}
                    sorted={column === "patientName" ? direction : null}
                    onClick={() => dispatch({ type: "CHANGE_SORT", column: "patientName" })}
                  >
                    Member Name
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell
                  width={1}
                  sorted={column === "referralType" ? direction : null}
                  onClick={() => dispatch({ type: "CHANGE_SORT", column: "referralType" })}
                >
                  Referral Type
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "referralDate" ? direction : null}
                  onClick={() => dispatch({ type: "CHANGE_SORT", column: "referralDate" })}
                >
                  Referral Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "referralAge" ? direction : null}
                  onClick={() => dispatch({ type: "CHANGE_SORT", column: "referralAge" })}
                >
                  Referral Age
                </Table.HeaderCell>

                <Table.HeaderCell
                  width={1}
                  sorted={column === "interventions" ? direction : null}
                  onClick={() => dispatch({ type: "CHANGE_SORT", column: "interventions" })}
                >
                  Interventions
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "referralStatus" ? direction : null}
                  onClick={() => dispatch({ type: "CHANGE_SORT", column: "referralStatus" })}
                >
                  Referral Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {referralData &&
                referralData.entry &&
                data.map((referral) => (
                  <Table.Row key={referral.id} singleLine>
                    <Table.Cell>
                      <Label color="brown">{referral.referralId}</Label>
                    </Table.Cell>
                    <Table.Cell>{referral.referralType}</Table.Cell>
                    <Table.Cell>{referral.referralDate}</Table.Cell>
                    <Table.Cell>{referral.referralAge} days</Table.Cell>
                    <Table.Cell>{referral?.interventions}</Table.Cell>
                    <Table.Cell>
                      <Button.Group size="small" color={referralStatusColors[referral.referralStatus]}>
                        <Popup
                          trigger={<Button>{referral.referralStatus[0]}</Button>}
                          content={
                            <>
                              <i>{referral.lastUpdatedAge}</i> by <b>{referral.lastUpdatedBy}</b>
                            </>
                          }
                        />
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Referrals;
