import { createContext, ReactNode, useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import * as fhirpath from "fhirpath";
import { isArray } from "lodash";
import { useConfigureAmplify, useOrganizations } from "hooks";
import { fhirGet } from "services";

type ExecuteFHIRGetQuery = {
  accessToken: string;
  userEmail: string;
  personId: string;
  organizationId: string;
  role: string;
};

type AuthState = {
  dashboardOnly: string | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  organizationId: string | null;
  personId: string | null;
  personName: string | null;
  role: string | null;
  token: string | null;
  userEmail: string | null;
  validateUSPSAddress: boolean;
  clearAuthState: () => void;
  setAuthState: (authState: Partial<AuthState>) => void;
};

const initialAuthState: AuthState = {
  dashboardOnly: null,
  isLoading: false,
  isLoggedIn: false,
  organizationId: null,
  personId: null,
  personName: null,
  role: null,
  token: null,
  userEmail: null,
  validateUSPSAddress: false,
  clearAuthState: () => {},
  setAuthState: () => {}
};

export const AuthContext = createContext<AuthState | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // Retrieve initial state from localStorage, falling back to initialAuthState
  const storedAuthState = localStorage.getItem("authState");
  const parsedAuthState = storedAuthState ? JSON.parse(storedAuthState) : initialAuthState;

  const [authState, setAuthState] = useState<Omit<AuthState, "clearAuthState" | "setAuthState">>(parsedAuthState);

  const { setEligibilityGroups, setIsEligibilityGroupsLoading, setOrganizations } = useOrganizations();

  useConfigureAmplify();

  const executeFHIRGetQuery = async ({
    accessToken,
    organizationId,
    personId,
    role,
    userEmail
  }: ExecuteFHIRGetQuery) => {
    try {
      if (userEmail && userEmail !== undefined) {
        setIsEligibilityGroupsLoading(true);

        const person = await fhirGet("/Practitioner/" + encodeURIComponent(personId), accessToken);

        const personName =
          fhirpath.evaluate(person, "name.given.first()") + " " + fhirpath.evaluate(person, "name.family");

        const dashboardOnlyArr = fhirpath.evaluate(
          person,
          "identifier.where(system='https://projectwell.io/fhir/dashboard-only').value"
        );
        const dashboardOnly = dashboardOnlyArr?.[0] || null;

        const personOrganizations = fhirpath.evaluate(
          person,
          "identifier.where(system='https://projectwell.io/fhir/organization').value"
        );
        const fetchedOrganizations = await fhirGet("/Organization?active=true", accessToken);
        const eligibilityGroups: string[] = fhirpath.evaluate(
          fetchedOrganizations,
          "entry.resource.identifier.where(system = 'https://projectwell.io/fhir/identifiers/eligibility-group').value"
        );

        if (personOrganizations.length > 0 && personOrganizations[0].includes(",")) {
          const organizations = personOrganizations[0].split(",");

          setOrganizations(organizations);
        }

        if (isArray(eligibilityGroups) && eligibilityGroups.length > 0) {
          //pull elig groups on Practitioner, filter elig group list
          const practitionerEligibilityGroups: string[] = fhirpath.evaluate(
            person,
            "identifier.where(system='https://projectwell.io/fhir/eligibility-group').value"
          );
          if (practitionerEligibilityGroups.length > 0) {
            const pracOrgEligGroups: string[] = practitionerEligibilityGroups[0].split(',').find(o => o.includes(organizationId))?.split(':')[1]?.split(';')
            pracOrgEligGroups 
              ? setEligibilityGroups(pracOrgEligGroups) 
              : setEligibilityGroups(eligibilityGroups);
          } else {
            setEligibilityGroups(eligibilityGroups);
          }
        } else {
          setEligibilityGroups([]);
        }
        setIsEligibilityGroupsLoading(false);

        setAuthState((prev) => ({
          ...prev,
          personName,
          dashboardOnly
        }));

        // add the authContext to local storage to support deeplinking
        // TODO: this will be updated once I get my hands to the patient tab -Ian
        const hostName = window.location.hostname;
        localStorage.setItem(`${hostName}-authToken`, accessToken);
        localStorage.setItem(`${hostName}-userEmail`, userEmail);
        localStorage.setItem(`${hostName}-personId`, personId);
        localStorage.setItem(`${hostName}-organizationId`, organizationId);
        localStorage.setItem(`${hostName}-role`, role);
        localStorage.setItem(`${hostName}-personName`, personName);
        localStorage.setItem(`${hostName}-dashboardOnly`, dashboardOnly);
      } else {
        console.log("Unable to login: No associated email found for the logged in user.");
        await Auth.signOut();
      }
    } catch (err) {
      console.error("Error fetching Practitioner with identifier: ", err);
    }
  };

  const clearAuthState = () => {
    localStorage.removeItem("authState");

    setAuthState((prev) => ({
      ...prev,
      isLoading: false,
      isLoggedIn: false,
      organizationId: null,
      personId: null,
      personName: null,
      dashboardOnly: null,
      role: null,
      token: null,
      userEmail: null,
      validateUSPSAddress: false
    }));
  };

  const updateAuthState = (updatedValues: Partial<AuthState>) => {
    setAuthState((prevState) => ({
      ...prevState,
      ...updatedValues
    }));
  };

  // Save authState to localStorage each time it changes
  useEffect(() => {
    localStorage.setItem("authState", JSON.stringify(authState));
  }, [authState]);

  // Checks for user session on page load
  useEffect(() => {
    const isUserAuthenticated = async () => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (err: unknown) {
        const errorMessage = err as string;

        // Let's logout the user if the error message is "The user is not authenticated"
        if (errorMessage === "The user is not authenticated") {
          await Auth.signOut();
        }
      }
    };

    if (authState.isLoggedIn) {
      isUserAuthenticated();
    }
  }, [authState.isLoggedIn]);

  useEffect(() => {
    if (authState.isLoggedIn && authState.personId && authState.organizationId && authState.token) {
      executeFHIRGetQuery({
        accessToken: authState.token,
        userEmail: authState.userEmail,
        personId: authState.personId,
        organizationId: authState.organizationId,
        role: authState.role
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authState.isLoggedIn,
    authState.personId,
    authState.organizationId,
    authState.role,
    authState.token,
    authState.userEmail
  ]);

  useEffect(() => {
    // Listen to authentication events from Amplify's Auth module
    const unsubscribe = Hub.listen("auth", async ({ payload: { data, event } }) => {
      switch (event) {
        case "signIn":
          const accessToken = data.signInUserSession.getAccessToken().getJwtToken();
          const authPayload = data.signInUserSession.getIdToken().payload;
          const userEmail = authPayload.email;
          const personId = authPayload["custom:personId"];
          const organizationId = authPayload["custom:organizationId"];
          const role = authPayload["custom:role"];

          await executeFHIRGetQuery({ accessToken, userEmail, personId, organizationId, role });

          setAuthState((prev) => ({
            ...prev,
            isLoading: false,
            isLoggedIn: true,
            token: accessToken,
            userEmail,
            personId,
            organizationId,
            role,
            validateUSPSAddress: true
          }));

          // Save the auth state to localStorage
          localStorage.setItem(
            "authState",
            JSON.stringify({
              isLoggedIn: true,
              token: accessToken,
              userEmail,
              personId,
              organizationId,
              role,
              validateUSPSAddress: true
            })
          );
          break;
        case "cognitoHostedUI":
          setAuthState((prev) => ({
            ...prev,
            isLoading: true
          }));
          break;
        case "oAuthSignOut":
        case "signOut":
        case "signIn_failure":
          clearAuthState();
          break;

        default:
          break;
      }
    });

    // Clean up the listener when the component is unmounted
    return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ ...authState, clearAuthState, setAuthState: updateAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};
